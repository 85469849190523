import {  Route, Routes,Navigate } from 'react-router-dom';
//import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
//import "./other/swiper/css/swiper-bundle.min.css";
// import "./css/style.css";
import Login from './pages/Login';
import VerifyEmail from './pages/VerifyEmail';
import Register from './pages/Register';
// import Error404 from './jsx/pages/Error404';
import PageRoute from './routes/route';
import ProtectedRoute from './Middleware/auth';
import PageNotFound from './pages/PageNotFound';
import { ToastContainer } from "react-toastify";
import { createContext, useState } from 'react';


export const userInfo = createContext();
function App() {
  const [username,setUsername] = useState("");
  const [email,setEmail] = useState("");
  const [newsCount,setNewsCount] = useState(0);
  return (
    <>
    <userInfo.Provider value={{username,setUsername,email,setEmail,newsCount,setNewsCount}}>
      <ToastContainer style={{zIndex:100000}}/>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register/>} />
        <Route path='/verifyEmail' element={<VerifyEmail/>} />
        <Route path='/invoice' element={<VerifyEmail/>} />
        <Route path="/user/*" element={<ProtectedRoute Children={PageRoute}/>}/>
        <Route path="/*" element={<PageNotFound/>}/>
      </Routes>
    </userInfo.Provider>
    </>
  );
}
export default App; 

