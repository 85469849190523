import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import ProtectedRoute from '../Middleware/auth';
import PageNotFound from '../pages/PageNotFound';
import { createContext, useEffect, useState } from 'react';
import Profile from '../pages/Profile';
// import Dashboard from '../Components/Pages/Dashboard';
import Kyc from '../pages/Kyc';
import ManualDepositList from '../pages/Deposit/ManualDepositList';
//import ManualDeposit from '../pages/Deposit/ManualDeposit';
import PakageList from '../pages/Subscription/PakageList';
import ManualWithdrawList from '../pages/Withdraw/ManualWithdrawList';
import ManualWithdraw from '../pages/Withdraw/ManualWithdraw';
import TransferList from '../pages/Transfer/TransferList';
import Transactions from '../pages/Finance/Transactions';
import Payouts from '../pages/Finance/Payouts';
import BinaryTeam from '../pages/Affiliate/BinaryTeam';
import DirectTeam from '../pages/Affiliate/DirectTeam';
import DownlineDetails from '../pages/Affiliate/DownlineDetails';
import SubscriptionHistory from '../pages/Subscription/SubscriptionHistory';
import { getReq } from '../requests/request';
import ManualBankList from '../pages/Withdraw/BankList';
import SingleLeg from '../pages/Affiliate/SingleLeg';
import RankDetails from '../pages/Rank';
import Logs from '../pages/Logs/logs';
import SalaryLogs from '../pages/SalaryLog/SalaryLogs';
import Levels from '../pages/Finance/Level';
import RoiIncomes from '../pages/Income/roiIncome';
import UnilevelIncome from '../pages/Income/unilevelIncome';
import Leadership from '../pages/Income/leadership';
import UniverselLeg from '../pages/Income/universelLeg';
import BotSubscriptionHistory from '../pages/Subscription/BotSubscriptionHistory';
// import BotPakageList from '../pages/BotSubscription/BotPakageList';
// import BotSubscriptionHistory from '../pages/BotSubscription/BotSubscriptionHistory';
//import News from '../pages/News/News';

export const SidebarClick = createContext();
export default function PageRoute(){
    const [pinSidebar,setPinSidebar] = useState(false);
    const [currentSidebarClick,setCurrentSidebarClick] = useState('');
    const [hideBinary,setHideBinary] = useState(false);
    const [hideSingleLeg,setHideSingleLeg] = useState(false);
    const [collapseId,setCollapseId] = useState(null);
    const [currentSubmenu,setCurrentSubmenu] = useState(null);
    useEffect(()=>{
        (async ()=>{
            let br = await getReq('pre-register');
            setHideSingleLeg(parseInt(br.single_leg));
            setHideBinary(parseInt(br.binary));
            sessionStorage.setItem('binary',parseInt(br.binary));
            sessionStorage.setItem('single_leg',parseInt(br.single_leg));
        })()
    },[]);
    return (
        <SidebarClick.Provider value={{currentSidebarClick,setCurrentSidebarClick,collapseId,setCollapseId,hideBinary,setHideBinary,currentSubmenu,setCurrentSubmenu,pinSidebar,setPinSidebar,hideSingleLeg,setHideSingleLeg}}>
            <Routes>
                <Route path="/dashboard" element={<ProtectedRoute Children={Dashboard} />}/>
                <Route path="/profile" element={<ProtectedRoute Children={Profile} />}/>
                <Route path='/kyc' element={<ProtectedRoute Children={Kyc}/>}/>
                <Route path='/manualDepositList' element={<ProtectedRoute Children={ManualDepositList}/>}/>
                {/* <Route path='/manualDeposit' element={<ProtectedRoute Children={ManualDeposit}/>}/> */}
                <Route path='/packageList' element={<ProtectedRoute Children={PakageList}/>}/>
                <Route path='/manualWithdraw' element={<ProtectedRoute Children={ManualWithdraw}/>}/>
                <Route path='/manualWithdrawList' element={<ProtectedRoute Children={ManualWithdrawList}/>}/>
                {/* <Route path='/manualBankList' element={<ProtectedRoute Children={ManualBankList}/>}/> */}
                {/* <Route path='/transfer' element={<ProtectedRoute Children={TransferList}/>}/> */}
                <Route path='/transactions' element={<ProtectedRoute Children={Transactions}/>}/>
                <Route path='/payouts' element={<ProtectedRoute Children={Payouts}/>}/>
                <Route path='/levels' element={<ProtectedRoute Children={Levels}/>}/>
                <Route path='/binaryList' element={<ProtectedRoute Children={BinaryTeam}/>}/>
                <Route path='/singleLeg' element={<ProtectedRoute Children={SingleLeg}/>}/>
                <Route path='/directList' element={<ProtectedRoute Children={DirectTeam}/>}/>
                <Route path='/downlineDetails' element={<ProtectedRoute Children={DownlineDetails}/>}/>
                <Route path='/subscriptionHistory' element={<ProtectedRoute Children={SubscriptionHistory}/>}/>
                <Route path='/rankDetails' element={<ProtectedRoute Children={RankDetails}/>}/>
                <Route path='/logs' element={<ProtectedRoute Children={Logs}/>}/>
                <Route path='/salaryLog' element={<ProtectedRoute Children={SalaryLogs}/>}/>
                <Route path='/roiIncomes' element={<ProtectedRoute Children={RoiIncomes}/>}/>
                <Route path='/unilevelIncomes' element={<ProtectedRoute Children={UnilevelIncome}/>}/>
                <Route path='/leaderIncomes' element={<ProtectedRoute Children={Leadership}/>}/>
                <Route path='/universelLegIncomes' element={<ProtectedRoute Children={UniverselLeg}/>}/>
                {/* <Route path='/botpackageList' element={<ProtectedRoute Children={BotPakageList}/>}/>*/}
                <Route path='/botsubscriptionHistory' element={<ProtectedRoute Children={BotSubscriptionHistory}/>}/> 
                {/* <Route path='/news' element={<ProtectedRoute Children={News}/>}/> */}
                <Route path='/*' element={<ProtectedRoute Children={PageNotFound} />}/>
            </Routes>
        </SidebarClick.Provider>
    )
}