import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getReq, logout } from '../requests/request';
import { notifyError, notifySuccess } from '../parts/Toast';
import { Link } from "react-router-dom";
import { userInfo } from '../App';
import { IMAGES } from '../constant/theme';
import { SidebarClick } from '../routes/route';

function Header() {
  const {pinSidebar,setPinSidebar} = useContext(SidebarClick);
  const { username,newsCount} = useContext(userInfo);
  const [img, setImg] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        let r = await getReq('get-picture', sessionStorage.getItem('token'))
        if (r.status === "success" || r.status === true) {
          setImg(r.data);
        }
        if (r.status == false && r.msg == 401) {
          sessionStorage.clear();
          window.history.replaceState(null, null, "/");
          navigate('/', { replace: true });
          //notifyError('Authentication Failed. Please Login Again....');
        }
      }
      catch (err) {
        if (err.message) {
          sessionStorage.clear();
          window.history.replaceState(null, null, "/");
          navigate('/', { replace: true });
        }
        notifyError("Request failed!!!");
      }
    })()
  }, []);
  async function onLogout() {
    let result = await logout(sessionStorage.getItem('token'));
    if (result.errors) {
      let err = Object.values(result.errors);
      //err.map((data)=>notifyError(data[0]));
      notifyError(err[0][0]);
    }
    if (result.status == true) {
      notifySuccess('Your Are Logout!!')
      sessionStorage.clear();
      window.history.replaceState(null, null, "/");
      navigate('/', { replace: true });
    }
    if (result.status == false && result.msg == 401) {
      sessionStorage.clear();
      window.history.replaceState(null, null, "/");
      navigate('/', { replace: true });
      notifyError('Please Login Again!')
    }
  }

  return (
   
    <div className="app-header d-flex align-items-center">


    <div className="d-flex">
        <button className="pin-sidebar">
          <i className="bi bi-list lh-1"></i>
        </button>
      {/* <input type='checkbox' typeof='checkbox' id="checkbox" className="pin-sidebar"/>
        <label for="checkbox" class="toggle">
          <div class="bars" id="bar1"></div>
          <div class="bars" id="bar2"></div>
          <div class="bars" id="bar3"></div>
        </label> */}
    </div>



    <div className="app-brand-sm d-lg-none d-flex">
      <a href="/user/dashboard">
        <img src="/assets/img/logo-white.png" className="logo" alt="" />
      </a>
    </div>

    <div className="header-actions">
      {/* <div style={{cursor:'pointer'}} onClick={()=>navigate('/user/news')}>
        <i class="bi bi-bell" style={{fontSize:'20px'}}/>
        <span style={{position:'relative',top:'-1em'}}>{newsCount}</span>
      </div> */}
      <div className="dropdown ms-4">
        <a id="userSettings" className="dropdown-toggle d-flex py-2 align-items-center" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <img className="rounded-4 img-3x" src={(img && `${process.env.REACT_APP_IMG_URL}/${img}`) || IMAGES.userProfile} style={{ filter: img ? 'invert(0)' : 'invert(1)' }} alt="Profile" height="60" />
          <div className="ms-2 text-truncate d-lg-flex flex-column d-none">
            <p className="text-truncate m-0">{sessionStorage.getItem('username').toLowerCase() || username.toLowerCase()}</p>
            {/* <span className="small opacity-50 lh-1">Admin</span> */}
          </div>
        </a>
        <div className="dropdown-menu dropdown-menu-end shadow-lg">
          <a href="/user/profile" className="dropdown-item d-flex align-items-center"><i
              className="bi bi-person fs-5 me-2"></i>My Profile</a>
          {/* <a href="/user/kyc" className="dropdown-item d-flex align-items-center"><i
              className="bi bi-file-text fs-5 me-2"></i>KYC</a> */}
          <a href="/user/packageList" className="dropdown-item d-flex align-items-center"><i
              className="bi bi-box fs-5 me-2"></i>Package</a>
          {/* <a href="/user/news" className="dropdown-item d-flex align-items-center"><i
              className="bi bi-newspaper fs-5 me-2"></i>News</a> */}
          <div className="mx-3 mt-2 d-grid">
            <a href="#" onClick={onLogout} className="btn btn-primary">Logout</a>
          </div>
        </div>
      </div>
      <div className="d-flex" onClick={()=>setPinSidebar(!pinSidebar)}>
        <button className="toggle-sidebar">
          <i className="bi bi-list lh-1"></i>
        </button>
      </div>
    </div>

    </div>


  );
}
export default Header;
