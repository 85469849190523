import React, { useState, useEffect, useContext } from 'react';
import {Nav, Tab,Card,Col} from "react-bootstrap";
import { getReq, postForm, postReq, sendOtp } from '../requests/request';
import { IMAGES } from '../constant/theme';
import { notifyError, notifySuccess } from '../parts/Toast';
import { SidebarClick } from '../routes/route';
import Frame from "../components/frame";
import { useNavigate } from 'react-router-dom';
import CheckFormError from '../parts/FormError';

function Profile() {
    const navigate = useNavigate();
    const [btnDisable,setBtnDisable] = useState(false);
    const [id,setId] = useState();
    const [user_id,setUser_id] = useState();
    const [user,setUser] = useState('');
    const [countryCode,setCountryCode] = useState();
    const [twofa,setTwofa] = useState({'code':''});
    const [twofaError,setTwofaError] = useState({});
    const [tempPhoto,setTempPhoto] = useState();
    const [avatar,setAvatar] = useState('');
    const [profile,setProfile] = useState();
    const [profileError,setProfileError] = useState({});
    const [password,setPassword] = useState({'currentpassword':'','newpassword':'','confirmpassword':''});
    const [passwordError,setPasswordError] = useState({});
    const [sPassword,setSpassword] = useState({"old_password": "","spassword": "","spassword_confirmation": ""});
    const [sPasswordError,setSPasswordError] = useState({});
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [refrashPage,setRefrashPage] = useState(false);
    const [status_2fa,setStatus_2fa] = useState();
    const [profileLoader,setProfileLoader] = useState(false);
    const [passwordLoader,setPasswordLoader] = useState(false);
    const [sPasswordLoader,setSpasswordLoader] = useState(false);
    const [showTwofa,setShowTwofa] = useState(false);
    const [authData,setAuthData] = useState({'type':''});
    const [authLoader,setAuthLoader] = useState(false);
    const [twofaLoader,setTwofaLoader] = useState(false);
    const [authMore,setAuthMore] = useState({});
    const [passShow,setPassShow] = useState(true);
    const [defaultPage,setDefaultPage] = useState("Profile");
    const [authType,setAuthType] = useState('otp');
    const [counter, setCounter] = useState(60);
	const [showTimmer, setShowTimmer] = useState(false);
	const [blockOtp, setBlockOtp] = useState(true);
    const [activeTab,setActiveTab] = useState("Profile");
    const [withdrawSetting,setWithdrawSetting] = useState(
        {
            "coin_name" : "",
            "coin_address" : "",
        }
    )
    const [creditPercentageSet,setCreditPercentageSet] = useState(
        {
            "credit_wallet_percentage_from_income": 0,
        }
    )
    var currency = '$';
	var globalCount = 60;
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/user/profile');
        setCollapseId(1);
        (async()=>{
            try{
                setAuthType(sessionStorage.getItem('auth_type'));
                let r = await getReq('country',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setCountryCode(r.data);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    //notifyError('Authentication Failed. Please Login Again....');
                }
                let rr = await getReq('credit-percentage',sessionStorage.getItem('token'))
                if(rr.status==="success" || rr.status===true){
                    setCreditPercentageSet({
                        "credit_wallet_percentage_from_income": rr.data,
                    })
                }
            }
            catch(err){
                if(err.message){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                }
                notifyError("Request failed!!!");
            }
        })()
    },[refrashPage])
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/user/profile');
        setAuthType(sessionStorage.getItem('auth_type'));
        (async()=>{
            try{
                setSpinner(true);
                let r = await getReq('user',sessionStorage.getItem('token'))
                if(r.status=="success" || r.status==true){
                    setUser(r.data);
                    setStatus_2fa(r.data.status_2fa);
                    setWithdrawSetting({"coin_name":r.data.profile.withdrawal_coin,"coin_address":r.data.profile.withdrawal_address})
                    let temp = {...r.data.profile};
                    setId(temp.id);
                    setUser_id(temp.user_id);
                    setAvatar(temp.avatar);
                    delete temp.id;
                    delete temp.user_id;
                    delete temp.phone_verified_at;
                    delete temp.last_login_at;
                    delete temp.status;
                    delete temp.created_at;
                    delete temp.updated_at;
                    delete temp.status_2fa;
                    delete temp.avatar;
                    delete temp.package_id;
                    delete temp.auth_type;
                    delete temp.is_paid;
                    delete temp.total_subscription;
                    delete temp.deposit_address;
                    delete temp.withdrawal_coin;
                    delete temp.withdrawal_address;
                    delete temp.pool;
                    temp.name = r.data.name;
                    temp.email = r.data.email;
                    setProfile(temp);
                    setSpinner(false);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if(r.errors){
					let err = Object.values(r.errors);
					notifyError(err[0][0]);
					//err.map((data)=>notifyError(data[0]));
					setSpinner(false);
				}
                setSpinner(false);
            }
            catch(err){
                if(err.message){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                }
                setSpinner(false);
                notifyError("Request failed!!!");
            }
        })()
    },[refrashPage]);
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/user/profile');
        setAuthType(sessionStorage.getItem('auth_type'));
        (async()=>{
            try{
                let r = await getReq('authentication',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    //setData(r.data)
                    setAuthData({'type':r.data});
                    setAuthMore(r.more_details);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    //notifyError('Authentication Failed. Please Login Again....');
                }
                if(r.errors){
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                if(err.response.status&&err.response.status===401||err.response.status===404){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
				    navigate('/',{ replace: true });
                }
                notifyError("Request failed login again");
            }
        })()
    },[refrashPage])
    async function updateProfile(e){
        e.preventDefault();
        let temp = {...profile};
        delete temp.address_line2;
        delete temp.landmark;
        delete temp.tax_id;
        let {errList,countError} = CheckFormError(temp);
        setProfileError(errList);
        if(countError<=0){
            setBtnDisable(true);
            setProfileLoader(true);
            let result = await postForm('update-profile',profile,sessionStorage.getItem('token'))
            if(result.errors){
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            if(result.status==false && result.msg==401){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if((result.message || result.msg) && result.status==false){
                notifyError(result.message||result.msg);
            }
            if((result.message || result.msg)&&result.status){
                notifySuccess(result.message||result.msg);
                setRefrashPage(true);
            }
        }
        setProfileLoader(false);
        setBtnDisable(false);
    }
    async function updateImage(img){
        if(img){
            let temp = {'photo':img}
            let result = await postForm(`change-picture`,temp,sessionStorage.getItem('token'));
            if(result.errors){
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
                setRefrashPage(true);
            }
            if(result.status==false && result.msg==401){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if((result.message || result.msg) && result.status){
                //notifySuccess(result.message||result.msg);
                //setRefrashPage(true);
                window.location.reload();
            }
            if((result.message || result.msg) && result.status==false){
                notifyError(result.message||result.msg);
                setRefrashPage(true);
            }
        }
    }
    async function ed2fa(status){
        let temp2fa = status_2fa;
        let {errList,countError} = CheckFormError({...twofa});
        setTwofaError(errList);
        if(countError<=0){
            if(!status){
                setTwofaLoader(true);
                let result = await postForm('enable-2fa',twofa,sessionStorage.getItem('token'))
                if(result.errors){
                    let err = Object.values(result.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                    setStatus_2fa(temp2fa);
                }
                if((result.message||result.msg) && result.status){
                    notifySuccess(result.message||result.msg);
                    setRefrashPage(true);
                    setStatus_2fa(!temp2fa);
                    setTwofa({...twofa,'code':''});
                }
                if(result.status==false && result.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if((result.message||result.msg) && result.status==false){
                    notifyError(result.message||result.msg);
                    setStatus_2fa(temp2fa);
                }
                setTwofaLoader(false);
            }
            else{
                setTwofaLoader(true);
                let result = await postForm('disable-2fa',twofa,sessionStorage.getItem('token'))
                if(result.errors){
                    let err = Object.values(result.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                    setStatus_2fa(temp2fa)
                }
                if((result.message||result.msg) && result.status){
                    notifySuccess(result.message||result.msg);
                    setRefrashPage(true);
                    setStatus_2fa(!temp2fa);
                    setTwofa({...twofa,'code':''});
                }
                if(result.status==false && result.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if((result.message||result.msg) && result.status==false){
                    notifyError(result.message||result.msg);
                    setStatus_2fa(temp2fa);
                }
                setTwofaLoader(false);
            }
        }
    }
    async function changePassword(e){
        e.preventDefault();
        let {errList,countError} = CheckFormError({...password});
        setPasswordError(errList);
        if(countError<=0){
            setBtnDisable(true);
            setPasswordLoader(true);
            let temp = password;
            if(temp.newpassword==temp.confirmpassword){
                setPassShow(false);
            }
            delete temp.confirmpassword;
            let result = await postForm('change-password',temp,sessionStorage.getItem('token'))
            if(result.errors){
                let err = Object.values(result.errors);
                err.map((data)=>notifyError(data[0]));
            }
            if((result.message||result.msg) && result.status){
                notifySuccess(result.message||result.msg);
                setRefrashPage(true);
                let temp = {'currentpassword':'','newpassword':'','confirmpassword':''}
                temp[authType] = "";
                setPassword(temp);
            }
            if(result.status==false && result.msg==401){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if((result.message||result.msg) && result.status==false){
                notifyError(result.message||result.msg);
            }
        }
        setPasswordLoader(false);
        setBtnDisable(false);
    }
    async function changeSPassword(e){
        e.preventDefault();
        let {errList,countError} = CheckFormError({...sPassword});
        setSPasswordError(errList);
        if(countError<=0){
            setBtnDisable(true);
            setSpasswordLoader(true);
            let result = await postReq('change-secondary-password',sPassword,sessionStorage.getItem('token'));
            if(result.errors){
                let err = Object.values(result.errors);
                err.map((data)=>notifyError(data[0]));
            }
            if((result.msg||result.message) && result.status){
                notifySuccess(result.message||result.msg);
                setRefrashPage(true);
                setSpassword({"old_password": "","spassword": "","spassword_confirmation": ""});
            }
            if(result.status==false && result.msg==401){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if((result.message||result.msg) && result.status==false){
                notifyError(result.message||result.msg);
            }
        }
        setSpasswordLoader(false);
        setBtnDisable(false);
    }
    async function updateAuth(){
        if(authData.type!=""){
            setAuthLoader(true);
            if(authData.type=='authenticator'&&authMore.authenticator){
                let r = await postReq('authentication',authData,sessionStorage.getItem('token'))
                if(r.message || r.msg && r.status===true){
                    notifySuccess(r.message||r.msg);
                    sessionStorage.setItem('auth_type',authData.type);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                else if(r.msg || r.message && r.status===false){
                    notifyError(r.msg||r.message);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if(r.errors){
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                    setAuthLoader(false);
                }
            }
            else if(authData.type=='mpin'&&authMore.mpin){
                let r = await postReq('authentication',authData,sessionStorage.getItem('token'))
                if(r.message || r.msg && r.status===true){
                    notifySuccess(r.message||r.msg);
                    sessionStorage.setItem('auth_type',authData.type);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                else if(r.msg || r.message && r.status===false){
                    notifyError(r.msg||r.message);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if(r.errors){
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                    setAuthLoader(false);
                }
            }
            else if(authData.type=='otp'){
                let r = await postReq('authentication',authData,sessionStorage.getItem('token'))
                if(r.message || r.msg && r.status===true){
                    notifySuccess(r.message||r.msg);
                    sessionStorage.setItem('auth_type',authData.type);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                else if(r.msg || r.message && r.status===false){
                    notifyError(r.msg||r.message);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if(r.errors){
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                    setAuthLoader(false);
                }
            }
            else{
                notifyError(`Please Setup Your ${authData.type=='mpin'?'Security Pin':'2FA Authenticator'}`);
                setAuthLoader(false);
                //setRefrashPage(true);
                if(authData.type=='mpin'){
                    //setDefaultPage('Sec Change Password');
                    setActiveTab('Sec Change Password'.toLowerCase());
                }
                if(authData.type=='authenticator'){
                    //setDefaultPage('2fa');
                    setActiveTab('2fa'.toLowerCase());
                }
                //setRefrashPage(true);
            }
        }
        else{
            notifyError(`Please Select Correct Option.`);
            setAuthLoader(false);
        }
    }
    async function sendOTP() {
		setBlockOtp(false);
		let result = await sendOtp({ 'username': sessionStorage.getItem('username'), 'from': '' });
		if ((result.message || result.msg) && result.status) {
			notifySuccess(result.message || result.msg);
			var OtpInterval = setInterval(function () {
				setCounter((prev) => prev - 1);
				globalCount -= 1;
				if (globalCount <= 0) {
					setCounter(60);
					globalCount = 60;
					setShowTimmer(false);
					setBlockOtp(true);
					clearInterval(OtpInterval);
				}
			}, 1000);
			setShowTimmer(true);
		}
		if ((result.message || result.msg) && result.status == false) {
			notifyError(result.message || result.msg);
			setBlockOtp(true);
		}
		if (result.errors) {
			let err = Object.values(result.errors);
			//err.map((data)=>notifyError(data[0]));
			notifyError(err[0][0]);
			setBlockOtp(true);
		}
	}
    async function updateWithdrawSetting(e){
        e.preventDefault();
        setBtnDisable(true);
        setProfileLoader(true);
        let result = await postReq('withdraw-setting',withdrawSetting,sessionStorage.getItem('token'))
        if(result.errors){
            let err = Object.values(result.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        if(result.status==false && result.msg==401){
            sessionStorage.clear();
            window.history.replaceState(null, null, "/");
            navigate('/',{ replace: true });
            notifyError('Authentication Failed. Please Login Again....');
        }
        if((result.message || result.msg) && result.status==false){
            notifyError(result.message||result.msg);
        }
        if((result.message || result.msg)&&result.status){
            notifySuccess(result.message||result.msg);
            setRefrashPage(true);
            let temp = {'coin_name':'','coin_address':''}
            temp[authType] = "";
            setWithdrawSetting(temp);
        }
        setProfileLoader(false);
        setBtnDisable(false);
    }
    async function updateCreditSetting(e){
        e.preventDefault();
        setBtnDisable(true);
        setProfileLoader(true);
        let result = await postReq('credit-percentage-set',creditPercentageSet,sessionStorage.getItem('token'))
        if(result.errors){
            let err = Object.values(result.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        if(result.status==false && result.msg==401){
            sessionStorage.clear();
            window.history.replaceState(null, null, "/");
            navigate('/',{ replace: true });
            notifyError('Authentication Failed. Please Login Again....');
        }
        if((result.message || result.msg) && result.status==false){
            notifyError(result.message||result.msg);
        }
        if((result.message || result.msg)&&result.status){
            notifySuccess(result.message||result.msg);
            setRefrashPage(true);
            let temp = {'credit_wallet_percentage_from_income':0}
            setCreditPercentageSet(temp);
        }
        setProfileLoader(false);
        setBtnDisable(false);
    }
    function handleProfileString(e,key){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z\s]/ig, '');
        let temp = {...profile}
        temp[key] = value
        setProfile(temp);
	};
	function handleProfileStrNum(e,key){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9]/ig, '');
        let temp = {...profile}
        temp[key] = value
        setProfile(temp);
	};
    function handleProfileAddress(e,key){
        let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9.-/\s]/ig, '');
        let temp = {...profile}
        temp[key] = value
        setProfile(temp);
    }
	function handleProfileEmail(e,key){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9@.]/ig, '');
        let temp = {...profile}
        temp[key] = value
        setProfile(temp);
	};
    function handleProfileNum(e,key){
		let value = e.target.value
  		value = value.replace(/[^0-9]/ig, '');
        let temp = {...profile}
        temp[key] = value
        setProfile(temp);
	};
    function handle2faCode(e,key){
		let value = e.target.value
  		value = value.replace(/[^0-9]/ig, '');
        let temp = {...twofa}
        temp[key] = value
        setTwofa(temp);
	};
    function handleMpin(e,key){
		let value = e.target.value
  		value = value.replace(/[^0-9]/ig, '');
        let temp = {...sPassword}
        temp[key] = value
        setSpassword(temp);
	};
    function setAuth(e){
        let temp = {...password};
        temp[authType] = e.target.value;
        setPassword(temp);
    }
    function setWithdrawAuth(e){
        let temp = {...withdrawSetting};
        temp[authType] = e.target.value;
        setWithdrawSetting(temp);
    }
    function handlePan(e,key){
        //let regex = /^[A-Z]{4,}\d{4,}\w/;
		let value = e.target.value
  		value = value.replace(/[^a-zA-Z0-9]/ig, '');
        let temp = {...profile}
        temp[key] = value.toUpperCase();
        setProfile(temp);
	};
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>

            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Profile</h1>
            </div>

            {!profile&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {profile&&<div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center flex-row flex-wrap">
                <img className="img-5x rounded-circle"  src={(tempPhoto!=null&&tempPhoto)||(avatar&&`${process.env.REACT_APP_IMG_URL}/${avatar}`)||IMAGES.userProfile} style={{filter:avatar?'invert(0)':'invert(1)'}} alt=""/>
                
               
                
                <div className="ms-3 text-white">
                  <h5 className="mb-1">{user&&user.username}</h5>
                  <h6 className="m-0 fw-light">{user&&user.email}</h6>
                </div>
                <div className="ms-auto text-white">
                    <p className="mb-1">
                        <div style={{wordWrap:"break-word",wordBreak:'break-all'}}>Deposit Address : <span>{user&&user.profile.deposit_address||'-'}</span></div>
                    </p>
                    <p className="mb-1">
                        Email : {user&&user.email_verified_at?<span> <i className="fa-solid fa-check" style={{color:'deepskyblue'}}/>{" "}Verified</span>:<span> <i className="fa-solid fa-close" style={{color:'red'}}/>{" "}Not Verified</span>}
                    </p>
                    <p className="mb-1">
                        Phone : {user&&user.profile.phone||'-'}
                    </p>
                </div>
                <div className="ms-auto text-white">
                   
                    {/* <p className="mb-1">
                        Global Pool : {user&&user.profile.pool||'NA'}
                    </p> */}
                    {/* <p className="mb-1">
                        Rank : {user&&user.profile.rank||'NA'}
                    </p> */}
                    <p className="mb-1">
                        Total Subscription : {user&&(process.env.REACT_APP_Amount_Symbol||'$'+' '+parseFloat(user.profile.total_subscription||0.0))}
                    </p>
                   
                </div>
              </div>
            </div>
            </div>}


           
            {profile&&<div className="card">
                <div className="card-body">
                    <div className="profile">
                        <div className="profile-container">
                            
                            <div className="profile-content">
                                <Tab.Container activeKey={activeTab.toLowerCase()} defaultActiveKey={defaultPage.toLowerCase()}>
                                <Nav as="ul" className="nav nav-pills mb-4">
                                    <Nav.Item as="li" onClick={()=>setActiveTab('Profile'.toLowerCase())}>
                                        <Nav.Link eventKey={"Profile".toLowerCase()}>
                                            {"Basic Info"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" onClick={()=>setActiveTab('2fa'.toLowerCase())}>
                                        <Nav.Link eventKey={"2fa".toLowerCase()}>
                                            {"2FA Setting"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" onClick={()=>setActiveTab('Change Password'.toLowerCase())}>
                                        <Nav.Link eventKey={"Change Password".toLowerCase()}>
                                            {"Password"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" onClick={()=>setActiveTab('Sec Change Password'.toLowerCase())}>
                                        <Nav.Link eventKey={"Sec Change Password".toLowerCase()}>
                                            {"Secutity Pin"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" onClick={()=>{setActiveTab('Authentication'.toLowerCase());setRefrashPage(true)}}>
                                        <Nav.Link eventKey={"Authentication".toLowerCase()}>
                                            {"Authentication"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" onClick={()=>{setActiveTab('withdrawSetting'.toLowerCase());setRefrashPage(true)}}>
                                        <Nav.Link eventKey={"withdrawSetting".toLowerCase()}>
                                            {"Withdraw Setting"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item as="li" onClick={()=>{setActiveTab('creditPercentage'.toLowerCase());setRefrashPage(true)}}>
                                        <Nav.Link eventKey={"creditPercentage".toLowerCase()}>
                                            {"Credit Percentage"}
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                                
                                <Tab.Content className="">
                                    <Tab.Pane eventKey={"Profile".toLowerCase()}>
                                        {profile&&
                                        <form>
                                            
                                            <div className="profile-content-container">
                                                <div className="card profile-card card-bx m-b30">
                                                    <div className="card-header">
                                                        <h6 className="card-title mb-0">Profile Setup</h6>
                                                    </div>
                                                    <div className="profile-form">
                                                        <div className="card-body">
                                                        <div className="mb-3">
                                                            <input type="file" accept="image/png,image/jpeg" className="update-flie form-control" onChange={(e)=>{updateImage(e.target.files[0]);setTempPhoto(e.target.files[0]&&URL.createObjectURL(e.target.files[0]))}}/>
                                                        </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 mb-4">
                                                                    <label className="mb-1">Name <span className="required">*</span></label>
                                                                    <input type="text" name="name" className="form-control" value={profile.name} onChange={(e)=>handleProfileString(e,'name')} placeholder="Name" required/>
                                                                    {((profile.name==''||profile.name==null)&&profileError.name)&&<span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <label className="mb-1">Gender <span className="required">*</span></label>
                                                                    <select className="form-control" name='gender' value={profile.gender} onChange={(e)=>setProfile({...profile,'gender':e.target.value})} required>
                                                                        <option value={''}>Select Gender</option>
                                                                        <option value={'female'}>Female</option>
                                                                        <option value={'male'}>Male</option>
                                                                    </select>
                                                                    {((profile.gender==''||profile.gender==null)&&profileError.gender)&&<span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <label className="mb-1">Date of birth <span className="required">*</span></label>
                                                                    <input type="date" name="dob" className="form-control" value={profile.dob!==null&&profile.dob?.split(' ')[0]} onChange={(e)=>setProfile({...profile,'dob':e.target.value})} placeholder="dob" required/>
                                                                    {((profile.dob==''||profile.dob==null)&&profileError.dob)&&<span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <label className="mb-1">Email <span className="required">*</span></label>
                                                                    <input type="email" name="email" className="form-control" value={profile.email} onChange={(e)=>handleProfileEmail(e,'email')} placeholder="Email" required/>
                                                                    {((profile.email==''||profile.email==null)&&profileError.email)&&<span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">Address Line 1 <span className="required">*</span></label>
                                                                        <input type="text" name="address_line1" className="form-control" value={profile.address_line1} onChange={(e)=>handleProfileAddress(e,'address_line1')} required/>
                                                                        {((profile.address_line1==''||profile.address_line1==null)&&profileError.address_line1)&&<span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">Address Line 2 </label>
                                                                        <input type="text" name="address_line2" className="form-control" value={profile.address_line2} onChange={(e)=>handleProfileAddress(e,'address_line2')} required/>
                                                                        {((profile.address_line2==''||profile.address_line2==null)&&profileError.address_line2)&&<span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">Landmark </label>
                                                                        <input type="text" name="landmark" className="form-control" value={profile.landmark} onChange={(e)=>handleProfileString(e,'landmark')} required/>
                                                                        {((profile.landmark==''||profile.landmark==null)&&profileError.landmark)&&<span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">City <span className="required">*</span></label>
                                                                        <input type="text" name="city" className="form-control" value={profile.city} onChange={(e)=>handleProfileString(e,'city')} required/>
                                                                        {((profile.city==''||profile.city==null)&&profileError.city)&&<span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">State <span className="required">*</span></label>
                                                                        <input type="text" name="state" className="form-control" value={profile.state} onChange={(e)=>handleProfileString(e,'state')} required/>
                                                                        {((profile.state==''||profile.state==null)&&profileError.state)&&<span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">Country <span className="required">*</span></label>
                                                                        <div className="form-group">
                                                                            <select name="select_box" className="form-control" value={countryCode&&profile?.country&&countryCode[parseInt(profile?.country)-1].id} onChange={(e)=>setProfile({...profile,'country':e.target.value,'phone':e.target[e.target.selectedIndex].getAttribute('data-code')})}>
                                                                                <option value="">Select Country</option>
                                                                                {
                                                                                    countryCode && countryCode.map((data,idx)=>
                                                                                        <option value={data.id} data-code={data.calling_code} key={idx}>{data.name}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {((profile.country==''||profile.country==null)&&profileError.country)&&<span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">Phone <span className="required">*</span></label>
                                                                        <input type="text" name="phone" className="form-control" value={profile.phone} onChange={(e)=>{handleProfileNum(e,'phone')}} required/>
                                                                        {((profile.phone==''||profile.phone==null)&&profileError.phone)&&<span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">Pan Number <span className="required">*</span></label>
                                                                        <input type="text" name="pan_no" className="form-control" value={profile.pan_no} onChange={(e)=>{handlePan(e,'pan_no')}} required/>
                                                                        {((profile.pan_no==''||profile.pan_no==null)&&profileError.pan_no)&&<span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">Pincode <span className="required">*</span></label>
                                                                        <input type="text" name="pincode" className="form-control" value={profile.pincode} onChange={(e)=>handleProfileNum(e,'pincode')} required/>
                                                                        {((profile.pincode==''||profile.pincode==null)&&profileError.pincode)&&<span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 mb-4">
                                                                    <div className="form-group"><label className="mb-1">Tax Id </label>
                                                                        <input type="text" name="tax_id" className="form-control" value={profile.tax_id} onChange={(e)=>handleProfileStrNum(e,'tax_id')} required/>
                                                                        {((profile.tax_id==''||profile.tax_id==null)&&profileError.tax_id)&&<span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button className="btn btn-primary" onClick={updateProfile} disabled={btnDisable}>
                                                                UPDATE{" "}
                                                                {profileLoader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>}
                                                            </button>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-arrow">
                                                    <div className="card-arrow-top-left"></div>
                                                    <div className="card-arrow-top-right"></div>
                                                    <div className="card-arrow-bottom-left"></div>
                                                    <div className="card-arrow-bottom-right"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>}
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content className=''>
                                    <Tab.Pane eventKey={"2fa".toLowerCase()}>
                                        {profile&&
                                        <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">Enable/Disable 2fa</h6>
                                                </div>
                                                <div className='d-flex justify-content-around twofa'>
                                                    <div className="card-body">
                                                        {/* <div className="" style={{display:"flex",alignItems:"center",gap:"2rem",flexDirection:"row"}}>
                                                            <label className="mb-1" style={{margin:"0",fontSize:"1.1rem"}} htmlFor="flexSwitchCheckChecked"> Enable/Disable 2fa :</label>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" role="switch" checked={status_2fa?true:false} id="flexSwitchCheckChecked" onChange={ed2fa}/>
                                                            </div>
                                                        </div> */}
                                                        <div className="mb-4">
                                                            <label className="mb-1">Code <span className="required">*</span></label>
                                                            <input type="text" name="code" className="form-control" value={twofa.code} onChange={(e)=>handle2faCode(e,'code')} placeholder="Enter Your Code" required/>
                                                            {(twofa.code==''&&twofaError.code)&&<span className="required">This Field Is Required.</span>}
                                                        </div>
                                                        <div className="">
                                                            <button className="btn btn-primary" onClick={()=>ed2fa(status_2fa)} disabled={twofaLoader}>
                                                                {status_2fa?'Disable':'Enable'} 2FA{" "}
                                                                {twofaLoader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {!status_2fa&&<img src={process.env.REACT_APP_2FA+user.image_2fa} alt=''/>}
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                                </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content className="">
                                    <Tab.Pane eventKey={"Change Password".toLowerCase()}>
                                        {profile&&
                                        <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">Change Password</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-4">
                                                        <label className="mb-1">Current Password <span className="required">*</span></label>
                                                        <div className="">
                                                            <input type="password" className="form-control" placeholder="Enter Current password" value={password.currentpassword} onChange={(e)=>setPassword({...password,'currentpassword':e.target.value})}/>
                                                            {(password.currentpassword==''&&passwordError.currentpassword)&&<span className="required">This Field Is Required.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="mb-1">New Password <span className="required">*</span></label>
                                                        <div className="">
                                                            <input type="password" className="form-control" placeholder="Enter new password" value={password.newpassword} onChange={(e)=>setPassword({...password,'newpassword':e.target.value})}/>
                                                            {(password.newpassword==''&&passwordError.newpassword)&&<span className="required">This Field Is Required.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="mb-1">Confirm Password <span className="required">*</span></label>
                                                        <div className="">
                                                            <input type="text" className="form-control" placeholder="Enter confirm password" value={password.confirmpassword} onChange={(e)=>setPassword({...password,'confirmpassword':e.target.value})}/>
                                                            {(password.confirmpassword==''&&passwordError.newpassword)&&<span className="required">This Field Is Required.</span>}
                                                            {passShow&&(password.newpassword!=password.confirmpassword)&&<div className="text-danger fs-12">Password not matching with new password</div>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-4">
                                                        {authType !== '' && <div className="mb-1">
                                                            <label className="form-label" style={{ textTransform: 'capitalize' }}>{authType=='mpin'?'Security Pin':authType}</label>
                                                            <input type="number" className="form-control" value={password[authType]} onChange={(e) =>setAuth(e)} />
                                                            {(password[authType]==''&&passwordError[authType])&&<span className="required">This Field Is Required.</span>}
                                                        </div>}
                                                        {authType == 'otp' && !showTimmer && <div className="mb-4"><span role="button" className='text-primary' style={{ textTransform: 'capitalize' }} onClick={() => blockOtp ? sendOTP() : null}>Resend OTP </span></div>}
                                                        {authType == 'otp' && showTimmer && <div className="mb-4"><span style={{ color: 'var(--primary)', margin: '8px' }}>Resend request after {counter} sec</span></div>}
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-primary" onClick={changePassword} disabled={btnDisable}>
                                                            UPDATE PASSWORD{" "}
                                                            {passwordLoader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                                </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content className="">
                                    <Tab.Pane eventKey={"Sec Change Password".toLowerCase()}>
                                        {profile&&
                                        <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">Change Secutity Pin</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-4">
                                                        <label className="mb-1">Enter Current Password <span className="required">*</span></label>
                                                        <div className="">
                                                            <input type="password" className="form-control" placeholder="Enter Current password" value={sPassword.old_password} onChange={(e)=>setSpassword({...sPassword,'old_password':e.target.value})}/>
                                                            {(sPassword.old_password==''&&sPasswordError.old_password)&&<span className="required">This Field Is Required.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="mb-1">Secutity Pin <span className="required">*</span></label>
                                                        <div className="">
                                                            <input type="password" className="form-control" placeholder="Enter new 4 digit secutity pin" value={sPassword.spassword} onChange={(e)=>handleMpin(e,'spassword')}/>
                                                            {(sPassword.spassword==''&&sPasswordError.spassword)&&<span className="required">This Field Is Required.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="mb-1">Confirm Secutity Pin <span className="required">*</span></label>
                                                        <div className="">
                                                            <input type="text" className="form-control" placeholder="Enter confirm secutity pin" value={sPassword.spassword_confirmation} onChange={(e)=>handleMpin(e,'spassword_confirmation')}/>
                                                            {(sPassword.spassword_confirmation==''&&sPasswordError.spassword_confirmation)&&<span className="required">This Field Is Required.</span>}
                                                            {sPassword.spassword!=sPassword.spassword_confirmation&&<div className="text-danger fs-12">Secutity Pin not matching with new Pin</div>}
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-primary" onClick={changeSPassword} disabled={btnDisable}>
                                                            UPDATE Secutity Pin{" "}
                                                            {sPasswordLoader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                                </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content className="">
                                    <Tab.Pane eventKey={"Authentication".toLowerCase()}>
                                        {profile&&
                                        <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">Change Authentication</h6>
                                                </div>
                                                <div className="card-body">
                                                    <select className="form-select form-select-lg " value={authData.type} aria-label="Default select" onChange={(e)=>setAuthData({type:e.target.value})}>
                                                        <option value="">Select Authentication Type</option>
                                                        <option value="authenticator">Authenticator</option>
                                                        <option value="otp">OTP</option>
                                                        <option value="mpin">Secutity Pin</option>
                                                    </select>
                                                    <div className="mt-4">
                                                        <button className="btn btn-primary" onClick={updateAuth} disabled={authLoader}>
                                                            UPDATE{" "}
                                                            {authLoader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                                </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content className="">
                                    <Tab.Pane eventKey={"withdrawSetting".toLowerCase()}>
                                        {profile&&
                                        <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">Change Withdraw Setting</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-4">
                                                        <label className="mb-1">Coin Name <span className="required">*</span></label>
                                                        <input type="text" name="name" className="form-control" value={withdrawSetting.coin_name} onChange={(e)=>setWithdrawSetting({...withdrawSetting,"coin_name":e.target.value})} placeholder="Coin Name" required/>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="mb-1">Coin Address <span className="required">*</span></label>
                                                        <input type="text" name="name" className="form-control" value={withdrawSetting.coin_address} onChange={(e)=>setWithdrawSetting({...withdrawSetting,"coin_address":e.target.value})} placeholder="Coin Address" required/>
                                                    </div>
                                                    {<div className="col-sm-6 mb-4">
                                                        {authType !== '' && <div className="mb-1">
                                                            <label className="form-label" style={{ textTransform: 'capitalize' }}>{authType=='mpin'?'Security Pin':authType}</label>
                                                            <input type="number" className="form-control" value={withdrawSetting[authType]} onChange={(e) =>setWithdrawAuth(e)} />
                                                        </div>}
                                                        {authType == 'otp' && !showTimmer && <div className="mb-4"><span role="button" className='text-primary' style={{ textTransform: 'capitalize' }} onClick={() => blockOtp ? sendOTP() : null}>Resend OTP </span></div>}
                                                        {authType == 'otp' && showTimmer && <div className="mb-4"><span style={{ color: 'var(--primary)', margin: '8px' }}>Resend request after {counter} sec</span></div>}
                                                    </div>}
                                                    <div className="mt-4">
                                                        <button className="btn btn-primary" onClick={updateWithdrawSetting} disabled={btnDisable}>
                                                            UPDATE{" "}
                                                            {btnDisable&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                                </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                                {/* <Tab.Content className="">
                                    <Tab.Pane eventKey={"creditPercentage".toLowerCase()}>
                                        {profile&&
                                        <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">Set Credit Percentage</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-4">
                                                        <label className="mb-1">Percentage From Income <span className="required">*</span></label>
                                                        <input type="text" name="name" className="form-control" value={parseFloat(creditPercentageSet.credit_wallet_percentage_from_income)} onChange={(e)=>setCreditPercentageSet({...creditPercentageSet,"credit_wallet_percentage_from_income":e.target.value})} placeholder="Percentage From Income" required/>
                                                    </div>
                                                    <div className="mt-4">
                                                        <button className="btn btn-primary" onClick={updateCreditSetting} disabled={btnDisable}>
                                                            UPDATE{" "}
                                                            {btnDisable&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                                </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content> */}
                                </Tab.Container>
                               
                            </div>
                        </div>
                    </div>
                </div>

               
            </div>}



        </Frame>
    );
}

export default Profile;