import React, { useState, useEffect, useContext } from 'react';
import { SidebarClick } from '../../routes/route';
import { Spinner, Table, Card, Col } from "react-bootstrap";
import { getReq, postReq, sendOtp } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OtpInput from 'react-otp-input';
import MoreDetails from '../../parts/MoreDetails';
import CheckFormError from '../../parts/FormError';
import { Link, useNavigate } from 'react-router-dom';
import Frame from "../../components/frame";
//import {metadata,network,projectId} from "../../utils";
//import Web3 from "web3";
//import {createWeb3Modal,defaultConfig,useWeb3Modal,useWeb3ModalAccount,useWeb3ModalSigner} from "@web3modal/ethers5/react";

// const modal = createWeb3Modal({
//     ethersConfig: defaultConfig({ metadata }),
//     chains: [network],
//     projectId: projectId,
//     themeVariables: {
//         '--w3m-z-index': 9999,
//         '--w3m-color-mix': '#fff',
//         '--w3m-color-mix-strength': 40
//     }
//     ,themeMode: 'light',
// });

function ManualWithdraw() {
    const navigate = useNavigate();
    const [authError, setAuthError] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [data, setData] = useState({ 
        'amount': 0, 
        'bank_id': ''
    });
    const [dataError, setDataError] = useState({});
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [loader, setLoader] = useState(false);
    const [banklist, setBanklist] = useState(null);
    const [refrashPage, setRefrashPage] = useState(false);
    const [showAuth, setShowAuth] = useState(false);
    const [authNum, setAuthNum] = useState('');
    const [moreDetails, setMoreDetails] = useState(null);
    let currency = '$';
    const [counter, setCounter] = useState(60);
    const [showTimmer, setShowTimmer] = useState(false);
    const [showAmountError,setShowAmountError] = useState(false);
    const [cDate,setCDate] = useState(new Date());
    let count = 0;
    var globalCount = 60;
    //const [web3,setWeb3] = useState(null);
    //const [accounts,setAccounts] = useState(null);
    //const [contract,setContract] = useState(null);
    //const [is_connected,setIs_connected] = useState(false);
    //const { open, close } = useWeb3Modal();
    //const { address,chainId, isConnected } = useWeb3ModalAccount();
    //const { signer, walletProvider } = useWeb3ModalSigner();
    //const [balance,setBalance] = useState(0);

    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/user/manualWithdraw');
        setCollapseId(5);
        (async () => {
            let r = await getReq('withdraw', sessionStorage.getItem('token'));
            if (r.status === "success" || r.status === true) {
                setMoreDetails(r.more_details);
            }
            if (r.status == false && r.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if (r.errors) {
                let err = Object.values(r.errors);
                err.map((data) => notifyError(data[0]));
            }
            setSpinner(false);
        })()
    }, [refrashPage])
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/user/manualWithdraw');
        setCollapseId(5);
        (async () => {
            // if(address && address!= accounts){
            //     //setAccounts(address)
            //     await connectToWallet();
            // }
            // if(accounts && !isConnected){
            //     disconnectFromWallet();
            // }

            let r = await getReq('user/bankdetails', sessionStorage.getItem('token'));
            if (r.status === "success" || r.status === true) {
                setBanklist(r.data.data);
            }
            if (r.status == false && r.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                //notifyError('Authentication Failed. Please Login Again....');
            }
            if (r.errors) {
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            setSpinner(false);
            // return ()=>{
            //     if(accounts && !isConnected){
            //         disconnectFromWallet();
            //     }
            // }
        })()
    }, [refrashPage])
    function bankListCheck(id) {
        let temp = { ...data }
        temp['bank_id'] = id;
        setData(temp);
    }
    async function sendData(e) {
        e.preventDefault();
        if (authNum != '') {
            setAuthError(false);
            let temp = { ...data }
            temp[sessionStorage.getItem('auth_type')] = authNum;
            setLoader(true);
            let result = await postReq('withdraw', temp, sessionStorage.getItem('token'))
            if (result.errors) {
                if (typeof result.errors === "string") {
                    notifyError(result.errors);
                    setLoader(false);
                }
                else {
                    let err = Object.values(result.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                    setLoader(false);
                }
                setAuthNum('');
                setRefrashPage(true);
            }
            if (result.msg && result.status) {
                notifySuccess(result.msg);
                setLoader(false);
                setRefrashPage(true);
                setData({ 'amount': '', 'bank_id': '' });
                //setData({ 'amount': ''});
                setAuthNum('');
                setShowAuth(false);
                navigate('/user/manualWithdrawList');
            }
            if (result.status == false && result.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if (result.msg && result.status == false) {
                notifyError(result.msg);
                setLoader(false);
                setRefrashPage(true);
                setAuthNum('');
                setShowAuth(false);
            }
            setRefrashPage(true);
            setLoader(false);
            // }
        }
        else {
            setAuthError(true);
        }
    }
    function showPin() {
        // if(cDate.getDate()>=1 && cDate.getDate()<=5){
        let { errList, countError } = CheckFormError({ ...data });
        errList.bank_id=false;
        countError-=1;
        setDataError(errList);
        let bal = 0;
        moreDetails.forEach((d)=>{d.title=='PAYOUT WALLET BALANCE'&&(bal=d.value.replace(process.env.REACT_APP_Amount_Symbol||"$",''))});
        if(data.amount!=0){
            if(data.amount<=parseFloat(bal)){
                setShowAmountError(false);
                if (countError <= 0) {
                    if (sessionStorage.getItem('auth_type') == 'otp') {
                        requestOTP();
                    }
                    setShowAuth(true);
                }
            }
            else{
                setShowAmountError(true);
            }
        }
        // }
    }
    async function requestOTP() {
        let data = { "username": sessionStorage.getItem('username'), 'from': '' };
        let result = await sendOtp(data);
        if (result.message && result.status) {
            notifySuccess(result.message);
            var OtpInterval = setInterval(function () {
                setCounter((prev) => prev - 1);
                globalCount -= 1;
                if (globalCount <= 0) {
                    setCounter(60);
                    globalCount = 60;
                    setShowTimmer(false);
                    clearInterval(OtpInterval);
                }
            }, 1000);
            setShowTimmer(true);
        }
        else {
            notifyError(result.message);
        }
    }
    function handleNum(e, key) {
        let value = e.target.value
        value = value.replace(/[^0-9]/ig, '');
        let temp = { ...data }
        temp[key] = value;
        setData(temp);
    }

    // const connectToWallet = async (e) => {
    //     try {
    //         // Get network provider and web3 instance.            
    //         // let provider = walletProvider;
    //         // let signer = signer;
    //         let web3 = new Web3(modal);
    //         web3.setProvider(network.rpcUrl);
    //         // Use web3 to get the user's accounts.
    //         let user_account = address
    //         try{
    //             let bal = await web3.eth.getBalance(user_account);
    //             bal = web3.utils.fromWei(bal.toString(),'ether');
    //             setBalance(bal);
    //         }
    //         catch (error) {
    //             console.error("Error fetching balance:", error);
    //             // Handle the error appropriately
    //         }

    //         const networkId = chainId;
    //         if (networkId !== 137 && networkId !== "eip:137") {
    //             notifyError("Error! Please connect to polygon mainnet");
    //             setSpinner(false);
    //             return false;
    //           }

    //         if (user_account !== accounts) {
    //             // Get the contract instance.

    //             // this.setSpinner(true );
    //             // const deployedNetwork = MainContract.network;
    //             // const instance = new web3.eth.Contract(
    //             //     MainContract.abi,
    //             //     deployedNetwork && MainContract.address,
    //             //     provider
    //             // );

    //             setSpinner(false);
    //             setIs_connected(true);
    //             setWeb3(web3);
    //             setAccounts(user_account);
    //             notifySuccess("Wallet Connected");
    //         } else {
    //             setSpinner(false);
    //             setIs_connected(true);
    //             notifySuccess("Wallet Connected");
    //         }
    //     } catch (error) {
    //         // Catch any errors for any of the above operations.
    //         notifyError(error?.message);
    //         setSpinner(false);
    //     }
    // };
    
    // const disconnectFromWallet = async () => {
    //     try {
    //         if (web3) {
    //             notifySuccess("Wallet disconnected");
    //         }
    //         setSpinner(false);
    //         setIs_connected(false);
    //         setWeb3(null);
    //         setAccounts(null);
    //         setBalance(0);
    //     } catch (error) {
    //         // Catch any errors for any of the above operations.
    //         notifyError(error?.message);
    //         setSpinner(false);
    //     }
    // };
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>

            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Manual Withdraw</h1>
            </div>

            {moreDetails && <MoreDetails props={moreDetails} />}

            {(!moreDetails||spinner)&&<div style={{backgroundColor:'rgba(0, 0, 0, 0.7)',position:'absolute',top:'0',bottom: '0',left:'0',right:'0',zIndex: '1000'}}>
                <div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                    <span class="sr-only"></span>
                </div>
            </div>}

            {/* <div className="card mb-4">
                <div className="card-header fw-bold small">Wallet</div>
                <div className="card-body">
                    <div className='row g-4'>
                        <div className='col-md-6 col-lg-6'>
                            {isConnected&&<w3m-button balance='show'/>} */}
                            {/* <h3 className="mb-2">{balance} BNB</h3> */}
                            {/* <h6 className="mb-2">{0.00} USD</h6> */}
                            {/* {!isConnected&&<div className="d-flex flex-wrap mb-3 gap-2">
                                Connected Address : {"---"}
                            </div>} */}
                            {/* <span disabled={spinner} onClick={()=>open({ view: 'Networks'})} style={{cursor:'pointer'}}><i class="bi bi-hdd-network"></i>{'  '}All Networks</span> */}
                        {/* </div>
                        <div className='col-md-6 col-lg-6'>
                            {!isConnected&&<p className='text-center'>Click To Add Wallet With Us. </p>}
                            <div className='text-center'>
                            {isConnected ? (
                                <button disabled={spinner} onClick={()=>open()} className='btn btn-outline-theme'><i class="bi bi-wallet"></i> Wallet Disconnect</button>
                            ):(
                                <button disabled={spinner} onClick={()=>open()} className='btn btn-outline-theme'><i class="bi bi-wallet"></i> Wallet Connect</button>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}

            {/* <div className="card mb-4">
                <div className="card-header fw-bold small">{banklist&&banklist.length==0?'Add Account':'Select Account'}</div>
                <div className="card-body"> */}
                    {/* <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>#Select</strong>
                                </th>
                                <th>
                                    <strong>Bank Name</strong>
                                </th>
                                <th>
                                    <strong>Account Number</strong>
                                </th>
                                <th>
                                    <strong>Account Holder Name</strong>
                                </th>
                                <th>
                                    <strong>Ifsc</strong>
                                </th>
                            </tr>
                        </thead>
                        {banklist && <tbody>
                            {
                                banklist.map((data, idx) => {
                                    data.status === 1 ? count += 1 : count += 0;
                                    return data.status === 1 && <tr key={idx}>
                                        <td>
                                            <input className="form-check-input" type="radio" name="banklist" value={data.id} onClick={(e) => bankListCheck(e)} />
                                        </td>
                                        <td>{data.bank_name}</td>
                                        <td>{data.bank_account_number}</td>
                                        <td>{data.account_holder_name}</td>
                                        <td>{data.ifsc}</td>
                                    </tr>
                                })
                            }
                        </tbody>}
                    </Table> */}
                    {/* <div style={{display:'flex',gap:'10px'}}>
                        {banklist&&banklist.map((dat,idx)=>{
                            return(<div className="col-xl-5 col-lg-5" style={{width:'48%',cursor:'pointer'}} key={idx} onClick={()=>bankListCheck(dat.id)}>
                                <div className={`card mb-3 ${data.bank_id==dat.id?'bg-info':'border rounded border-primary'}`} style={{height:'100%'}}>
                                    <div className="card-body">
                                        <div className="d-flex fw-bold small mb-3" style={{gap:'20px'}}>
                                                {dat.type=='bank'&&<div style={{display:'flex',flexDirection:'column'}}>
                                                    <h3 className="mb-0">{dat.bank_name.toUpperCase()}</h3>
                                                    <h6><strong>Account Number</strong> : {dat.ac_no}</h6>
                                                    <h6><strong>Account Holder Name</strong> : {dat.holder_name}</h6>
                                                    <h6><strong>Ifsc</strong> : {dat.ifsc}</h6>
                                                </div>}
                                                {dat.type=='crypto'&&<div style={{display:'flex',flexDirection:'column'}}>
                                                    <h3 className="mb-0">{dat.network.toUpperCase()}</h3>
                                                    <h6><strong>Account</strong> : {dat.address}</h6>
                                                </div>} */}
                                                {/* <div style={{display:'flex',flexDirection:'column'}}>
                                                    <h3 className="mb-0">{dat.network.toUpperCase()}</h3>
                                                    <h6><strong>Account</strong> : {dat.address}</h6>
                                                </div> */}
                                            {/* </div>
                                        </div>
                                    <div className="card-arrow">
                                        <div className="card-arrow-top-left"></div>
                                        <div className="card-arrow-top-right"></div>
                                        <div className="card-arrow-bottom-left"></div>
                                        <div className="card-arrow-bottom-right"></div>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                    {banklist&&banklist.length == 0 && <span style={{ display: 'block', textAlign: 'center', marginBottom: '2rem' }}>Add New Account Details. <span style={{cursor:'pointer',textDecoration:'underline',color:'var(--bs-theme)'}} onClick={()=>navigate('/user/manualBankList')}>Click here</span></span>}
                </div>
               
                {(data.bank_id == '' && dataError.bank_id) && <span className="required" style={{paddingLeft:'15px',paddingBottom:'15px'}}>This Field Is Required.</span>}
            </div> */}

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 mb-4">
                            <label className="text-label mb-1">Amount({process.env.REACT_APP_Amount_Symbol||"$"})<span className="required">*</span></label>
                            <input type="text" name="amount" min={0} className="form-control" placeholder="enter amount" value={data.amount} onChange={(e) => handleNum(e,'amount')} required />
                            {(data.amount == '' && dataError.amount) && <span className="required">This Field Is Required.</span>}
                            {(showAmountError&&!dataError.amount)&&<span className="required">Insufficient Balance</span>}
                        </div>
                    </div>
                    {/* {(cDate.getDate()>=1 && cDate.getDate()<=5) */}
                    <button className="btn btn-primary" onClick={() => { showPin() }}>
                        Next
                    </button>
                    {/* :<button className="btn btn-primary" style={{cursor:'not-allowed'}}>
                        Next
                    </button> */}
                    {/* } */}
                </div>
            </div>
            {/* <div>
                <span style={{fontSize:16}}>Note: </span><span style={{color:'red'}}>Withdraw request should be from 1 to 5 of a month</span>
            </div> */}

            {showAuth &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(0,0,0,0.5)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header>
                        <Modal.Title style={{ fontFamily: 'system-ui', fontWeight: 'bold' }}>Verify Your Account</Modal.Title>
                        <button type="button" class="btn-close" aria-label="Close" onClick={() => { setShowAuth(false); setAuthNum(''); setAuthError(false) }} />
                    </Modal.Header>
                    <Modal.Body style={{ alignSelf: 'center' }}>
                        <strong style={{ fontSize: '15px' }}>Enter You {sessionStorage.getItem('auth_type').toUpperCase()} To Complete Your Transaction</strong>
                        <OtpInput
                            containerStyle={{ justifyContent: 'center', marginTop: '1.5rem' }}
                            inputStyle={{ justifySelf: 'center' }}
                            value={authNum}
                            onChange={setAuthNum}
                            numInputs={sessionStorage.getItem('auth_type') == "authenticator" ? 6 : 4}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} style={{ margin: '0.5rem', width: '3rem', height: '3.5rem', borderRadius: '5px', textAlign: 'center' }} />}
                        />
                        {sessionStorage.getItem('auth_type') == 'otp' && !showTimmer && <span style={{ color: 'var(--primary)', cursor: 'pointer', margin: '8px' }} onClick={requestOTP}>Request OTP</span>}
                        {sessionStorage.getItem('auth_type') == 'otp' && showTimmer && <span style={{ color: 'var(--primary)', margin: '8px' }}>Resend request after {counter} sec</span>}
                        {(authNum == '' && authError) && <span className="required" style={{ marginLeft: '12%' }}>This Field Is Required.</span>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }} onClick={(e) => sendData(e)} disabled={loader}>
                            Withdraw
                            {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
        </Frame>
    );
}

export default ManualWithdraw;