import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SidebarClick } from '../../routes/route';
import { notifyError } from '../../parts/Toast';
import { getReq } from '../../requests/request';
import {Col,Card,Table, Spinner} from "react-bootstrap";
import { motion } from "framer-motion";
import Frame from "../../components/frame";

export default function DownlineDetails() {
    const pSymbol = '$';
    const navigate = useNavigate();
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [refrashPage,setRefrashPage] = useState(false);
    let durationanim = 0.15;
    let countData=0;
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/user/downlineDetails');
        setCollapseId(6);
        (async()=>{
            try{
                setSpinner(true);
                let r = await getReq('user-team-details',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setTable(r.downlineDetails);
                    setSpinner(false);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                setSpinner(false);
                notifyError("Request failed login again");
            }
        })()
    },[refrashPage]);
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
             <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Downline</h1>
            </div>

            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {table&&<div className="card">
            
            <div className="card-body">
            <Table responsive style={{textAlign:'center'}}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Level</strong>
                                </th>
                                <th>
                                    <strong>User Count</strong>
                                </th>
                                <th>
                                    <strong>Investment</strong>
                                </th>
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>{
                                countData +=1;
                                return(<motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                                    <td>{data.level}</td>
                                    <td>{data.user_count}</td>
                                    <td>{process.env.REACT_APP_Amount_Symbol||'$'}{data.investment}</td>
                                </motion.tr>)})
                            }
                        </tbody>}
                        {countData===0&&<span style={{display:'table-caption'}}>No Data For Action</span>}
                    </Table>
            </div>
            
        </div>}

        </Frame>
    )
}
