import React, { useEffect, useState } from 'react';
import { getReq, postForm } from '../requests/request';
import { notifyError,notifySuccess } from './Toast';
import KYCModel from './KYCModel';
import { useNavigate } from 'react-router-dom';

export default function KYCAddress({props}) {
    const {kycPage,setKycPage,setRefrashPage} = props;
    const navigate = useNavigate();
    const [docType,setDocType] = useState();
    const [loader,setLoader] = useState(false);
    const [showModel,setShowModel] = useState(false);
    const [KYCData,setKYCData] = useState({'front':'','back':'','document_name':'','document_type':'address','document_number':''});
    useEffect(()=>{
        (async()=>{
            try{
                let r = await getReq('get/doc-type',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setDocType(r.data.address);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
            }
            catch(err){
                if(err.response.status===401||err.response.status===404){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
				    navigate('/',{ replace: true });
                }
                notifyError("Request failed ERR_BAD_REQUEST1");
            }
        })()
    },[])
    async function UploadKYCData(){
        setLoader(true);
        let result = await postForm('kyc-doc-upload',KYCData,sessionStorage.getItem('token'));
        if(result.message && result.status){
            setLoader(false);
            notifySuccess(result.message);
            setKYCData({'front':'','back':'','document_name':'','document_type':'','document_number':''});
            setKycPage(kycPage+1);
            setRefrashPage(true);
        }
        if(result.status==false && result.msg==401){
            sessionStorage.clear();
            window.history.replaceState(null, null, "/");
            navigate('/',{ replace: true });
            notifyError('Authentication Failed. Please Login Again....');
        }
        if(result.message && result.status==false){
            setLoader(false);
            notifyError(result.message);
            setShowModel(true);
        }
        if(result.errors){
            setLoader(false);
            if(typeof result.errors === 'string'){
                notifyError(result.errors)
            }
            else{
                setLoader(false);
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            setShowModel(true);
        }
        setRefrashPage(true);
    }
    function handleKYCStrNum(e,key){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9]/ig, '');
        let temp = {...KYCData}
        temp[key] = value
        setKYCData(temp);
	};
    return (
        <div>
            <h3 className='mb-4'>Upload Address Proof</h3>
            <div>
                <div className="mb-4">
                    <label className="mb-1">Document Name <span className="required">*</span></label>
                    {/* <input type="text" className="form-control" value={KYCFData.document_name} onChange={(e)=>{setKYCFData({...KYCFData,'document_name':e.target.value});setKYCBData({...KYCBData,'document_name':e.target.value})}} /> */}
                    <div className="form-group">
                        <select name="select_box" className="form-control" required style={{textTransform:'capitalize'}} onChange={(e)=>{setKYCData({...KYCData,'document_name':e.target.value})}}>
                            <option value="">Select Document Name</option>
                            {
                                docType && docType.map((data,idx)=>
                                    <option value={data} key={idx} style={{textTransform:'capitalize'}}>{data.replaceAll('_',' ')}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="mb-1">Document Number <span className="required">*</span></label>
                    <input type="text" className="form-control" value={KYCData.document_number} onChange={(e)=>{handleKYCStrNum(e,'document_number')}} />
                </div>
                <h4>Front Side</h4>
                <div className="mb-4">
                    <label className="mb-1">Frontside Img <span className="required">*</span></label>
                    <input type="file" accept="image/png,image/jpeg" className="update-flie form-control" onChange={(e)=>setKYCData({...KYCData,'front':e.target.files[0]})}/>
                    <span>Image size sholud not be gretaer than 2MB</span>
                </div>
            </div>
            <div>
                <h4>Back Side</h4>
                <div className="mb-4">
                    <label className="mb-1">Backside Img <span className="required">*</span></label>
                    <input type="file" accept="image/png,image/jpeg" className="update-flie form-control" onChange={(e)=>setKYCData({...KYCData,'back':e.target.files[0]})}/>
                    <span>Image size sholud not be gretaer than 2MB</span>
                </div>
            </div>
            <div style={{display:"flex",justifyContent:"right",alignItems: "center",marginRight:"1rem"}}>
                <button type="submit" className="btn btn-primary" onClick={()=>setShowModel(true)}>Next</button>
            </div>
            {showModel&&<KYCModel props={{setShowModel,UploadKYCData,loader,setLoader}}/>}
        </div>
    )
}
