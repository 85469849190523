import React, { useState, useEffect, useContext } from 'react';
import {Col,Card,Table, Spinner} from "react-bootstrap";
import { motion } from "framer-motion";
import { notifyError } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { getFilterTable, getReq, getTable } from '../../requests/request';
import { useNavigate } from 'react-router-dom';
import Frame from "../../components/frame";
import moment from 'moment/moment';
function ManualWithdrawList() {
    const navigate = useNavigate();
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [table,setTable] = useState();
    const [filterShow,setFilterShow] = useState(false);
    const [spinner,setSpinner] = useState(false);
    const [withdrawListData,setWithdrawListData] = useState('');
    const [refrashPage,setRefrashPage] = useState(false);
    let countData = 0;
    let durationanim = 0.15;
    let AmountSymbol = '$';
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/user/WithdrawList');
        setCollapseId(5);
        (async()=>{
            try{
                //setSpinner(true);
                let r = await getReq('user/manual/withdraw/list',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setWithdrawListData(r.data);
                    setTable(r.data.data);
                    //setSpinner(false);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if(r.errors){
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                //setSpinner(false);
                notifyError("Request failed login again");
            }
        })()
    },[refrashPage]);
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`user/manual/withdraw/list?page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setWithdrawListData(r.data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.status==false && r.msg==401){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getFilterTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setWithdrawListData(r.data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    return ( 
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
         <div className="d-flex align-items-center mb-md-3 mb-2">
            <h1 className="page-header mb-0">Withdraw History</h1>
        </div>

        {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
            <span class="sr-only"></span>
        </div>}

        {table&&<div className="card">
            <div className="card-body">
            <div className="input-group mb-4">
                <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
            </div>
            {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
            </div>}
            <Table responsive style={{textAlign:'center'}}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Code</strong>
                                </th>
                                <th>
                                    <strong>Method</strong>
                                </th>
                                {/* <th>
                                    <strong>Withdraw From</strong>
                                </th>
                                <th>
                                    <strong>Address</strong>
                                </th> */}
                                <th>
                                    <strong>Tnx Hash</strong>
                                </th>
                                {/* <th>
                                    <strong>Bank Name</strong>
                                </th> */}
                                {/* <th>
                                    <strong>Account Details</strong>
                                </th> */}
                                <th>
                                    <strong>Amount From</strong>
                                </th>
                                <th>
                                    <strong>Amount To</strong>
                                </th>
                                <th>
                                    <strong>Net Amount</strong>
                                </th>
                                <th>
                                    <strong>Remarks</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                                <th>
                                    <strong>Action Time</strong>
                                </th>
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>{
                                countData += 1; 
                                return (<motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                                    <td>
                                        <div className='expendHash'>
                                            {data.code}
                                        </div>
                                    </td>
                                    <td>{data.coin_type}</td>
                                    {/* <td>{data.withdraw_from}</td>
                                    <td>{data.address||'-'}</td> */}
                                    <td>
                                        <div className='expendHash'>
                                            {data.t_hash||'-'}
                                        </div>
                                    </td>
                                    {/* <td>
                                        {data.coin_type=='bank'&&<div style={{display:'flex',flexDirection:'column',textWrap:'nowrap'}}>
                                            <div><div><div style={{fontWeight:'600'}}>{'Bank Name - '}</div>{data.bank_name}</div></div>
                                            <div><div><div style={{fontWeight:'600'}}>{'Account No. - '}</div>{data.ac_no}</div></div>
                                            <div><div><div style={{fontWeight:'600'}}>{'Account Holder Name - '}</div>{data.holder_name}</div></div>
                                            <div><div><div style={{fontWeight:'600'}}>{'IFSC - '}</div>{data.ifsc}</div></div>
                                        </div>}
                                        {data.coin_type=='crypto'&&<div style={{display:'flex',flexDirection:'column',textWrap:'nowrap'}}>
                                            <div><div><div style={{fontWeight:'600'}}>{'Network - '}</div>{data.network}</div></div>
                                            <div><div><div style={{fontWeight:'600'}}>{'Account - '}</div>{data.address}</div></div>
                                        </div>}
                                    </td> */}
                                    {/* <td>{data.bank_ac_no}</td> */}
                                    <td>{process.env.REACT_APP_Amount_Symbol||"$"} {parseFloat(data.amount_from)}</td>
                                    <td>{process.env.REACT_APP_Amount_Symbol||"$"} {parseFloat(data.amount_to)}</td>
                                    <td>{process.env.REACT_APP_Amount_Symbol||"$"} {parseFloat(data.net_amount)}</td>
                                    <td>{data.remarks}</td>
                                    <td>
                                        {data.status===0 && <span className="badge light btn btn-warning">Pending</span>}
                                        {data.status===1 && <span className="badge light btn btn-success">Approved</span>}
                                        {data.status===2 && <span className="badge light btn btn-success">Completed</span>}
                                        {data.status===3 && <span className="badge light btn btn-danger">Rejected</span>}
                                    </td>
                                    <td>{data.process_at&&moment(data.process_at).format('YYYY-MM-DD HH:mm')||'-'}</td>
                                    {/* <td>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={()=>{setShowEditModel(true);editBankFun(data.id)}}>Edit</button>
                                    </td> */}
                                </motion.tr>)})
                            }
                        </tbody>}
                        {countData===0&&<span style={{display:'table-caption'}}>No Data For Action</span>}
                    </Table>
                    {table&&
                    <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                        <p className="h6">
                            Showing <span className="font-medium">{withdrawListData.from||'-'}</span> to <span className="font-medium">{withdrawListData.to||'-'}</span> of{' '}
                            <span className="font-medium">{withdrawListData.last_page}</span> results
                        </p>
                        <div className="d-flex">
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(withdrawListData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link cursor-pointer">&laquo;</span>
                                            
                                        </a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}}>
                                        <a className="page-link">{withdrawListData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(withdrawListData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}

            </div>
            
        </div>}
        </Frame>
    );
}

export default ManualWithdrawList;