import axios from "axios";

export async function login(data,token) {
    try{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`,data,{headers});
        return await result.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function prelogin(data) {
    try{
        const headers = {
            'Content-Type': 'application/json',
        };
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/pre-login`,data,{headers});
        return await result.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function register(data) {
    try{
        const headers = {
            'Content-Type': 'application/json',
        };
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/register`,data,{headers});
        return await result.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function verify(data){
    try{
        const headers = {
            'Content-Type': 'application/json',
        };
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/verify-email`,data,{headers});
        return await result.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function sendOtp(data){
    try{
        const headers = {
            'Content-Type': 'application/json',
        };
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/resendotp`,JSON.stringify(data),{headers});
        return await result.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function forgetPassword(data){
    try{
        const headers = {
            'Content-Type': 'application/json',
        };
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/forget-password`,JSON.stringify(data),{headers});
        return await result.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function logout(token){
    try{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/logout`,{},{headers});
        return await result.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function logoutall(token){
    try{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
        let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/logout-all`,{},{headers});
        return await result.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function postReq(url,data,token){
    try{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept':'application/json',
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${url}`, data, {headers});
        return await response.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function getReq(url,token){
    try{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept':'application/json',
        };
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${url}`, {headers});
        return await response.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else if(err.message=="Network Error"){
            return {status:false,msg:401};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function postTable(url,data,token){
    try{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept':'application/json',
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${url}`, data, {headers});
        return await response.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function getTable(url,token){
    try{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept':'application/json',
        };
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${url}`, {headers});
        return await response.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}

export async function getFilterTable(url,token){
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept':'application/json',
    };
    const response = await axios.get(url, {headers});
    return await response.data;
}

export async function postForm(url,data,token){
    try{
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
            'Accept':'application/json',
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${url}`, data, {headers});
        return await response.data;
    }
    catch(err){
        if(err.response?.status==401){
            return {status:false,msg:err.response?.status};
        }
        else{
            return {status:false,msg:err.message};
        }
    }
}
