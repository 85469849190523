import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SidebarClick } from '../../routes/route';
import { getFilterTable, getReq, getTable, postReq } from '../../requests/request';
import { notifyError } from '../../parts/Toast';
import {Col,Card,Table, Spinner} from "react-bootstrap";
import { motion } from "framer-motion";
import MoreDetails from '../../parts/MoreDetails';
import Frame from "../../components/frame";
import moment from 'moment/moment';

export default function Logs() {
    const navigate = useNavigate();
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [payoutsListData,setPayoutsListData] = useState('');
    const [refrashPage,setRefrashPage] = useState(false);
    const [filterShow,setFilterShow] = useState(false);
    const [moreDetails,setMoreDetails] = useState(null);
    let durationanim = 0.15;
    let countData = 0;
    let AmountSymbol = '$';
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/user/logs');
        setCollapseId(6);
        (async()=>{
            try{
                //setSpinner(true);
                let r = await getReq('logs',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setPayoutsListData(r.data);
                    setTable(r.data.data);
                    setMoreDetails(r.more_details);
                    //setSpinner(false);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if(r.errors){
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                //setSpinner(false);
                notifyError("Request failed login again");
            }
        })()
    },[refrashPage]);
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getFilterTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setPayoutsListData(r.data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`logs?page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setPayoutsListData(r.data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.status==false && r.msg==401){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
           
            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Logs</h1>
            </div>

            {moreDetails&&<MoreDetails props={moreDetails}/>}

            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {table&&<div className="card">
            <div className="card-header fw-bold small">Log History</div>
            <div className="card-body">
            <div className="input-group mb-4">
                <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
            </div>
            {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
            </div>}
            <Table responsive style={{textAlign:'center'}}>
                <thead>
                    <tr>
                        <th>
                            <strong>Id</strong>
                        </th>
                        <th>
                            <strong>IP Address</strong>
                        </th>
                        <th>
                            <strong>Time</strong>
                        </th>
                    </tr>
                </thead>
                {table&&<tbody>
                    {
                        table.map((data,idx)=>{
                        countData+=1
                        return(<motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                            <td>{idx+1}</td>
                            <td>{data.ip}</td>
                            <td>{moment(data.created_at).format('DD-MM-YYYY HH:mm')}</td>
                        </motion.tr>)})
                    }
                </tbody>}
                {countData===0&&<span style={{display:'table-caption'}}>No Data For Action</span>}
            </Table>
            {table&&<div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                <p className="h6">
                    Showing <span className="font-medium">{payoutsListData.from||'-'}</span> to <span className="font-medium">{payoutsListData.to||'-'}</span> of{' '}
                    <span className="font-medium">{payoutsListData.last_page}</span> results
                </p>
                <div className="d-flex">
                    
                    <nav aria-label="Page navigation">
                        <ul className="pagination">
                            <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(payoutsListData.prev_page_url)}>
                                <a className="page-link" aria-label="Previous">
                                    <span className="btn-link cursor-pointer">&laquo;</span>
                                    
                                </a>
                            </li>
                            <li className="page-item" style={{cursor:'pointer'}}>
                                <a className="page-link">{payoutsListData.current_page}</a>
                            </li>
                            <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(payoutsListData.next_page_url)}>
                                <a className="page-link" aria-label="Next">
                                    <span className="btn-link">&raquo;</span>
                                    
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>}
            </div>
            
        </div>}
        </Frame>
    )
}
