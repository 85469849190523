import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { sendOtp, verify } from '../requests/request';
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../parts/Toast';

export default function VerifyEmail() {
    const navigate = useNavigate();
    const [data,setData] = useState({'username':'','otp':''});
    const [counter,setCounter] = useState(60);
    const [showTimmer,setShowTimmer] = useState(false);
    const [blockOtp,setBlockOtp] = useState(true);
    const [loader,setLoader] = useState(false);
	var globalCount = 60;
    const [otp1,setOtp1] = useState("");
    const [otp2,setOtp2] = useState("");
    const [otp3,setOtp3] = useState("");
    const [otp4,setOtp4] = useState("");
    useEffect(()=>{
        (
            async ()=>{
                let data = {"username":sessionStorage.getItem('username')}
                resendOtp();
            }
        )()
    },[]);
    async function verifyOTP(){
        setLoader(true);
        let temp = data;
        temp.otp = `${otp1}${otp2}${otp3}${otp4}`;
        temp.username = sessionStorage.getItem('username');
        let result = await verify(temp);
        if(result.status&&result.username==data.username){
            navigate('/login',{ replace: true });
        }
        if(result.message && result.status){
            notifySuccess(result.message);
        }
        if(result.message && !result.status){
            notifyError(result.message);
        }
        if(result.errors && !result.status){
            let err = Object.values(result.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        setLoader(false);
    }
    async function resendOtp(){
        setBlockOtp(false);
        let data = {"username":sessionStorage.getItem('username'),"from":''}
        let result = await sendOtp(data);
        if((result.message||result.msg) && result.status){
            notifySuccess(result.message||result.msg);
            var OtpInterval = setInterval(function () {
                setCounter((prev)=>prev-1);
                globalCount-=1;
                if(globalCount<=0){
                    setCounter(60);
                    globalCount=60;
                    setShowTimmer(false);
                    setBlockOtp(true);
                    clearInterval(OtpInterval);
                }
            }, 1000);
            setShowTimmer(true);
        }
        else{
            notifyError(result.message||result.msg);
            setBlockOtp(true);
        }
    }
    function otpChange(idx,d){
        if(!isNaN(parseInt(d))){
            if(idx===1){
                setOtp1(d);
                document.getElementById("inpOtp2").focus();
            }
            if(idx===2){
                setOtp2(d);
                document.getElementById("inpOtp3").focus();
            }
            if(idx===3){
                setOtp3(d);
                document.getElementById("inpOtp4").focus();
            }
            if(idx===4){
                setOtp4(d);
            }
        }
        else{
            if(idx===1){
                if(d=="")
                {
                    setOtp1("");
                }
            }
            if(idx===2){
                if(d=="")
                {
                    setOtp2("");
                }
            }
            if(idx===3){
                if(d=="")
                {
                    setOtp3("");
                }
            }
            if(idx===4){
                if(d=="")
                {
                    setOtp4("");
                }
            }
        }
    }
    return (
        <>


            <div className="login">
				<div className="login-content" style={{marginTop:'5em'}}>
					<div className='login_logo'>
						<a href="/" class="auth-logo mb-4">
							<img src="/assets/img/logo.png" alt="" />
						</a>
					</div>
                    <div className="p-4">
            <h4 class="mb-2">Verify email to login</h4>
            <div className="">
                <span className="verify-mobile-text">Enter the code we just send on your email</span>
                <div className="d-flex flex-row mt-4 d-flex justify-content-center gap-4">
                    <input style={{textAlign:'center',width:'3rem',height:'3rem'}} type="text" id='inpOtp1' value={otp1} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(1,e.target.value)}} className="verify-form-control" autoFocus/>
                    <input style={{textAlign:'center',width:'3rem',height:'3rem'}} type="text" id='inpOtp2' value={otp2} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(2,e.target.value)}} className="verify-form-control"/>
                    <input style={{textAlign:'center',width:'3rem',height:'3rem'}} type="text" id='inpOtp3' value={otp3} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(3,e.target.value)}} className="verify-form-control"/>
                    <input style={{textAlign:'center',width:'3rem',height:'3rem'}} type="text" id='inpOtp4' value={otp4} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(4,e.target.value)}} className="verify-form-control"/>
                </div>
                <div className="text-center mt-5">
                    <span className="d-block verify-mobile-text">Don't receive the code?</span>
                    {!showTimmer&&<span onClick={blockOtp?resendOtp:null} className="font-weight-bold text-danger verify-cursor" style={{cursor:'pointer'}}>Resend</span>}
                    {showTimmer&&<span style={{color:'var(--primary)'}} className="font-weight-bold text-danger">Resend request after {counter} sec</span>}
                </div>
                <div className='d-flex justify-content-center mt-2'>
                    <Button variant="primary" onClick={verifyOTP} disabled={loader}>
                        Submit{' '}
                        {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </Button>
                </div>
            </div>
        </div>
				</div>
			</div>


       
        </>
    )
}
