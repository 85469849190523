import React, { useContext, useEffect, useState } from 'react';
import { getReq, logout } from '../requests/request';
import { notifyError, notifySuccess } from '../parts/Toast';
import { useNavigate,Link, NavLink,useLocation } from "react-router-dom";
import { userInfo } from '../App';
import $ from "jquery";
import { SidebarClick } from '../routes/route';


function Leftpart() {
	const { hideBinary,hideSingleLeg } = useContext(SidebarClick);
	$(document).ready(function () {
	});
	const location = useLocation();
	const { hash, pathname, search } = location;
	const { username } = useContext(userInfo);
	const [img, setImg] = useState(null);
	const [clickId,setClickId] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
			(async () => {
			try {
				let r = await getReq('get-picture', sessionStorage.getItem('token'))
				if (r.status === "success" || r.status === true) {
					setImg(r.data);
				}
				if (r.status == false && r.msg == 401) {
				sessionStorage.clear();
				window.history.replaceState(null, null, "/");
				navigate('/', { replace: true });
				//notifyError('Authentication Failed. Please Login Again....');
				}
			}
			catch (err) {
				if (err.message) {
				sessionStorage.clear();
				window.history.replaceState(null, null, "/");
				navigate('/', { replace: true });
				}
				notifyError("Request failed!!!");
			}
			})()
		},[]);
  async function onLogout() {
    let result = await logout(sessionStorage.getItem('token'));
    if (result.errors) {
      let err = Object.values(result.errors);
      //err.map((data)=>notifyError(data[0]));
      notifyError(err[0][0]);
    }
    if (result.status == true) {
      notifySuccess('You Are Logged Out!!')
      sessionStorage.clear();
      window.history.replaceState(null, null, "/");
      navigate('/', { replace: true });
	  window.location.reload();
    }
    if (result.status == false && result.msg == 401) {
      sessionStorage.clear();
      window.history.replaceState(null, null, "/");
      navigate('/', { replace: true });
      notifyError('Please Login Again!')
    }
  }	
      return (
        <nav id="sidebar" className="sidebar-wrapper">
		 <div className="app-brand p-3 mb-3">
            <a href="/user/dashboard">
              <img src="/assets/img/logo-dark.png" className="logo" alt="" />
              <img src="/assets/img/favicon.png" className="favicon" alt="" />
            </a>
          </div>
         
          <div className="sidebarMenuScroll">
            <ul className="sidebar-menu">
				<li>
					<a href="/user/dashboard" className={pathname=="/user/dashboard"?'active menu-link':null}>
						<i className="bi bi-shop-window"></i>
						<span className="menu-text">Dashboard</span>
					</a>
				</li>
				<li>
					<a href="/user/profile" className={pathname=="/user/profile"?'active menu-link':null}>
						<i className="bi bi-person"></i>
						<span className="menu-text">Profile</span>
					</a>
				</li>
				<li>
					<a href="/user/kyc" className={pathname=="/user/kyc"?'active menu-link':null}>
						<i className="bi bi-file-text"></i>
						<span className="menu-text">KYC</span>
					</a>
				</li>
				{/* <li>
					<a href="/user/rankDetails" className={pathname=="/user/rankDetails"?'active menu-link':null}>
						<i className="bi bi-graph-up"></i>
						<span className="menu-text">Rank Details</span>
					</a>
				</li> */}
				
				<li className={`treeview ${
						(
						pathname=='/user/manualDepositList'||
						clickId==0)?'active':null
					}`} onClick={()=>setClickId(0)}>
					<a style={{cursor:'pointer'}}><i className={`bi bi-stickies`}></i>
						<span className="menu-text">Deposit</span>
					</a>
					<ul className="treeview-menu">
						{/* <li>
							<a href="/user/manualDeposit" className={pathname=="/user/manualDeposit"?'active':null}>
								Deposit
							</a>
						</li> */}
						<li>
							<a href="/user/manualDepositList" className={pathname=="/user/manualDepositList"?'active':null}>
								Deposit
							</a>
						</li>
					</ul>
				</li>
				<li className={`treeview ${
						(pathname=='/user/packageList'||
						pathname=='/user/subscriptionHistory'||
						pathname=='/user/botsubscriptionHistory'||
						clickId==1)?'active':null
					}`} onClick={()=>setClickId(1)}>
					<a style={{cursor:'pointer'}}><i className={`bi bi-gem`}></i>
						<span className="menu-text">Subscription</span>
					</a>
					<ul className="treeview-menu">
						<li>
							<a href="/user/packageList" className={pathname=="/user/packageList"?'active':null}>
								Purchase Package
							</a>
						</li>
						<li>
							<a href="/user/subscriptionHistory" className={pathname=="/user/subscriptionHistory"?'active':null}>
								Subscription History
							</a>
						</li>
						<li>
							<a href="/user/botsubscriptionHistory" className={pathname=="/user/botsubscriptionHistory"?'active':null}>
								Bot Subscription History
							</a>
						</li>
					</ul>
				</li>
				{/* <li className={`treeview ${
						(pathname=='/user/botpackageList'||
						pathname=='/user/botsubscriptionHistory'||
						clickId==2)?'active':null
					}`} onClick={()=>setClickId(2)}>
					<a style={{cursor:'pointer'}}><i className={`bi bi-robot`}></i>
						<span className="menu-text">Bot Subscription</span>
					</a>
					<ul className="treeview-menu">
						<li>
							<a href="/user/botpackageList" className={pathname=="/user/botpackageList"?'active':null}>
								Bot Package
							</a>
						</li>
						<li>
							<a href="/user/botsubscriptionHistory" className={pathname=="/user/botsubscriptionHistory"?'active':null}>
								Bot Subscription History
							</a>
						</li>
					</ul>
				</li> */}
				<li className={`treeview ${
						(
						// pathname=='/user/transfer'||
						pathname=='/user/transactions'||
						pathname=='/user/payouts'||
						pathname=='/user/levels'||
						clickId==2)?'active':null
					}`} onClick={()=>setClickId(2)}>
					<a style={{cursor:'pointer'}}><i className={`bi bi-currency-dollar`}></i>
						<span className="menu-text">Finance</span>
					</a>
					<ul className="treeview-menu">
						{/* <li>
							<a href="/user/transfer" className={pathname=="/user/transfer"?'active':null}>
								Transfer
							</a>
						</li> */}
						<li>
							<a href="/user/transactions" className={pathname=="/user/transactions"?'active':null}>
								Transactions
							</a>
						</li>
						<li>
							<a href="/user/payouts" className={pathname=="/user/payouts"?'active':null}>
								Payouts
							</a>
						</li>
					</ul>
				</li>
				<li className={`treeview ${
						(pathname=='/user/manualBankList'||
						pathname=='/user/manualWithdraw'||
						pathname=='/user/manualWithdrawList'||
						clickId==3)?'active':null
					}`} onClick={()=>setClickId(3)}>
					<a style={{cursor:'pointer'}}><i className={`bi bi-share`}></i>
						<span className="menu-text">Withdraw</span>
					</a>
					<ul className="treeview-menu">
						{/* <li>
							<a href="/user/manualBankList" className={pathname=="/user/manualBankList"?'active':null}>
								Manage Account
							</a>
						</li> */}
						<li>
							<a href="/user/manualWithdraw" className={pathname=="/user/manualWithdraw"?'active':null}>
								Withdraw
							</a>
						</li>
						<li>
							<a href="/user/manualWithdrawList" className={pathname=="/user/manualWithdrawList"?'active':null}>
								Withdraw History
							</a>
						</li>
					</ul>
				</li>
				<li className={`treeview ${
						(pathname=='/user/binaryList'||
						pathname=='/user/directList'||
						pathname=='/user/downlineDetails'||
						pathname==='/user/singleLeg'||
						clickId==4)?'active':null
					}`} onClick={()=>setClickId(4)}>
					<a style={{cursor:'pointer'}}><i className={`bi bi-layout-sidebar`}></i>
						<span className="menu-text">Affiliate</span>
					</a>
					<ul className="treeview-menu">
						{(hideBinary == 1) &&<li> 
							<a href="/user/binaryList" className={pathname=="/user/binaryList"?'active':null}>
								Binary Team
							</a>
						</li>}
						{(hideSingleLeg == 1)&&<li>
							<a href="/user/singleLeg" className={pathname=="/user/singleLeg"?'active menu-link':null}>
								Single Leg
							</a>
						</li>}
						<li>
							<a href="/user/directList" className={pathname=="/user/directList"?'active menu-link':null}>
								Referral Team
							</a>
						</li>
						<li>
							<a href="/user/downlineDetails" className={pathname=="/user/downlineDetails"?'active menu-link':null}>
								Downline Details
							</a>
						</li>
						<li>
							<a href="/user/levels" className={pathname=="/user/levels"?'active':null}>
								Level Details
							</a>
						</li>
					</ul>
				</li>
				<li className={`treeview ${
						(pathname=='/user/roiIncomes'||
						pathname=='/user/unilevelIncomes'||
						pathname=='/user/leaderIncomes'||
						pathname==='/user/universelLegIncomes'||
						clickId==5)?'active':null
					}`} onClick={()=>setClickId(5)}>
					<a style={{cursor:'pointer'}}><i className={`bi bi-stickies`}></i>
						<span className="menu-text">Incomes</span>
					</a>
					<ul className="treeview-menu">
						<li> 
							<a href="/user/roiIncomes" className={pathname=="/user/roiIncomes"?'active':null}>
							Daily Growth Bonus
							</a>
						</li>
						<li>
							<a href="/user/unilevelIncomes" className={pathname=="/user/unilevelIncomes"?'active menu-link':null}>
							Unilevel Incomes
							</a>
						</li>
						<li>
							<a href="/user/leaderIncomes" className={pathname=="/user/leaderIncomes"?'active menu-link':null}>
							Leadership Incomes
							</a>
						</li>
						<li>
							<a href="/user/universelLegIncomes" className={pathname=="/user/universelLegIncomes"?'active menu-link':null}>
							Universel Leg Incomes
							</a>
						</li>
					</ul>
				</li>
				<li>
					<a href="/user/logs" className={pathname=="/user/logs"?'active menu-link':null}>
						<i className="bi bi-box-arrow-in-right"></i>
						<span className="menu-text">Logs</span>
					</a>
				</li>
				<li>
					<a href="/user/salaryLog" className={pathname=="/user/salaryLog"?'active menu-link':null}>
						<i className="bi bi-list"></i>
						<span className="menu-text">Leadership Details</span>
					</a>
				</li>
				<li>
					<a href="https://app.parqet.com/p/65cdde6b2532ad900cafaa08" target="_blank" rel="noopener noreferrer" className={pathname=="/user/portfolio"?'active menu-link':null}>
						<i className="bi bi-person-bounding-box"></i>
						<span className="menu-text">Our Portfolio</span>
					</a>
				</li>
				{/* <li>
					<a href="/user/news" className={pathname=="/user/news"?'active menu-link':null}>
						<i className="bi bi-newspaper"></i>
						<span className="menu-text">News</span>
					</a>
				</li> */}
            </ul>
          </div>
		  {/* <div className="sidebar-settings gap-1 d-lg-flex d-none">
		  	<a href="/user/dashboard" data-bs-toggle="tooltip" data-bs-placement="top" className={pathname=="/user/kyc"?'active settings-icon':'settings-icon'}
              data-bs-custom-class="custom-tooltip-info" title="Dashboard">
              <i className="bi bi-shop-window"></i>
            </a>
            <a href="/user/profile" data-bs-toggle="tooltip" data-bs-placement="top" className={pathname=="/user/kyc"?'active settings-icon':'settings-icon'}
              data-bs-custom-class="custom-tooltip-danger" title="Profile">
              <i className="bi bi-person"></i>
            </a>
            <a href="/user/kyc" data-bs-toggle="tooltip" data-bs-placement="top" className={pathname=="/user/kyc"?'active settings-icon':'settings-icon'}
              data-bs-custom-class="custom-tooltip-warning" title=" KYC">
              <i className="bi bi-file-text"></i>
            </a>
            <a href="/user/packageList" data-bs-toggle="tooltip" data-bs-placement="top" className={pathname=="/user/kyc"?'active settings-icon':'settings-icon'}
              data-bs-custom-class="custom-tooltip-success" title=" Package">
              <i className="bi bi-box"></i>
            </a>
           
            <a href="#" onClick={onLogout} data-bs-toggle="tooltip" data-bs-placement="top" className={pathname=="/user/kyc"?'active settings-icon':'settings-icon'}
              data-bs-custom-class="custom-tooltip-secondary" title="Logout">
              <i className="bi bi-power"></i>
            </a>
          </div> */}
        </nav>
  );
}

  export default Leftpart;