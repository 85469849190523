import React, { useContext, useEffect, useState } from 'react';
import { Col, Card, Table, Spinner } from "react-bootstrap";
import { getFilterTable, getReq, getTable, postReq, sendOtp } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import Frame from "../../components/frame";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OtpInput from 'react-otp-input';
import CheckFormError from '../../parts/FormError';
import OpenImg from '../../parts/OpenImageTab';
import moment from 'moment/moment';
import Filter from '../../parts/Filter';

export default function BotSubscriptionHistory() {
    const navigate = useNavigate();
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [spinner, setSpinner] = useState(false);
    const [table, setTable] = useState();
    const [bankListData, setBankListData] = useState();
    const [refrashPage, setRefrashPage] = useState(false);
    const [searchBy, setSearchBy] = useState('');
    const [searchByText, setSearchByText] = useState('');
    const [filterShow, setFilterShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [showReason, setShowReason] = useState(false);
    //const [authNum, setAuthNum] = useState('');
    const [showTimmer, setShowTimmer] = useState(false);
    const [blockOTP, setBlockOTP] = useState(true);
    const [counter, setCounter] = useState(60);
    const [request, setRequest] = useState({ 'reason': '' })
    const [requestError, setRequestError] = useState({});
    const [cancelId, setCancelId] = useState(null);
    const [currency,setCurrency] = useState(null);
    const [condData,setCondData] = useState([]);
    const [condNameData,setCondNameData] = useState([]);
    const list=[
        {'label':'Subscription Invoice','data':'subscription_invoice','type':'text'},
        // {'label':'Status','data':'status','type':'option','value':[{'key':'Active','value':'1'},{'key':'Inactive','value':'2'}]},
    ]
    let countData = 0;
    let AmountSymbol = '$';
    var globalCount = 60;
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/user/botsubscriptions');
        setCollapseId(3);
        (async () => {
            // try {
            //setSpinner(true);
            let r = await getReq('botsubscriptions', sessionStorage.getItem('token'))
            console.log(r)
            if (r.status == "success" || r.status == true) {
                setCurrency(r.currency);
                setBankListData(r.data);
                setTable(r.data.data);
                //setSpinner(false);
            }
            if (r.status == false && r.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if (r.errors) {
                //setSpinner(false);
                let err = Object.values(r.errors);
                err.map((data) => notifyError(data[0]));
            }
            // }
            // catch (err) {
            //     if (err?.response?.status === 401 || err?.response?.status === 404) {
            //         sessionStorage.clear();
            //         window.history.replaceState(null, null, "/");
            //         navigate('/', { replace: true });
            //     }
            //     //setSpinner(false);
            //     notifyError("Request failed login again");
            // }
        })()
    }, [refrashPage]);
    async function searchTable(e) {
        try {
            setSpinner(true);
            let r = await getTable(`botsubscriptions?page=${e.target.value}`, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                setBankListData(r.data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if (r.status == false && r.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if (r.errors) {
                setSpinner(false);
                let err = Object.values(r.errors);
                err.map((data) => notifyError(data[0]));
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    async function nextTable(url) {
        try {
            if (url !== null) {
                setSpinner(true);
                let r = await getFilterTable(url, sessionStorage.getItem('token'));
                if (r.status === "success" || r.status === true) {
                    setBankListData(r.data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    err.map((data) => notifyError(data[0]));
                }
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    function filterFunData(r){
        setCurrency(r.currency);
        setBankListData(r.data);
        setTable(r.data.data);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
        {filterShow&&<Filter props={{setFilterShow,url:`${process.env.REACT_APP_BASE_URL}/botsubscriptions?export=true`,list,filterFunData}}/>}
        {/* <div className="card mb-4">
                <div className="card-header fw-bold small">Instructions For Cancellation</div>
                <div className="card-body">
                    <h4 className='mb-4'>In case of Cancellation,</h4>
                    <ul className='list_ul'>
                        <li> 15% of the investment will be deducted within 6 months of investment.</li>
                        <li> 10% of the investment will be deducted within 25 months of investment.</li>
                    </ul>
                </div>
               
            </div> */}

            <div className="d-flex align-items-center mb-md-3 mb-2 justify-content-between">
                <h1 className="page-header mb-0">Bot Subscription History</h1>
                <span className="btn btn-outline-light btn-sm" onClick={()=>setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span>
            </div>

            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {table&&<div className="card">
                <div className="card-header fw-bold small">Bot Subscription History</div>
                <div className="card-body">
                    <div className="input-group mb-3">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div>
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                  
                        <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Subscription Invoice</strong>
                                    </th>
                                    <th>
                                        <strong>Subscription Price</strong>
                                    </th>
                                    <th>
                                        <strong>Bot Recharge Amount</strong>
                                    </th>
                                    <th>
                                        <strong>End Date</strong>
                                    </th>
                                </tr>
                            </thead>
                            {table && <tbody>
                                {
                                    table.map((data, idx) => {
                                        countData += 1;
                                        return (<tr key={idx}>
                                            <td>{data.invoice_id}</td>
                                            <td style={{textWrap: 'nowrap'}}>{process.env.REACT_APP_Amount_Symbol||'$'}{parseFloat(data.price||0)}</td>
                                            {/* <td>{data.type?.replace('_',' ')}</td> */}
                                            <td style={{textWrap: 'nowrap'}}>{process.env.REACT_APP_Amount_Symbol||'$'}{parseFloat(data.amount)}</td>
                                            {/* <td style={{textWrap: 'nowrap'}}>{process.env.REACT_APP_Amount_Symbol||'$'}{parseFloat(data.amount_paid)}</td> */}
                                            {/* <td>{moment(data.created_at).format('DD/MM/YYYY')}</td> */}
                                            <td>{moment(data.end_date).format('DD/MM/YYYY')}</td>
                                            {/* <td>
                                                {data.status==1 && <span className="badge light btn btn-success">Active</span>}
                                                {data.status==2 && <span className="badge light btn btn-danger">Cancelled</span>}
                                                {data.status==3 && <span className="badge light btn btn-warning">Cancellation Requested</span>}
                                            </td> */}
                                            {/* <td>
                                                <span style={{cursor:'pointer'}} className='badge light btn btn-info' onClick={()=>OpenImg(data,currency)}>Print</span>
                                            </td> */}
                                        </tr>)
                                    })
                                }
                            </tbody>}
                            {countData == 0 && <span style={{ display: 'table-caption',textAlign:'center'}}>No Data For Action</span>}
                        </Table>
                        </div>
                  
                    {table &&
                        <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                            <p className="h6">
                                Showing <span className="font-medium">{bankListData.from||'-'}</span> to <span className="font-medium">{bankListData.to||'-'}</span> of{' '}
                                <span className="font-medium">{bankListData.last_page}</span> results
                            </p>
                            <div className="d-flex">

                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(bankListData.prev_page_url)}>
                                            <a className="page-link" aria-label="Previous">
                                                <span className="btn-link cursor-pointer">&laquo;</span>
                                                
                                            </a>
                                        </li>
                                        <li className="page-item" style={{ cursor: 'pointer' }}>
                                            <a className="page-link">{bankListData.current_page}</a>
                                        </li>
                                        <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(bankListData.next_page_url)}>
                                            <a className="page-link" aria-label="Next">
                                                <span className="btn-link">&raquo;</span>
                                                
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>}
                </div>
               
            </div>}
        </Frame>
    )
}