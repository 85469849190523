import React from 'react'
import { IMAGES } from '../constant/theme'
import './PageNotFound.css';

export default function PageNotFound() {
    return (
        <div className="page_404">
            <div className="container">
                <div className="row">	
                    <div className="col-sm-12">
                        <div className="text-center">
                            <div className="four_zero_four_bg">
                                <img src={IMAGES.NotFound} alt=""/>
                            </div>
                            <div className="contant_box_404">
                                <h1 className="text-center" style={{marginTop:'5%'}}>404</h1>
                                <h3 className="h2">
                                    Look like you're lost
                                </h3>
                                
                                <p>the page you are looking for not avaible!</p>
                                
                                <a href="\user\Dashboard" className="link_404">Go to Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
