import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment/moment';
import { Button } from 'react-bootstrap';
import { getReq } from '../requests/request';
import { notifySuccess } from '../parts/Toast';

export default function DashboardPopUp({ props }) {
    const { data } = props;
    const [showNews, setShowNews] = useState(true);
    async function readPopUp(id){
        let r = await getReq(`news/${id}/read`,sessionStorage.getItem('token'));
        if(r.status){
            setShowNews(false);
        }
    }
    return (
        <>
            {showNews && <div className="modal dmodal" id="modal_show" style={{ opacity: 1, display: 'block' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">

                        <div className="modal-header close_right">

                            <button type="button" className="close" data-dismiss="modal" style={{ padding: '3px 9px', borderRadius: '100%' }} onClick={() => { setShowNews(false);readPopUp(data.id) }}>&times;</button>
                        </div>

                        <div className="modal-body modal_height">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3 className="modal-title">{data.title}</h3>
                                <span class="entry-time mi"><span class="sp">Published On - </span>{moment(data.published_at).format('DD-MM-YYYY')}</span>
                            </div>
                            <h5><strong>Dear Associates,</strong></h5>
                            <div className="card-post-image text-center">
                                <img src={`${process.env.REACT_APP_IMG_URL}/${data.image}`} style={{ width: '200px' }} />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: data.body }} style={{ margin: '10px' }} className="mb-4" />
                            <hr style={{ borderTopColor: 'rgba(32, 32, 33, 0.36)' }} />
                            <p className="text-right mb-0">Regards</p>
                            <p className="text-right" style={{ color: '#dc1320' }}>Team </p>
                        </div>


                    </div>
                </div>
            </div>}
        </>
    )
}
