import React, { useContext, useEffect, useRef, useState } from 'react';
import { SidebarClick } from '../routes/route';
import { useNavigate } from 'react-router-dom';
import { getReq } from '../requests/request';
import { notifyError, notifySuccess } from '../parts/Toast';
import moment from 'moment';
import Frame from "../components/frame";
import { RWebShare } from "react-web-share";
import './Dashboard.css'
import { userInfo } from '../App';
import DashboardPopUp from './DashboardPopUp';

export default function Dashboard() {
    const currency = "$ ";
    const { setNewsCount } = useContext(userInfo);
    const { setCurrentSidebarClick, setCollapseId, hideBinary } = useContext(SidebarClick);
    const [refrashPage, setRefrashPage] = useState(false);
    const [data, setData] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [newsData, setNewsData] = useState(null);
    const navigate = useNavigate();
    const copyRef = useRef();
    useEffect(() => {
        (async () => {
            let br = await getReq('pre-register');
            sessionStorage.setItem('Binary', br.binary);
        })()
    }, [refrashPage]);
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/dashboard');
        setCollapseId(0);
        (async () => {
            // try {
            setSpinner(true);
            let r = await getReq('dashboard', sessionStorage.getItem('token'))
            console.log(r);
            if (r.status === "success" || r.status === true) {
                setData(r.data);
                setSpinner(false);
            }
            if (r.errors) {
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            if (r.status == false && r.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Login Again')
            }
            if (r.status==false&&r.msg) {
                setSpinner(false);
                notifyError(r.msg);
            }
            // }
            // catch (err) {
            //     if (err.message) {
            //         sessionStorage.clear();
            //         window.history.replaceState(null, null, "/");
            //         navigate('/', { replace: true });
            //     }
            //     setSpinner(false);
            //     notifyError(`Request failed ${err.message}`);
            // }
            setSpinner(false);
        })()
    }, [refrashPage]);
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/dashboard');
        setCollapseId(0);
        (async () => {
            setSpinner(true);
            let r = await getReq('news', sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                setNewsCount(r.unread_count);
                setNewsData(r.data.reverse());
                setSpinner(false);
            }
            if (r.errors) {
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            if (r.status == false && r.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Login Again')
            }
            if (r.status == false && r.msg) {
                setSpinner(false);
                notifyError(r.msg);
            }
            setSpinner(false);
        })()
    }, []);
    async function copyLink(txt) {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(txt);
            notifySuccess('Referral link copy to clipboard successfully.')
        } else {
            const textarea = document.createElement('textarea');
            textarea.value = txt;
            textarea.style.position = 'absolute';
            textarea.style.left = '-9999px';
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            notifySuccess('Referral link copy to clipboard successfully.')
        }
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>

            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Dashboard</h1>
            </div>

           
            {data&&
            <div className="welocme_back_box">
                <div className="d-flex flex-column">
                    <h4 className="mb-1">Welcome back <span>{data.username}</span></h4>
                    <p className='mb-0'>{`Your last login was ${data.last_login_at}.`}</p>
                </div>
                <img className='welocme_usdt_icon' src="/assets/img/usdt-icon.png" alt="" />
            </div>
            }


            {!data&&
                <div class="loader card card-bg d-flex flex-column" style={{width:'unset'}}>
                    <div class="wrapper">
                        <div class="circle"></div>
                        {/* <div class="line-1"></div>
                        <div class="line-2"></div> */}
                        <div class="line-3"></div>
                        <div class="line-4"></div>
                    </div>
                </div>
            }

            {/* {!data&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>} */}
            
           {/* {!data&&<div className="row g-3 mt-2" style={{display:'flex',justifyContent:'center',gap:'40px'}}>
                {(()=>{
                    let arr = [];
                    for (let i = 0; i < 12; i++){
                        arr.push(<div class="loader col-xl-3 col-lg-6 card card-bg" key={i}>
                            <div class="wrapper">
                                <div class="circle"></div>
                                <div class="line-1"></div>
                                <div class="line-2"></div>
                                <div class="line-3"></div>
                                <div class="line-4"></div>
                            </div>
                        </div>)
                    }
                    return arr;
                })()}
            </div>}
            */}

            {data&&<div className="row g-3">
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Pay In Wallet
                            </div>
                            <h3 className="mb-0">{data.pay_in_wallet || 0.0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Pay Out Wallet
                            </div>
                            <h3 className="mb-0">{data.pay_out_wallet || 0.0}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Pending Deposit
                            </div>
                            <h3 className="mb-0">{data.pending_deposit || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Completed Deposit
                            </div>
                            <h3 className="mb-0">{data.completed_deposit || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Pending Withdraw
                            </div>
                            <h3 className="mb-0">{data.pending_withdraw || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Completed Withdraw
                            </div>
                            <h3 className="mb-0">{data.completed_withdraw || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Total Withdraw
                            </div>
                            <h3 className="mb-0">{data.total_withdraw || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Kyc Status
                            </div>
                            <h3 className="mb-0">{data.kyc_status?'Completed':'Incomplete'}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Daily Growth Bonus
                            </div>
                            <h3 className="mb-0">{data.roi_bonus || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Universal Leg Bonus
                            </div>
                            <h3 className="mb-0">{data.universal_leg_bonus || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Leadership Bonus
                            </div>
                            <h3 className="mb-0">{data.leadership_bonus || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Unilevel Bonus
                            </div>
                            <h3 className="mb-0">{data.unilevel_bonus || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">             
                            <div className={`d-flex fw-bold small ${data.current_leadership_rank!="N/A"?'mb-0':'mb-3'}`}>
                                Leadership Rank
                            </div>
                            <h3 className="mb-0">{data.current_leadership_rank||'-'}</h3>
                            {/* {data.current_leadership_rank!="N/A"&&<h6 className="mb-0">Percentage: {data.current_bot_rank_extra_percentage}</h6>} */}
                        </div>
                    </div>
                </div>
                {data.current_leadership_rank!="N/A"&&<div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-0">
                                Next Leadership Rank
                            </div>
                            <h3 className="mb-0">{data.next_leadership_rank}</h3>
                            <h6 className="mb-0">Criteria: {data.next_leadership_rank_criteria}</h6>
                        </div>
                        
                    </div>
                </div>}
                {(hideBinary == 1) && <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Total Team
                            </div>
                            <h3 className="mb-0">{data.total_team || 0}</h3>
                        </div>
                        
                    </div>
                </div>}
                {(hideBinary == 1) && <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Paid Team
                            </div>
                            <h3 className="mb-0">{data.paid_team || 0}</h3>
                        </div>
                        
                    </div>
                </div>}
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Business A
                            </div>
                            <h3 className="mb-0">{data.a_legBusiness || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Business B
                            </div>
                            <h3 className="mb-0">{data.b_legBusiness || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Total Referrals
                            </div>
                            <h3 className="mb-0">{data.total_referrals || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Total Paid Referrals
                            </div>
                            <h3 className="mb-0">{data.total_paid_referrals || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                My Sponsor
                            </div>
                            <h3 className="mb-0">{data.my_sponsor || '--'}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Total Subscription
                            </div>
                            <h3 className="mb-0">{data.total_subscription || 0}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Subscription Date
                            </div>
                            <h3 className="mb-0">{data.subscription_date}</h3>
                        </div>
                        
                    </div>
                </div>
            </div>}

            {/* {data&&(parseFloat(data.total_subscription.replace('$',''))>=3000)&&<div class="card mt-4">
                <div class="card-body">
                <div class="alert bg-info-subtle mb-0  d-flex fade show" role="alert">
                <i class="bi bi-trophy text-info fs-3 me-3 lh-1"></i>
                <div class="d-flex flex-column">
                <h6 class="mb-1 text-info">Congratulations</h6>
                <p class="mb-0">Congratulations, You're Now Eligible For IB.</p>
                
                </div>
                
            </div>
                </div>
            </div>} */}

            {data&&<div className="card mt-3">
                <div className="card-header fw-bold small">
                    Referral Link
                </div>
                <div className="card-body">
                    <h5 className="card-title mb-4" ref={copyRef}>
                        {`${window.location.origin}/register?u=${sessionStorage.getItem('username')}`}
                    </h5>

                    <div style={{ display: 'flex', gap: '20px' }}>
                        <button onClick={() => copyLink(`${window.location.origin}/register?u=${sessionStorage.getItem('username')}`)} className="btn btn-outline-theme"><i class="bi bi-copy"/>{' '}Copy Referral Link</button>
                        <RWebShare
                            data={{ url: `${window.location.origin}/register?u=${sessionStorage.getItem('username')}`, title: "CAFXWORLD" }}
                            sites={['facebook', 'twitter', 'whatsapp', 'telegram', 'linkedin', 'mail']}
                        >
                            <button className="btn btn-outline-theme"><i class="bi bi-share"/>{'  '}Share Referral Link</button>
                        </RWebShare>
                    </div>

                </div>
            </div>}
            {data&&<div className="card mt-3">
                <div className="card-header fw-bold small">
                    Support System
                </div>
                <div className="card-body">
                    <i className="bi bi-headset"></i>
                    {"  "}
					<span className="menu-text">Email : tradebiz@faxi.io</span>
                </div>
            </div>}
            {
                newsData && newsData.map((data, idx) => {
                    if (data.is_show) {
                        return <DashboardPopUp props={{ 'data': data }} key={idx} />
                    }
                })
            }
        </Frame>
    )
}
