import React, { Component,useContext } from 'react';
import Header from "./header";
import Footer from "./footer";
import Leftpart from "./leftpart";
import { SidebarClick } from '../routes/route';

// class Frame extends React.Component {
//     constructor(props) {
//       super(props);
//     }
//     render() {
//         const { withHeader, withFooter, withLeftpart } = this.props;
//       return (
//         <div className="page-wrapper">
//            <div className="main-container">

//              {withLeftpart && <Leftpart></Leftpart> } 

//              <div className="app-container">
          
//                 {withHeader && <Header></Header> } 
              
                
//                 <div className="app-body">
//                   {this.props.children}
//                 </div>
                
//                 {withFooter &&  <Footer></Footer>}

//             </div>
//           </div> 
//         </div>
//       );
//     }
//   }

function Frame(props){
  const {pinSidebar} = useContext(SidebarClick);
  return (
    <div className={`page-wrapper ${pinSidebar?'toggled':''}`}>
        <div className="main-container">

          {props.withLeftpart && <Leftpart></Leftpart> } 

          <div className="app-container">
      
            {props.withHeader && <Header></Header> } 
          
            
            <div className="app-body">
              {props.children}
            </div>
            
            {props.withFooter &&  <Footer></Footer>}

        </div>
      </div> 
    </div>
  );
}

export default Frame;