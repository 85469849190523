import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getReq } from '../../requests/request';
import { notifyError } from '../../parts/Toast';
import Tree from 'react-d3-tree';

import { MdPerson } from "react-icons/md";
import { SidebarClick } from '../../routes/route';
import { ThemeContext } from '../../context/ThemeContext';
import Frame from "../../components/frame";
export default function DirectTeam() {
    const navigate = useNavigate();
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const { background } = useContext(ThemeContext);
    const [spinner, setSpinner] = useState(false);
    const [username, setUsername] = useState(null);
    const currecy = '$';
    const [tree_data, setTree_data] = useState({
        name: '',
        attributes: {
            department: '',
        },
        more: '',
        children: []
    });
    useEffect(() => {
        setSpinner(true);
        setCurrentSidebarClick('/user/directTeam');
        setCollapseId(7);
        (async () => {
            try {
                let result = await getReq(`directTeam/${sessionStorage.getItem('username')}`, sessionStorage.getItem('token'));
                setTree_data({
                    name: result.username,
                    attributes: {},
                    more: result.data,
                    extra: {
                        sponsor_total_team: result.sponsor_total_team,
                        sponsor_paid_team: result.sponsor_paid_team
                    },
                    children: result.data.map((value) => {
                        return {
                            name: value.username,
                            attributes: {},
                            more: value,
                            children: [],
                        }
                    }),
                });
                if (result.status == false && result.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
            }
            catch (err) {
                notifyError(err.message);
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
            }
        })();
        setSpinner(false);
    }, []);
    const getLastChildArray = (node, targetName) => {
        if (!node) {
            return null;
        }
        if (node.more && node.more.username === targetName) {
            return node.children;
        }
        for (let c of node.children) {
            const Result = getLastChildArray(c, targetName);
            if (Result) {
                return Result;
            }
        }
        return null;
    }
    const addDataToLastChild = async (node) => {
        if (node.data.name !== 'vacant' && node.data.children.length <= 0) {
            setSpinner(true);
            const lastChildArray = getLastChildArray(tree_data, node.data.more.username);
            let cdata = await getReq(`directTeam/${node.data.more.username}`, sessionStorage.getItem('token'));
            cdata.data && lastChildArray.push(...cdata.data.map((value) => {
                return {
                    name: value.username,
                    attributes: {},
                    more: value,
                    children: []
                }
            }))
            setTree_data({ ...tree_data });
            setSpinner(false);
        }
        else {
            setSpinner(false);
        }
    };
    const renderRectSvgNode = ({ nodeDatum, onNodeClick }) => (
        <g onClick={onNodeClick} onMouseOver={() => setUsername(nodeDatum.name)} onMouseLeave={() => setUsername(null)}>
            <circle r="25" fill="#6cfff475" stroke="#000" />
            <text fill={background.value == 'light' ? "#000" : "white"}  transform="translate(30, 5)">
                {nodeDatum.name}
            </text>
            <g transform="translate(-25, -25)">
                <MdPerson style={{ fontSize: 50 }} />
            </g>
            {nodeDatum.name !== 'vacant' && username === nodeDatum.name && <foreignObject {...{ width: 250, height: 300, x: 30, y: -30 }}>
                <div style={{ border: "1px solid #ccc", borderRadius: '8px', padding: '10px', color:"#000", backgroundColor: "#f69220" }}>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <strong><span style={{ textAlign: "center" }}>Username :</span></strong>
                        <span style={{ textAlign: "center" }}>{nodeDatum.name}</span>
                    </div>
                    {nodeDatum.name == nodeDatum.more[0]?.sponsor
                        ? <div style={{ display: 'flex', gap: '0.2rem',textWrap:'nowrap'}}>
                            <strong><span style={{ textAlign: "center" }}>Subscription Amount :</span></strong>
                            <span style={{ textAlign: "center" }}>{process.env.REACT_APP_Amount_Symbol + parseFloat(nodeDatum.more[0]?.sponsor_total_subscription||0) || process.env.REACT_APP_Amount_Symbol + '0'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' ,textWrap:'nowrap'}}>
                            <strong><span style={{ textAlign: "center" }}>Subscription Amount :</span></strong>
                            <span style={{ textAlign: "center" }}>{process.env.REACT_APP_Amount_Symbol + parseFloat(nodeDatum.more?.total_subscription||0) || process.env.REACT_APP_Amount_Symbol + '0'}</span>
                        </div>
                    }
                    {nodeDatum.name == nodeDatum.more[0]?.sponsor
                        ? <div style={{ display: 'flex', gap: '0.2rem' ,textWrap:'nowrap'}}>
                            <strong><span style={{ textAlign: "center" }}>Business Amount :</span></strong>
                            <span style={{ textAlign: "center" }}>{process.env.REACT_APP_Amount_Symbol + parseFloat(nodeDatum.more[0]?.sponsor_total_team_business||0) || process.env.REACT_APP_Amount_Symbol + '0'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' ,textWrap:'nowrap'}}>
                            <strong><span style={{ textAlign: "center" }}>Business Amount :</span></strong>
                            <span style={{ textAlign: "center" }}>{process.env.REACT_APP_Amount_Symbol + parseFloat(nodeDatum.more?.total_team_business||0) || process.env.REACT_APP_Amount_Symbol + '0'}</span>
                        </div>
                    }
                    {/* {nodeDatum.name == nodeDatum.more[0]?.sponsor
                        ? <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Global Pool :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more[0]?.sponsor_pool || 'NA'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Global Pool :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.pool || 'NA'}</span>
                        </div>
                    } */}
                    {/* {nodeDatum.name == nodeDatum.more[0]?.sponsor
                        ? <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Rank :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more[0]?.sponsor_rank || 'NA'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Rank :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.rank || 'NA'}</span>
                        </div>
                    } */}
                    {nodeDatum.name == nodeDatum.more[0]?.sponsor && nodeDatum.extra
                        ? <div style={{ display: 'flex', gap: '0.2rem' ,textWrap:'nowrap'}}>
                            <strong><span style={{ textAlign: "center" }}>Total Team :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.extra.sponsor_total_team || '0'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' ,textWrap:'nowrap'}}>
                            <strong><span style={{ textAlign: "center" }}>Total Team :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.total_team || '0'}</span>
                        </div>
                    }
                    {nodeDatum.name == nodeDatum.more[0]?.sponsor && nodeDatum.extra
                        ? <div style={{ display: 'flex', gap: '0.2rem' ,textWrap:'nowrap'}}>
                            <strong><span style={{ textAlign: "center" }}>Paid Team :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.extra.sponsor_paid_team || '0'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' ,textWrap:'nowrap'}}>
                            <strong><span style={{ textAlign: "center" }}>Paid Team :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.paid_team || '0'}</span>
                        </div>
                    }
                    {nodeDatum.name != nodeDatum.more[0]?.sponsor && <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <strong><span style={{ textAlign: "center" }}>Sponsor :</span></strong>
                        <span style={{ textAlign: "center" }}>{nodeDatum.more?.sponsor || '-'}</span>
                    </div>}
                </div>
            </foreignObject>}
        </g>
    );
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Referral Team</h1>
            </div>
            {spinner&&<div style={{backgroundColor: "rgba(0, 0, 0, 0.7)",position: "absolute",top: "0",bottom: "0",left: "0",right: "0",zIndex: "1000"}}>
                <div class="spinner-grow text-primary" role="status" style={{display: "block",position: "fixed",zIndex: 1030,top: "50%",left: "50%",}}>
                    <span class="sr-only"></span>
                </div>
            </div>}
            <div className="card">
                <div className="card-body">
                    <div style={{ height: '100vh' }}>
                        <Tree data={tree_data}
                            orientation='vertical'
                            collapsible={true}
                            separation={{ nonSiblings: 1, siblings: 3 }}
                            onNodeClick={(node) => addDataToLastChild(node)}
                            translate={{ x: 500, y: 100 }}
                            renderCustomNodeElement={renderRectSvgNode}
                            pathFunc={"step"}
                        />
                    </div>
                </div>
               
            </div>

        </Frame>
    );
}

