import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function KYCModel({props}) {
    const {setShowModel,UploadKYCData,loader,setLoader} = props;
    return (
        <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(0,0,0,0.5)'}}>
            <Modal.Dialog>
                <Modal.Header closeButton onClick={()=>setShowModel(false)}>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>are you sure ? you want to upload your doc.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setShowModel(false)}>Close</Button>
                    <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={()=>{UploadKYCData();setLoader(true)}} disabled={loader}>
                        Go To Next
                        {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    )
}
