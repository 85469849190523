import React,{useEffect, useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getReq, register } from "../requests/request";
import { notifyError, notifySuccess } from "../parts/Toast";
import Spinner from 'react-bootstrap/Spinner';
import { IMAGES } from "../constant/theme";

function Register() {
	let {search} = useLocation();
	let query = new URLSearchParams(search);
	const [preData,setPreData] = useState(null);
	const [userCount,setUserCount] = useState(null);
	const [binary,setBinary] = useState("");
	const [checkUsernameLoader,setCheckUsernameLoader] = useState(false);
	const [showUsernameMsg,setShowUsernameMsg] = useState('');
	const [checkSponsorLoader,setCheckSponsorLoader] = useState(false);
	const [showSponsorMsg,setShowSponsoreMsg] = useState('');
	const [showSponsorMsgInfo,setShowSponsoreMsgInfo] = useState('');
	const [showSPassword,setShowSPassword] = useState('');
	const [countryCode,setCountryCode] = useState(null);
	const [loader,setLoader] = useState(false);
	const [spinner,setSpinner] = useState(false);
    const [data, setData] = useState({
        name: '',
        username: '',
        email: '',
		phone:'',
		// pan_no:'',
		country:'',
        password: '',
		spassword:'',
        sponsor: query.get("u")||'',
        position:''
    });
    let errorsObj = { 
        name: "",
        username: "",
		phone:"",
		// pan_no:"",
        email: "",
		country:"",
        password: "",
		spassword:"",
        sponsor: "",
        position:""
    };
    const [errors, setErrors] = useState(errorsObj);
	const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

	useEffect(()=>{
		if(query.get("u")){
			checkSponsor();
		}
        (async()=>{
			setSpinner(true);
			let r = await getReq('country');
			if(r.status==="success" || r.status===true){
				setCountryCode(r.data);
            }
			let br = await getReq('pre-register');
			if(br.status==="success" || br.status===true){
				setPreData(br)
				setBinary(br.binary);
				setUserCount(br.user_count);
            }
			setSpinner(false);
        })()
    },[])

    async function RegisterBtn(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (data.email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
		if (data.phone === '') {
            errorObj.phone = 'Phone is Required';
            error = true;
        }
		// if (data.pan_no === '') {
        //     errorObj.pan_no = 'Pan Number is Required';
        //     error = true;
        // }
        if (data.password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
		if(preData.email_required==0){
			if (data.spassword === '') {
				errorObj.spassword = 'Security Pin is Required';
				error = true;
			}
		}
        if (data.name === '') {
            errorObj.name = 'Name is Required';
            error = true;
        }
		if (data.country === '') {
            errorObj.country = 'Country is Required';
            error = true;
        }
        if (data.username === '') {
            errorObj.username = 'Username is Required';
            error = true;
        }
        if (data.sponsor === '' && (userCount!=0)) {
            errorObj.sponsor = 'Sponsor is Required';
            error = true;
        }
        if (data.position === '' && (binary=="1"||binary==1) && (userCount!=0)) {
            errorObj.position = 'Position is Required';
			error = true;
        }
		console.log(errorObj,error)
        setErrors(errorObj);
        if(error==false){
			setLoader(true);
			let result = await register(data);
			if((result.message||result.msg)&&result.status==false){
				notifyError(result.message||result.msg);
			}
			if(result.errors){
				let err = Object.values(result.errors);
				//err.map((data)=>notifyError(data[0]));
				notifyError(err[0][0]);
			}
			if(result.status==true){
				sessionStorage.setItem('username',data.username);
				if(preData.email_required==0){
					notifySuccess(result.message||result.msg);
					navigate('/login');
				}else{
					notifySuccess(result.message||result.msg);
					navigate('/verifyEmail');
				}
			}
			setLoader(false);
		}
    }
	async function setCountryCodeFun(e){
		//let r = await getReq(`country?id=${e}`);
		setData({...data,'country':e.target.value,'phone':e.target[e.target.selectedIndex].getAttribute('data-code')});
	}
	async function checkUsername(){
		setShowUsernameMsg('');
		setCheckUsernameLoader(true);
		let r = await getReq(`check/${data.username}/username`);
		setCheckUsernameLoader(false);
		setShowUsernameMsg(r.data);
	}
	async function checkSponsor(){
		setShowSponsoreMsg('');
		setCheckSponsorLoader(true);
		let r = await getReq(`check/${data.sponsor}/sponsor`);
		setCheckSponsorLoader(false);
		setShowSponsoreMsgInfo(r.info);
		setShowSponsoreMsg(r.data);
	}
	function handleName(e){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z\s]/ig, '');
		setData({...data,'name':value});
	};
	function handleUsername(e){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9]/ig, '');
		setData({...data,'username':value});
	};
	function handleEmail(e){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9@.]/ig, '');
		setData({...data,'email':value});
	};
	function handleSponsor(e){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9]/ig, '');
		setData({...data,'sponsor':value});
	};
	function handleNum(e){
		let value = e.target.value
  		value = value.replace(/[^0-9+]/ig, '');
		setData({...data,'phone':value});
	};
	// function handlePan(e){
	// 	let value = e.target.value
  	// 	value = value.replace(/[^A-Za-z0-9]/ig, '');
	// 	setData({...data,'pan_no':value.toUpperCase()});
	// }
	return (
		<>

		<div className="login">
				
				
			<div className='container'>
				<div className='login_logo'>
					<a href="/" class="auth-logo mb-4">
						<img src="/assets/img/logo-dark.png" alt="" />
					</a>
				</div>
				<div className="login-content register_box">
					
					<form  onSubmit={RegisterBtn}>
						<h4 class="mb-4 text-white">Create an account</h4>
						<div className="row">
							<div className="col-md-6 col-lg-6">
								<div className="mb-3">
									<label className="mb-1">Name <span className="text-danger">*</span></label>
									<input name="dzName2" value={data.name} required="" onChange={(e)=>handleName(e)} className="form-control form-control-lg bg-inverse bg-opacity-5" placeholder="Name" type="text" />
									{(data.name==''&&errors.name) && <div className="text-danger fs-12">{errors.name}</div>}
								</div>
							</div>
							<div className="col-md-6 col-lg-6">
								<div className="mb-3">
									<label className="mb-1">Username <span className="text-danger">*</span></label>
									<input name="dzName2" value={data.username} required="" onChange={(e)=>handleUsername(e)} className="form-control form-control-lg bg-inverse bg-opacity-5" placeholder="Username" type="text" onBlur={checkUsername}/>
									{checkUsernameLoader&&<div>
										<div className="spinner-border spinner-border-sm" role="status" style={{marginTop:'8px',marginLeft:'8px'}}>
											<span className="visually-hidden">Loading...</span>
										</div>
										<span> Checking...</span>
									</div>}
									{showUsernameMsg=="Available"&&<div style={{marginTop:'4px'}}><img src={IMAGES.right} style={{width:'4%',height:'4%'}}/><span> Available</span></div>}
									{showUsernameMsg=="Not Available"&&<div style={{marginTop:'4px'}}><img src={IMAGES.wrong} style={{width:'4%',height:'4%'}}/><span> Not Available</span></div>}
									{(data.username==''&&errors.username) && <div className="text-danger fs-12">{errors.username}</div>}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 col-lg-6">
								<div className="mb-3">
									<label className="mb-1">Email <span className="text-danger">*</span></label>
									<input name="dzName2" value={data.email} required="" onChange={(e)=>handleEmail(e)} className="form-control form-control-lg bg-inverse bg-opacity-5" placeholder="Email" type="email" />
									{(data.email==''&&errors.email) && <div className="text-danger fs-12">{errors.email}</div>}
								</div>
							</div>
							<div className="col-md-6 col-lg-6">
								<div className="mb-3">
									<label className="mb-1">Country <span className="text-danger">*</span></label>
									<div className="form-group">
										<select name="select_box" className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.country} onChange={(e)=>setCountryCodeFun(e)}>
											<option value="">Select Country</option>
											{
												countryCode && countryCode.map((data,idx)=>
													<option value={data.id} data-code={data.calling_code} key={idx}>{data.name}</option>
												)
											}
										</select>
									</div>
									{(data.country==''&&errors.country)&&<span className="text-danger">{errors.country}</span>}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 col-lg-6">
								<div className="mb-3">
									<label className="mb-1">Phone <span className="text-danger">*</span></label>
									<input name="dzName2" value={data.phone} required="" onChange={(e)=>handleNum(e)} className="form-control form-control-lg bg-inverse bg-opacity-5" placeholder="Phone" type="text" />
									{(data.phone==''&&errors.phone) && <div className="text-danger fs-12">{errors.phone}</div>}
								</div>
							</div>
							<div className="col-md-6 col-lg-6">
								<label className="mb-1">Password <span className="text-danger">*</span></label>
								<div class="input-group">
								<input
									type={`${showPassword ? "text" : "password"}`}
									className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.password} onChange={(e) => setData({ ...data, 'password': e.target.value })} />
								<span
									className="btn btn-outline-secondary"
									onClick={() => setShowPassword(!showPassword)}>
									{showPassword === false ? (<i className="bi bi-eye" />) : (<i className="bi bi-eye-slash" />)}
								</span>
								</div>
								{(data.password == '' && errors.password) && <div className="text-danger fs-12">{errors.password}</div>}
							</div>
							{preData?.email_required==0&&<div className="col-md-6 col-lg-6">
								<label className="mb-1">Security Pin(4 Digit) <span className="text-danger">*</span></label>
								<div class="input-group">
								<input
									type={`${showSPassword ? "text" : "password"}`}
									className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.spassword} onChange={(e) =>{setData({ ...data, 'spassword': e.target.value.replace(/[0-9]{5}/ig, '')})}} />
								<span
									className="btn btn-outline-secondary"
									onClick={() => setShowSPassword(!showSPassword)}>
									{showSPassword === false ? (<i className="bi bi-eye" />) : (<i className="bi bi-eye-slash" />)}
								</span>
								</div>
								{(data.spassword == '' && errors.spassword) && <div className="text-danger fs-12">{errors.spassword}</div>}
							</div>}
							<div className="col-md-6 col-lg-6">
								{userCount!=0&&
								<div className="mb-3">
									<label className="mb-1">Sponsor <span className="text-danger">*</span></label>
									<input name="dzName2" value={data.sponsor} required="" onChange={(e)=>handleSponsor(e)} className="form-control form-control-lg bg-inverse bg-opacity-5" placeholder="Sponsor" type="text" disabled={query.get("u")} readOnly={query.get("u")} onBlur={checkSponsor}/>
									{checkSponsorLoader&&<div>
										<div className="spinner-border spinner-border-sm" role="status" style={{marginTop:'4px',marginLeft:'8px'}}>
											<span className="visually-hidden">Loading...</span>
										</div>
										<span> Checking...</span>
									</div>}
									{showSponsorMsg=="Available"&&<span style={{marginLeft:'5px'}}>{showSponsorMsgInfo}</span>}
									{showSponsorMsg=="Not Available"&&<div style={{marginTop:'4px'}}><img src={IMAGES.wrong} style={{width:'4%',height:'4%'}}/><span> Not Available</span></div>}
									{(data.sponsor==''&&errors.sponsor) && <div className="text-danger fs-12">{errors.sponsor}</div>}
								</div>}
							</div>
							<div className="col-md-6 col-lg-6">
							{(binary==1||binary=="1")&&userCount!=0&&
								<div className="mb-3">
									<label className="mb-1">Position <span className="text-danger">*</span></label>
									<select className="form-control form-control-lg bg-inverse bg-opacity-5" name='dzName2' value={data.position} onChange={(e)=>setData({...data,'position':e.target.value})} required>
										<option defaultValue={''}>Select Position</option>
										<option value={'R'}>Right</option>
										<option value={'L'}>Left</option>
									</select>
									{(data.position==''&&errors.position) && <div className="text-danger fs-12">{errors.position}</div>}
								</div>}
							</div>
						</div>
						<div className="d-grid mt-2 gap-3">
							<button type="submit" className="light_gradient_btn w_100">
							Sign Up{" "}
							{loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
								<span className="visually-hidden">Loading...</span>
							</div>}
							</button>
							<Link className="text-center" to={"/login"}>Already have an Account? Sign in</Link>
						</div>
						
					</form>
				</div>
			</div>
		</div>

		</>
	);
};
export default Register;

