import React, { useContext, useEffect, useState } from 'react';
import { Col, Card, Table, Spinner } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { getReq, getTable, postReq } from '../../requests/request';
import { SidebarClick } from '../../routes/route';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Frame from "../../components/frame";
import './PriceList.css';
import { IMAGES } from '../../constant/theme';

export default function PakageList() {
    const [classList, setClassList] = useState(['pl-container1', 'pl-container2', 'pl-container3'])
    let classListId = null;
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [refrashUsername,setRefrashUsername] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [table, setTable] = useState();
    const [pakageListData, setPakageListData] = useState();
    const [refrashPage, setRefrashPage] = useState(false);
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [showPakage, setShowPakage] = useState(false);
    const [investmentAmount, setInvestmentAmount] = useState('');
    const [investmentDate,setInvestmentDate]=useState('');
    const [loader, setLoader] = useState(false);
    const [pakageId, setPackageId] = useState();
    const [tempVariable, setTempVariable] = useState();
    const [maxPrice, setMaxPrice] = useState();
    const [minPrice, setMinPrice] = useState();
    const [activeCard, setActiveCard] = useState(null);
    const [error, setError] = useState('');
    const [filterShow, setFilterShow] = useState(false);
    const [modalLoader, setModalLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [variablePackageAlert, setVariablePackageAlert] = useState(false);
    const [fixedPackageAlert, setFixedPackageAlert] = useState(false);
    const [buyVeriablePackageData, setBuyVeriablePackageData] = useState({});
    const [buyFixedPackageData, setBuyFixedPackageData] = useState({});
    const [moreDetails, setMoreDetails] = useState({});
    const [showSponsorMsg,setShowSponsoreMsg] = useState('');
    const [showFixPakage,setShowFixPakage] = useState(false);
    const [showPackBtn,setShowPackBtn] = useState(false);
    const [icoloader,setIcoloader] = useState(false);
    const [progressNow,setProgressNow] = useState(false);
    const [buyNow,setBuyNow] = useState(true);
    let AmountSymbol = '$';
    useEffect(() => {
        setRefrashUsername(false);
        (async () => {
            let r = await getReq('profile', sessionStorage.getItem('token'))
            if (r.status == "success" || r.status == true) {
                setUsername(r.data.username);
            }
            // let rr = await getReq('check-subscription', sessionStorage.getItem('token'))
            // if (rr.status == "success" || rr.status == true) {
            //     setBuyNow(rr.data<=0?true:false);
            // }
        })()
    }, [refrashUsername]);
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/user/pakageList');
        setCollapseId(3);
        (async () => {
            try {
                setSpinner(true);
                let r = await getReq('packages', sessionStorage.getItem('token'));
                if (r.status === "success" || r.status === true) {
                    setPakageListData(r.data);
                    setTable(r.data.data);
                    setActiveCard(parseInt(r.data.data.length / 2));
                    setMoreDetails(r.more_details);
                    setSpinner(false);
                }
                if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if (r.msg && r.status==false) {
                    setSpinner(false);
                    notifyError(r.msg);
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    // err.map((data) => notifyError(data[0]));
                    notifyError(err[0][0])
                }
            }
            catch (err) {
                if (err.response.status === 401 || err.response.status === 404) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                setSpinner(false);
                notifyError("Request failed login again");
            }
        })()
    }, [refrashPage]);
    async function pakageBuyFixed() {
        setProgressNow(true);
        setFixedPackageAlert(false);
        let temp = { "package_id": buyFixedPackageData.id, 'subscription_type': 'fixed', 'investment_amount': buyFixedPackageData.price, 'username': username}
        setLoader(true);
        let r = await postReq('subscription/store', temp, sessionStorage.getItem('token'))
        if (r.status === "success" || r.status === true) {
            setLoader(false);
            //notifySuccess(r.message||r.msg);
            setSuccess(true);
            setShowFixPakage(false);
            let time = setTimeout(function() {
                clearTimeout(time);
                setSuccess(false);
                navigate('/user/subscriptionHistory');
            }, 6000);
        }
        if (r.msg && r.status==false) {
            setLoader(false);
            setShowFixPakage(false);
            setSpinner(false);
            notifyError(r.msg);
        }
        if (r.status == false && r.msg == 401) {
            setLoader(false);
            setShowFixPakage(false);
            sessionStorage.clear();
            window.history.replaceState(null, null, "/");
            navigate('/', { replace: true });
            notifyError('Authentication Failed. Please Login Again....');
        }
        if (r.errors) {
            setLoader(false);
            setSpinner(false);
            setShowFixPakage(false);
            let err = Object.values(r.errors);
            // err.map((data) => notifyError(data[0]));
            notifyError(err[0][0]);
        }
        setProgressNow(false);
    }
    async function buyPakageFun() {
        setProgressNow(true);
        let temp = { "package_id": buyVeriablePackageData.id, 'subscription_type': 'variable', 'investment_amount': investmentAmount, 'username': username}
        if ((parseFloat(investmentAmount) >= parseFloat(buyVeriablePackageData.minimum_price) && (buyVeriablePackageData.maximum_price!='Above'?parseFloat(investmentAmount) <= parseFloat(buyVeriablePackageData.maximum_price):true))&&username) {
            setError("");
            setModalLoader(true);
            let r = await postReq('subscription/store', temp, sessionStorage.getItem('token'));
            if (r.status === "success" || r.status === true) {
                setLoader(false);
                setModalLoader(false);
                setInvestmentAmount('');
                //notifySuccess(r.message||r.msg);
                setShowPakage(false);
                setSuccess(true);
                let time = setTimeout(function() {
                    clearTimeout(time);
                    setSuccess(false);
                    navigate('/user/subscriptionHistory');
                }, 6000);
            }
            if (r.status == false && r.msg == 401) {
                setLoader(false);
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if (r.status === "success" || r.status === false) {
                setLoader(false);
                setModalLoader(false);
                notifyError(r.message || r.msg);
            }
            if (r.errors) {
                setLoader(false);
                setSpinner(false);
                let err = Object.values(r.errors);
                err.map((data) => notifyError(data[0]));
            }
            setModalLoader(false);
        }
        else {
            setError("Amount Should be b/w range!!!")
        }
        setProgressNow(false);
    }
    function showVBuyAlert(id, minimum_price, maximum_price) {
        let temp = { ...buyVeriablePackageData }
        temp['id'] = id;
        temp['minimum_price'] = minimum_price;
        //temp['maximum_price'] = maximum_price;
        temp['maximum_price'] = maximum_price<=10000000?maximum_price:'Above';
        setBuyVeriablePackageData(temp);
        setVariablePackageAlert(true);
    }
    function showFBuyAlert(id, price) {
        let temp = { ...buyFixedPackageData }
        temp['id'] = id;
        temp['price'] = price;
        setBuyFixedPackageData(temp);
        setFixedPackageAlert(true);
    }
    function handleStr(e){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9]/ig, '');
        setUsername(value);
	};
    function handleNum(e){
		let value = e.target.value
  		value = value.replace(/[^0-9]/ig, '');
        setInvestmentAmount(value);
	};
    async function checkSponsor(){
        //await syncwallet();
		setShowSponsoreMsg('');
		let r = await getReq(`check/${username}/sponsor`);
        if(r.status){
            setShowPackBtn(true);
        }
		setShowSponsoreMsg(r);
	}
    // async function syncwallet(){
    //     setIcoloader(true);
    //     let r = await getReq('get/ico',sessionStorage.getItem('token'));
    //     if(r.code==200){
    //         //setShowPack(true);
    //         setShowPackBtn(true);
    //         notifySuccess('Sync Successfully');
    //     }
    //     else{
    //         //setShowPack(false);
    //         notifyError('!!! Please Try Afrer Sometimes');
    //     }
    //     setIcoloader(false);
    // }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-2 justify-content-between">
                <h1 className="page-header mb-0">Purchase Package</h1>
                {/* {!showPack&&table&&<button type="button" onClick={()=>syncwallet()} className="btn btn-primary" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px",height:'3em' }}>
                    ICO Purchase
                    {icoloader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>}
                </button>} */}
            </div>
            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}
            {table&&<Col lg={12}>
                <Card>
                    <div className='card-body'>
                        {/* {!showPack&&<div style={{position:'absolute',width:'100%',height:'100%',zIndex:9999,background:'rgba(0,0,0,0.7)'}}>
                            <div style={{display:'flex',height:'100%',width:'100%',justifyContent:'center',alignItems:'center',fontSize:'large',fontWeight: 600}}>
                                To Buy Package, Click ICO Purchase.
                            </div>
                        </div>} */}
                        <div style={{ textAlign: 'center' }}>
                            <h2>Find a plan that's right for you.</h2>
                            <h3>Every plan comes with best price.</h3>
                        </div>
                        <div className="pl-outer-container">
                            <span className="light" style={{ position: 'relative', fontSize: 'x-large', fontFamily: 'monospace', color: '#b371dd', marginBottom: '2.2rem' }}>Pricing Table</span>
                            <div className="pl-card-body">
                                {table && table.map((data, idx) => {
                                    classListId += 1;
                                    if (classListId > 2) {
                                        classListId = 0;
                                    }
                                    return (<div key={idx} className={`pl-container m-2 ${classList[classListId]} ${idx === activeCard ? 'pl-active' : ''}`} onClick={() => setActiveCard(idx)}>
                                        <div className="pl-inner_container">
                                            <div className="pl-title">{data.name}</div>
                                            <div className="pl-main_number">{data.type=='fixed' ? <div><small>$</small> {parseFloat(data.price)}</div> : <div><small>$</small> {parseFloat(data.minimum_price)} - {data.maximum_price<=10000000?parseFloat(data.maximum_price):'Above'}</div>}</div>
                                            {/* <div className="pl-container_text">
                                                <div className="pl-container_text1" style={{ fontFamily: 'system-ui', fontWeight: '600' }}>Duration(Months) : {data.duration}</div>
                                                {data.is_roi == 1 && <div className="pl-container_text1" style={{ fontFamily: 'system-ui', fontWeight: '600' }}>Daily Growth Bonus: {parseFloat(data.roi_percentage||0)}%</div>}
                                                {data.is_binary == 1 && <div className="pl-container_text1" style={{ fontFamily: 'system-ui', fontWeight: '600' }}>Binary: {parseFloat(data.binary_percentage||0)}%</div>}
                                            </div> */}
                                        </div>
                                        {data.type === 'variable' && <a onClick={() => { buyNow&&showVBuyAlert(data.id, data.minimum_price, data.maximum_price);buyNow&&setPackageId(data.id) }}>
                                            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem',cursor:buyNow?"pointer":"not-allowed" }} className='packageBuy'>
                                                Buy
                                                {data.id === pakageId && loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>}
                                            </span></a>}
                                        {data.type === 'fixed' && <a onClick={() => {buyNow&&showFBuyAlert(data.id, data.price);buyNow&&setPackageId(data.id) }}>
                                            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem',cursor:buyNow?"pointer":"not-allowed" }} className='packageBuy'>
                                                Buy
                                                {data.id === pakageId && loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>}
                                            </span></a>}
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                   
                </Card>
            </Col>}


            {showPakage &&
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header>
                        <Modal.Title>Buy Pakage</Modal.Title>
                        <button type="button" class="btn-close" aria-label="Close" onClick={() =>{setShowPakage(false);setInvestmentAmount("");setError("");setRefrashUsername(true);}} />
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Username</label>
                                <input type="text" className="form-control" value={username} onChange={(e) => handleStr(e)} onBlur={()=>checkSponsor()} autoFocus/>
                            </div>
                            {showSponsorMsg.data=="Available"&&<span>Username: <span style={{color:'#7fffd4'}}>{showSponsorMsg.info}</span></span>}
						    {showSponsorMsg.data=="Not Available"&&<div><img src={IMAGES.wrong} style={{width:'4%',height:'4%'}}/><span> Not Available</span></div>}
                        </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Investment Amount</label>
                                <input type="text" className="form-control" value={investmentAmount} onChange={(e) => handleNum(e)} />
                            </div>
                            <div>Investment Amount Should be Between {process.env.REACT_APP_Amount_Symbol}{parseFloat(buyVeriablePackageData.minimum_price)} and {buyVeriablePackageData.maximum_price!='Above'&&process.env.REACT_APP_Amount_Symbol}{buyVeriablePackageData.maximum_price!='Above'?parseFloat(buyVeriablePackageData.maximum_price):'Above'}</div>
                            <div style={{ color: 'red' }}>{error}</div>
                        </div>
                        {/* <div>
                            <label className="mb-1">Date</label>
                            <input type="datetime-local" className="form-control" value={investmentDate} onChange={(e) => setInvestmentDate(e.target.value)} />
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() =>{setShowPakage(false);setInvestmentAmount("");setError("");setRefrashUsername(true);}}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={()=>(showSponsorMsg.info&&!progressNow)&&buyPakageFun()} disabled={progressNow||!showSponsorMsg.info}>
                            Buy
                            {modalLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {showFixPakage &&
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header>
                        <Modal.Title>Buy Pakage</Modal.Title>
                        <button type="button" class="btn-close" aria-label="Close" onClick={() =>{setShowFixPakage(false);setInvestmentAmount("");setError("");setRefrashUsername(true);}} />
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Username</label>
                                <input type="text" className="form-control" value={username} onChange={(e) => handleStr(e)} onBlur={()=>checkSponsor()} autoFocus/>
                            </div>
                            {showSponsorMsg.data=="Available"&&<span>Username: <span style={{color:'#7fffd4'}}>{showSponsorMsg.info}</span></span>}
						    {showSponsorMsg.data=="Not Available"&&<div><img src={IMAGES.wrong} style={{width:'4%',height:'4%'}}/><span> Not Available</span></div>}
                        </div>
                        {/* <div>
                            <label className="mb-1">Date</label>
                            <input type="datetime-local" className="form-control" value={investmentDate} onChange={(e) => setInvestmentDate(e.target.value)} />
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() =>{setShowFixPakage(false);setInvestmentAmount("");setError("");setRefrashUsername(true);}}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={()=>(showSponsorMsg.info&&!progressNow)&&pakageBuyFixed()} disabled={progressNow||!showSponsorMsg.info}>
                            Buy
                            {modalLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                success && <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={IMAGES.buy} style={{ width: '50%', height: '50%' }} />
                        <span style={{ fontFamily: 'inherit' }}>
                            Package Subscribed Successfully
                        </span>
                    </Modal.Body>
                </Modal>
            }
            {
                variablePackageAlert && <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header>
                        <Modal.Title>Warning</Modal.Title>
                        <button type="button" class="btn-close" aria-label="Close" onClick={() => setVariablePackageAlert(false)} />
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span>Your {moreDetails[0].title} : {moreDetails[0].value}</span>
                        <span style={{ fontFamily: 'inherit' }}>
                            Are You Sure To Buy This Package ?
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setVariablePackageAlert(false)}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={() => { setShowPakage(true); setVariablePackageAlert(false);checkSponsor() }}>
                            Continue
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                fixedPackageAlert && <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header>
                        <Modal.Title>Warning</Modal.Title>
                        <button type="button" class="btn-close" aria-label="Close" onClick={() => setFixedPackageAlert(false)} />
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ fontFamily: 'inherit' }}>
                            Are You Sure To Buy This Package ?
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setFixedPackageAlert(false)}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={() => { setShowFixPakage(true); setFixedPackageAlert(false) }}>
                            Continue
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </Frame>
    )
}
