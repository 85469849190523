import React, { useContext, useEffect, useState } from 'react';
import { Col, Card, Table, Spinner } from "react-bootstrap";
import { getFilterTable, getReq, getTable, postReq, sendOtp } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import Frame from "../../components/frame";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OtpInput from 'react-otp-input';
import CheckFormError from '../../parts/FormError';
import OpenImg from '../../parts/OpenImageTab';
import moment from 'moment/moment';

export default function SubscriptionHistory() {
    const navigate = useNavigate();
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [spinner, setSpinner] = useState(false);
    const [table, setTable] = useState();
    const [bankListData, setBankListData] = useState();
    const [refrashPage, setRefrashPage] = useState(false);
    const [searchBy, setSearchBy] = useState('');
    const [searchByText, setSearchByText] = useState('');
    const [filterShow, setFilterShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [showReason, setShowReason] = useState(false);
    //const [authNum, setAuthNum] = useState('');
    const [showTimmer, setShowTimmer] = useState(false);
    const [blockOTP, setBlockOTP] = useState(true);
    const [counter, setCounter] = useState(60);
    const [request, setRequest] = useState({ 'reason': '' })
    const [requestError, setRequestError] = useState({});
    const [cancelId, setCancelId] = useState(null);
    const [currency,setCurrency] = useState(null);
    const [condData,setCondData] = useState([]);
    const [condNameData,setCondNameData] = useState([]);
    const [renewAmt,setRenewAmt] = useState({'username':'','subscription_id':''});
    const [showWarning,setShowWarning] = useState(false);
    let countData = 0;
    let AmountSymbol = '$';
    var globalCount = 60;
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/user/pakageList');
        setCollapseId(3);
        (async () => {
            try {
                //setSpinner(true);
                let r = await getReq('subscriptions', sessionStorage.getItem('token'))
                if (r.status == "success" || r.status == true) {
                    setCurrency(r.currency);
                    setCondData(r.cancel_data);
                    setCondNameData(r.cancel_data.cancel_package);
                    setBankListData(r.data);
                    setTable(r.data.data);
                    //setSpinner(false);
                }
                if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if (r.errors) {
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    err.map((data) => notifyError(data[0]));
                }
            }
            catch (err) {
                if (err.response.status === 401 || err.response.status === 404) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                //setSpinner(false);
                notifyError("Request failed login again");
            }
        })()
    }, [refrashPage]);
    async function searchTable(e) {
        try {
            setSpinner(true);
            let r = await getTable(`subscriptions?page=${e.target.value}`, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                setBankListData(r.data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if (r.status == false && r.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if (r.errors) {
                setSpinner(false);
                let err = Object.values(r.errors);
                err.map((data) => notifyError(data[0]));
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    async function nextTable(url) {
        try {
            if (url !== null) {
                setSpinner(true);
                let r = await getFilterTable(url, sessionStorage.getItem('token'));
                if (r.status === "success" || r.status === true) {
                    setBankListData(r.data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    err.map((data) => notifyError(data[0]));
                }
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    async function requestOTP() {
        setBlockOTP(false);
        let data = { "username": sessionStorage.getItem('username'), 'from': '' };
        let result = await sendOtp(data);
        if (result.message && result.status) {
            notifySuccess(result.message);
            var OtpInterval = setInterval(function () {
                setCounter((prev) => prev - 1);
                globalCount -= 1;
                if (globalCount <= 0) {
                    setCounter(60);
                    globalCount = 60;
                    setShowTimmer(false);
                    setBlockOTP(true);
                    clearInterval(OtpInterval);
                }
            }, 1000);
            setShowTimmer(true);
        }
        else {
            notifyError(result.message);
            setBlockOTP(true);
        }
    }
    // function cancel(id) {
    //     setCancelId(id);
    //     setShowReason(true);
    //     if (sessionStorage.getItem('auth_type') == 'otp') {
    //         requestOTP();
    //     }
    // }
    function setData(key, value) {
        let temp = { ...request };
        temp[key] = value;
        setRequest(temp);
    }
    async function sendData() {
        let { errList, countError } = CheckFormError(request)
        setRequestError(errList);
        if (countError <= 0) {
            setLoader(true);
            try {
                let r = await postReq(`subscription/${cancelId}/cancel`, request, sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    notifySuccess(r.message || r.msg);
                    setShowReason(false);
                    setRefrashPage(true);
                }
                if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if (r.msg && r.status === false) {
                    //setShowAuth(false);
                    notifyError(r.msg);
                    //setTransferData({'payee':'','transfer_amount':'','wallet':''})
                }
                if (r.errors) {
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
                //}
            }
            catch (err) {
                notifyError("Request failed login again");
            }
            setLoader(false);
        }
    }
    function handleNum(key,e){
        let value = e.target.value
  		value = value.replace(/[^0-9]/ig, '');
        let temp = {...request}
        temp[key] = value;
		setRequest(temp);
    }
    function monthDiff(d1){
        d1 = new Date(d1);
        let d2 = new Date();
        d1 =  moment(d1);
        d2 = moment(d2);
        const monthDiff = d1.diff(d2,'month')
        return monthDiff;
    }
    function RenewAmtTemp(username,subs_id){
        setRenewAmt({
            'username':username,
            'subscription_id':subs_id
        })
        setShowWarning(true);
    }
    async function RenewAmtFun(){
        setLoader(true);
        try {
            let r = await postReq(`botsubscription/store`, renewAmt, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                notifySuccess(r.message || r.msg);
                setRefrashPage(true);
            }
            if (r.status == false && r.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if (r.msg && r.status === false) {
                //setShowAuth(false);
                notifyError(r.msg);
                //setTransferData({'payee':'','transfer_amount':'','wallet':''})
            }
            if (r.errors) {
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            //}
        }
        catch (err) {
            notifyError("Request failed login again");
        }
        setShowWarning(false);
        setLoader(false);
        navigate('/user/botsubscriptionHistory');
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>

        {/* <div className="card mb-4">
            <div className="card-header fw-bold small">Instructions For Cancellation</div>
                <div className="card-body">
                    <h4 className='mb-4'>In case of Cancellation,</h4>
                    <ul className='list_ul'>
                        <li> 15% of the investment will be deducted within 6 months of investment.</li>
                        <li> 10% of the investment will be deducted within 25 months of investment.</li>
                    </ul>
                </div>
               
            </div> */}

            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Subscription History</h1>
            </div>

            {condData&&condData.fees&&<div className='card mb-3'>
                <div className="card-header fw-bold small">Instructions For Cancellation</div>
                <div className="card-body">
                    <h4>In case of Cancellation of {condNameData} Package,</h4>
                    <ul>
                        {condData.fees.map((data,id)=><li key={id}>{data}</li>)}
                    </ul>
                </div>
                <div className="card-arrow">
                    <div className="card-arrow-top-left"></div>
                    <div className="card-arrow-top-right"></div>
                    <div className="card-arrow-bottom-left"></div>
                    <div className="card-arrow-bottom-right"></div>
                </div>
            </div>}

            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {table&&<div className="card">
                <div className="card-header fw-bold small">Subscription History</div>
                <div className="card-body">
                    <div className="input-group mb-3">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div>
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                  
                        <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Username</strong>
                                    </th>
                                    <th>
                                        <strong>Subscription Done By</strong>
                                    </th>
                                    <th>
                                        <strong>Invoice ID</strong>
                                    </th>
                                    <th>
                                        <strong>Package Name</strong>
                                    </th>
                                    {/* <th>
                                        <strong>Type</strong>
                                    </th> */}
                                    <th>
                                        <strong>Price</strong>
                                    </th>
                                    <th>
                                        <strong>Amount Paid</strong>
                                    </th>
                                    <th>
                                        <strong>Activation Date</strong>
                                    </th>
                                    {/* <th>
                                        <strong>Expired Date</strong>
                                    </th> */}
                                    <th>
                                        <strong>Bot Recharge Amount ($)</strong>
                                    </th>
                                    <th>
                                        <strong>Bot Validity (days)</strong>
                                    </th>
                                    <th>
                                        <strong>Action</strong>
                                    </th>
                                    <th>
                                        <strong>Status</strong>
                                    </th>
                                    <th>
                                        <strong>Invoice</strong>
                                    </th>
                                </tr>
                            </thead>
                            {table && <tbody>
                                {
                                    table.map((data, idx) => {
                                        countData += 1;
                                        return (<tr key={idx}>
                                            <td>{data.username}</td>
                                            <td>{<td>{data.subs_admin?data.subs_admin:data.s_done_by}</td>}</td>
                                            <td>{data.invoice_id}</td>
                                            <td>{data.package}</td>
                                            {/* <td>{data.type?.replace('_',' ')}</td> */}
                                            <td style={{textWrap: 'nowrap'}}>{process.env.REACT_APP_Amount_Symbol||'$'}{parseFloat(data.price)}</td>
                                            <td style={{textWrap: 'nowrap'}}>{process.env.REACT_APP_Amount_Symbol||'$'}{parseFloat(data.amount_paid)}</td>
                                            <td>{moment(data.created_at).format('DD/MM/YYYY')}</td>
                                            {/* <td>{moment(data.end_date).format('DD/MM/YYYY')}</td> */}
                                            <td>{data.status == 1 && data.is_bot_recharge?parseFloat(data.bot_recharge_amount):'-'}</td>
                                            <td>{data.status == 1 && data.is_bot_recharge?data.bot_recharge_validity:'-'}</td>
                                            <td>
                                                {(data.status == 1 && data.is_bot_recharge==1)?
                                                    <button className="badge light btn btn-primary" style={{ cursor: 'pointer',height:'25px'}} onClick={() =>RenewAmtTemp(data.username,data.id)}>Bot Subscription</button>
                                                    :<button className="badge light btn btn-primary" style={{ cursor: 'not-allowed',height:'25px'}}>Bot Subscription</button>
                                                }
                                            </td>
                                            <td>
                                                {data.status==1 && <span  className="badge light btn btn-success">Active</span>}
                                                {data.status==2 && <span  className="badge light btn btn-danger">Cancelled</span>}
                                                {data.status==3 && <span  className="badge light btn btn-warning">Cancellation Requested</span>}
                                            </td>
                                            {/* <td>
                                                {(data.status == 1 && data.is_cancellation)?
                                                    <button className="badge light btn btn-primary" style={{ cursor: 'pointer'}} onClick={() => cancel(data.id)}>Unsubscribe</button>
                                                    :<button className="badge light btn btn-primary" style={{ cursor: 'not-allowed' }}>Unsubscribe</button>
                                                }
                                            </td> */}
                                            <td>
                                                <span style={{cursor:'pointer'}} className='badge light btn btn-info' onClick={()=>OpenImg(data,currency)}>Print</span>
                                            </td>
                                        </tr>)
                                    })
                                }
                            </tbody>}
                            {countData == 0 && <span style={{ display: 'table-caption',textAlign:'center'}}>No Data For Action</span>}
                        </Table>
                        </div>
                  
                    {table &&
                        <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                            <p className="h6">
                                Showing <span className="font-medium">{bankListData.from||'-'}</span> to <span className="font-medium">{bankListData.to||'-'}</span> of{' '}
                                <span className="font-medium">{bankListData.last_page}</span> results
                            </p>
                            <div className="d-flex">

                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(bankListData.prev_page_url)}>
                                            <a className="page-link" aria-label="Previous">
                                                <span className="btn-link cursor-pointer">&laquo;</span>
                                                
                                            </a>
                                        </li>
                                        <li className="page-item" style={{ cursor: 'pointer' }}>
                                            <a className="page-link">{bankListData.current_page}</a>
                                        </li>
                                        <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(bankListData.next_page_url)}>
                                            <a className="page-link" aria-label="Next">
                                                <span className="btn-link">&raquo;</span>
                                                
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>}
                </div>
               
            </div>}
            {showReason &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(0,0,0,0.5)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header>
                        <Modal.Title style={{ fontFamily: 'system-ui', fontWeight: 'bold' }}>Verify Your Account</Modal.Title>
                        <button type="button" class="btn-close" aria-label="Close" onClick={() => { setShowReason(false); setRequest({}) }} />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-2">
                            <label className="mb-1">Reason</label>
                            <input type="text" className="form-control" value={request.reason} onChange={(e) => setData('reason', e.target.value)} />
                            {(request.reason == '' && requestError.reason) && <span className="required">This Field Is Required.</span>}
                        </div>
                        <div className="mb-2">
                            <label className="mb-1">{sessionStorage.getItem('auth_type')=='mpin'?"SECURITY PIN":sessionStorage.getItem('auth_type').toUpperCase()}</label>
                            <input type="text" className="form-control" value={request[sessionStorage.getItem('auth_type')]} onChange={(e) => handleNum(sessionStorage.getItem('auth_type'), e)} />
                            {(request[sessionStorage.getItem('auth_type')] == '' && requestError[sessionStorage.getItem('auth_type')]) && <span className="required">This Field Is Required.</span>}
                        </div>
                        {sessionStorage.getItem('auth_type') == 'otp' && !showTimmer && <span style={{ color: 'var(--primary)', cursor: 'pointer', margin: '8px' }} onClick={() => blockOTP ? requestOTP() : null}>Request OTP</span>}
                        {sessionStorage.getItem('auth_type') == 'otp' && showTimmer && <span style={{ color: 'var(--primary)', margin: '8px' }}>Resend request after {counter} sec</span>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn btn-primary' style={{ backgroundColor: 'var(--bs-btn-bg)', display: 'flex', gap: '0.5rem', alignItems: 'center' }} onClick={(e) => sendData()} disabled={loader}>
                            Request
                            {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
            {showWarning &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(0,0,0,0.5)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header>
                        <Modal.Title style={{ fontFamily: 'system-ui', fontWeight: 'bold' }}>Warning</Modal.Title>
                        <button type="button" class="btn-close" aria-label="Close" onClick={() =>setShowWarning(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <label className="mb-1">Are you sure? you want to buy subscription </label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn btn-primary' style={{ backgroundColor: 'var(--bs-btn-bg)', display: 'flex', gap: '0.5rem', alignItems: 'center' }} onClick={() => RenewAmtFun()} disabled={loader}>
                            Buy
                            {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
        </Frame>
    )
}