import React from 'react';
import {Card} from "react-bootstrap";

export default function MoreDetails({props}) {
    return (

        <div>

        <div className="row">
        { props.map((val,idx)=>
            <div className="col-xl-6 col-lg-6" key={idx}>
                <div className="card card-bg mb-3">
                    <div className="card-body">
                        <div className="d-flex fw-bold small mb-3">
                        {val.title}
                        </div>
                        <h3 className="mb-0">{parseFloat(val.value)||val.value}</h3>
                    </div>
                   
                </div>
            </div>
             )}
        </div>
       

        </div>
    )
}
