import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <div className="app-footer">
          <span className="small">© Faxi 2024</span>
        </div>
      );
    }
  }

  export default Footer;