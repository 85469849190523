import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SidebarClick } from '../routes/route';
import { notifyError } from '../parts/Toast';
import { getFilterTable, getReq, getTable } from '../requests/request';
import {Col,Card,Table, Spinner} from "react-bootstrap";
import { motion } from "framer-motion";
import Frame from "../components/frame";

export default function RankDetails() {
    const pSymbol = '$';
    const navigate = useNavigate();
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [rank,setRank] = useState();
    const [table,setTable] = useState();
    const [refrashPage,setRefrashPage] = useState(false);
    let durationanim = 0.15;
    let currency = '$';
    let countData=0;
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/user/rankIncomes');
        setCollapseId(6);
        (async()=>{
            try{
                setSpinner(true);
                let r = await getReq('rank/incomes',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setRank(r.data)
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('authentication failed. Login Again');
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                // if(err.response.status===401||err.response.status===404){
                //     sessionStorage.clear();
                //     window.history.replaceState(null, null, "/");
				//     navigate('/',{ replace: true });
                // }
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                setSpinner(false);
                notifyError("Request failed login again");
            }
        })()
    },[refrashPage]);
    async function nextTable(url) {
        try {
            if (url !== null) {
                setSpinner(true);
                let r = await getFilterTable(url, sessionStorage.getItem('token'));
                if (r.status === "success" || r.status === true) {
                    setRank(r.data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    err.map((data) => notifyError(data[0]));
                }
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
             <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Rank Details</h1>
            </div>

            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {table&&<div className="card">
            
            <div className="card-body">
            <Table responsive style={{textAlign:'center'}}>
                <thead>
                    <tr>
                        <th>
                            <strong>Name</strong>
                        </th>
                        <th>
                            <strong>Total Months</strong>
                        </th>
                        <th>
                            <strong>Paid Months</strong>
                        </th>
                        <th>
                            <strong>Bouns({currency})</strong>
                        </th>
                        <th>
                            <strong>Rewards</strong>
                        </th>
                        <th>
                            <strong>Paid Status</strong>
                        </th>
                    </tr>
                </thead>
                {table&&<tbody>
                    {
                        table.map((data,idx)=>{
                        countData +=1;
                        return(<motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                            <td>{data.name}</td>
                            <td>{data.total_months}</td>
                            <td>{data.paid_months}</td>
                            <td>{parseFloat(data.per_month_amount)}</td>
                            <td>{data.rewards||'-'}</td>
                            <td>{data.is_paid?'Paid':'Unpaid'}</td>
                        </motion.tr>)})
                    }
                </tbody>}
                {countData==0&&<span style={{display:'table-caption'}}>No Data For Action</span>}
            </Table>
            {table&&
            <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                <p className="h6">
                    Showing <span className="font-medium">{rank.from||'-'}</span> to <span className="font-medium">{rank.to||'-'}</span> of{' '}
                    <span className="font-medium">{rank.last_page}</span> results
                </p>
                <div className="d-flex">
                    <nav aria-label="Page navigation">
                        <ul className="pagination">
                            <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(rank.prev_page_url)}>
                                <a className="page-link" aria-label="Previous">
                                    <span className="btn-link cursor-pointer">&laquo;</span>
                                </a>
                            </li>
                            <li className="page-item" style={{cursor:'pointer'}}>
                                <a className="page-link">{rank.current_page}</a>
                            </li>
                            <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(rank.next_page_url)}>
                                <a className="page-link" aria-label="Next">
                                    <span className="btn-link">&raquo;</span>
                                    
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>}
        </div>
            
        </div>}

        </Frame>
    )
}
