import React from 'react'
export default function KYCDoc({props}) {
    const {kycPage,setKycPage} = props;
    return (
        <div>
            <h3 className='mb-4'>Instructions</h3>
            <ul className='list_ul'>
                <li> Ensure you have the correct document based on the KYC requirements. This could be a government-issued ID.</li>
                <li> Take clear and high-quality photos. Ensure that all details on the document are visible, including text, photo, and any security features.</li>
                <li> If there are specific resolution requirements, make sure your photo meets them. This is important for clarity and legibility.</li>
                <li> Check if there are any restrictions on file formats (JPEG, JPG, PNG, etc.) and comply accordingly.</li>
                <li> Follow the submission instructions provided by the platform or service you are submitting the KYC documents to.</li>
            </ul>
            <div style={{display:"flex",justifyContent:"right",alignItems: "center",marginRight:"1rem"}}>
                <button type="submit" className="btn btn-primary" onClick={()=>setKycPage(kycPage+1)}>Next</button>
            </div>
        </div>
    )
}
