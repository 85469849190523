import React, { useState } from 'react';
import { postForm } from '../requests/request';
import { notifyError,notifySuccess } from './Toast';
import KYCModel from './KYCModel';
import { useNavigate } from 'react-router-dom';

export default function KYCPhoto({props}) {
    const navigate = useNavigate();
    const {kycPage,setKycPage,setRefrashPage} = props;
    const [loader,setLoader] = useState(false);
    const [showModel,setShowModel] = useState(false);
    const [KYCData,setKYCData] = useState({'front':'','document_type':'photo'});
    async function UploadKYCData(){
        setLoader(true);
        let result = await postForm('kyc-doc-upload',KYCData,sessionStorage.getItem('token'));
        if(result.message && result.status){
            setLoader(false);
            notifySuccess(result.message);
            setKYCData({'front':'','document_type':''});
            setKycPage(kycPage+1);
            setRefrashPage(true);
        }
        if(result.status==false && result.msg==401){
            sessionStorage.clear();
            window.history.replaceState(null, null, "/");
            navigate('/',{ replace: true });
            notifyError('Authentication Failed. Please Login Again....');
        }
        if(result.message && result.status==false){
            setLoader(false);
            notifyError(result.message);
            setShowModel(true);
        }
        if(result.errors){
            if(typeof result.errors == 'string'){
                setLoader(false);
                notifyError(result.errors);
            }
            else{
                setLoader(false);
                let err = Object.values(result.errors);
                err.map((data)=>notifyError(data[0]));
            }
            setShowModel(true);
        }
        setRefrashPage(true);
    }
    return (
        <div>
            <h3>Upload Photo</h3>
            <div>
                <div className="mb-4">
                    <label className="mb-1">Photo <span className="required">*</span></label>
                    <input type="file" accept="image/png,image/jpeg" className="update-flie form-control" onChange={(e)=>setKYCData({...KYCData,'front':e.target.files[0]})}/>
                    <span>Image size sholud not be gretaer than 2MB</span>
                </div>
            </div>
            <div style={{display:"flex",justifyContent:"right",alignItems: "center",marginRight:"1rem"}}>
                <button type="submit" className="btn btn-primary" onClick={()=>setShowModel(true)}>Finish</button>
            </div>
            {showModel&&<KYCModel props={{setShowModel,UploadKYCData,loader,setLoader}}/>}
        </div>
        
    )
}
