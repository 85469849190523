import React, { useContext, useEffect, useState } from "react";
//import {Row,Col,Card,Table,Badge,Dropdown,ProgressBar} from "react-bootstrap";
//import {Col,Card,Table,Badge} from "react-bootstrap";
import {Col,Card,Table, Spinner} from "react-bootstrap";
import { SidebarClick } from "../routes/route";
import { getFilterTable, getReq, getTable, postForm, postTable } from "../requests/request";
import { notifyError } from "../parts/Toast";
import { SVGICON } from "../constant/theme";
import { Link, useNavigate } from "react-router-dom";
import KYCIdentity from "../parts/KYCIdentity";
import KYCAddress from "../parts/KYCAddress";
import KYCPhoto from "../parts/KYCPhoto";
import KYCDoc from "../parts/KYCDoc";
//import openImg from "../parts/OpenImageTab";
import Frame from "../components/frame";
import moment from "moment/moment";
import Modal from 'react-bootstrap/Modal';

export default function Kyc() {
    const navigate = useNavigate();
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [kycData,setKycData] = useState();
    const [table,setTable] = useState();
    const [refrashPage,setRefrashPage] = useState(false);
    const [kycPage,setKycPage] = useState(0);
    const [showKYC,setShowKYC] = useState(false);
    const [kycStatus,setKycStatus] = useState('');
    const [showFrontImg,setShowFrontImg] = useState(false);
    const [showBackImg,setShowBackImg] = useState(false);
    const [kycImgId,setKycImgId] = useState(null);
    const uploadDoc = {'identity':0,'address':0,'photo':0};
    useEffect(()=>{
        setShowKYC(false);
        setRefrashPage(false);
        setCurrentSidebarClick('/user/kyc');
        setCollapseId(2);
        (async()=>{
            try{
                //setSpinner(true);
                let r = await getReq('kyc-list',sessionStorage.getItem('token'));
                if(r.status=="success" || r.status==true){
                    setKycStatus(r.kyc_status);
                    setKycData(r.data);
                    setTable(r.data.data);
                    //setSpinner(false);
                    if(r.data.data.length==0){
                        setKycPage(0);
                        setShowKYC(true);
                    }
                    else if(r.data.data.length>0 && r.data.data.length<3){
                        //let dt = ["address","identity","photo"]
                        let document_type = []
                        for(let d of r.data.data){
                            document_type.push(d.document_type);
                        }
                        if(document_type.includes('identity')==false){
                            setKycPage(1);
                            setShowKYC(true);
                        }
                        else if(document_type.includes('address')==false){
                            setKycPage(2);
                            setShowKYC(true);
                        }
                        else if(document_type.includes('photo')==false){
                            setKycPage(3);
                            setShowKYC(true);
                        }
                    }
                    else{
                        setShowKYC(false);
                    }
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if(r.errors){
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                if(err){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
				    navigate('/',{ replace: true });
                }
                //setSpinner(false);
                notifyError("Request failed login again");
            }
        })()
    },[refrashPage]);
    
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getFilterTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setKycData(r.data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    err.map((data)=>notifyError(data[0]));
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`kyc-list?page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setKycData(r.data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.status==false && r.msg==401){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed login again");
        }
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
        <div className="d-flex align-items-center mb-md-3 mb-2">
            <h1 className="page-header mb-0">KYC</h1>
        </div>
        {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
            <span class="sr-only"></span>
        </div>}
        {table&&kycStatus==0&&table.length<3&&<div className="alert alert-danger" role="alert">
            Please Complete Your KYC !
        </div>}
        {table&&kycStatus==0&&table.length==3&&<div className="alert alert-info" role="alert">
            Please Wait For KYC Verification.
        </div>}
        {table&&kycStatus==1&&table.length==3&&<div className="alert alert-primary" role="alert">
            KYC Verified successfully.
        </div>}
        {showKYC&&<div className="card mb-4">
            <div className="card-header fw-bold small">Upload Documents</div>
            <div className="card-body">
                {kycPage===0 && <KYCDoc props={{kycPage,setKycPage,setRefrashPage}}/>}
                {kycPage===1 && <KYCIdentity props={{kycPage,setKycPage,setRefrashPage}}/>}
                {kycPage===2 && <KYCAddress props={{kycPage,setKycPage,setRefrashPage}}/>}
                {kycPage===3 && <KYCPhoto props={{kycPage,setKycPage,setRefrashPage}}/>}
            </div>
            
        </div>}

        {table&&<div className="card">
            <div className="card-header fw-bold small">KYC List</div>
            <div className="card-body">
            <div className="input-group mb-3">
                <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
            </div>
            {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
            </div>}
               
                    <Table responsive style={{textAlign:'center'}}>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>Document Name</strong>
                                </th>
                                <th>
                                    <strong>Document No</strong>
                                </th>
                                <th>
                                    <strong>Document Type</strong>
                                </th>
                                <th>
                                    <strong>Front Image</strong>
                                </th>
                                <th>
                                    <strong>Back Image</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                                <th>
                                    <strong>Remarks</strong>
                                </th>
                                <th>
                                    <strong>Submitted Date</strong>
                                </th>
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>
                                <tr key={idx}>
                                    <td style={{textTransform:'capitalize'}}>{data.document_name?.replaceAll('_',' ')||'-'}</td>
                                    <td>{data.document_number||'-'}</td>
                                    <td style={{textTransform:'capitalize'}}>{data.document_type?.replaceAll('_',' ')||'-'}</td>
                                    <td style={{textAlign: "-webkit-center"}}>
                                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
                                            {/* <div style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",width:"10rem"}}>
                                                {data.front||'-'}
                                            </div> */}
                                            {/* {data.front&&<button onClick={()=>openImg(`${process.env.REACT_APP_IMG_URL}/${data.front}`)} className="btn btn-sm btn-primary">View</button>} */}
                                            {data.front&&<button onClick={()=>{setShowFrontImg(true);setKycImgId(idx)}} className="btn btn-sm btn-primary">View</button>}
                                            {data.front&&showFrontImg&&kycImgId==idx&&
                                            <div className="modal show" style={{ display: 'block',top:'2rem',position:"fixed",background:'rgba(0,0,0,0.5)'}}>
                                                <Modal.Dialog>
                                                    <Modal.Header>
                                                        <Modal.Title>Image</Modal.Title>
                                                        <button type="button" class="btn-close" aria-label="Close" onClick={()=>setShowFrontImg(false)}></button>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <img width={'100%'} src={`${process.env.REACT_APP_IMG_URL}/${data.front}`} alt="Image"/>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Link to={`${process.env.REACT_APP_IMG_URL}/${data.front}`} target="_blank" className="btn btn-primary" download style={{textDecoration:'none'}}>Download</Link>
                                                    </Modal.Footer>
                                                </Modal.Dialog>
                                            </div>}
                                        </div>
                                    </td>
                                    <td style={{textAlign: "-webkit-center"}}>
                                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
                                            {/* <div style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",width:"10rem"}}>
                                                View
                                            </div> */}
                                            {/* {data.back&&<button onClick={()=>openImg(`${process.env.REACT_APP_IMG_URL}/${data.back}`)} className="btn btn-sm btn-primary">View</button>} */}
                                            {data.back&&<button onClick={()=>{setShowBackImg(true);setKycImgId(idx)}} className="btn btn-sm btn-primary">View</button>}
                                            {data.back&&showBackImg&&kycImgId==idx&&
                                            <div className="modal show" style={{ display: 'block',top:'2rem',position:"fixed",background:'rgba(0,0,0,0.5)'}}>
                                                <Modal.Dialog>
                                                    <Modal.Header>
                                                        <Modal.Title>Image</Modal.Title>
                                                        <button type="button" class="btn-close" aria-label="Close" onClick={()=>setShowBackImg(false)}></button>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <img width={'100%'} src={`${process.env.REACT_APP_IMG_URL}/${data.back}`} alt="Image"/>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Link to={`${process.env.REACT_APP_IMG_URL}/${data.back}`} target="_blank" className="btn btn-primary" download style={{textDecoration:'none'}}>Download</Link>
                                                    </Modal.Footer>
                                                </Modal.Dialog>
                                            </div>}
                                        </div>
                                    </td>
                                    <td>
                                        {data.status===0 && <span  className="badge light btn btn-warning">Pending</span>}
                                        {data.status===1 && <span  className="badge light btn btn-success">Approved</span>}
                                        {data.status===2 && <span  className="badge light btn btn-danger">Rejected</span>}
                                    </td>
                                    <td>{data.remarks||"-"}</td>
                                    <td>{moment(data.created_at).format('DD-MM-YYYY HH:mm')}</td>
                                </tr>)
                            }
                        </tbody>}
                        {table&&table.length == 0 && <span style={{ display: 'table-caption',textAlign:'center'}}>No Data For Action</span>}
                    </Table>
              
                    {table&&
                    <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                        <p className="h6">
                            Showing <span className="font-medium">{kycData.from||'-'}</span> to <span className="font-medium">{kycData.to||'-'}</span> of{' '}
                            <span className="font-medium">{kycData.last_page}</span> results
                        </p>
                        <div className="">
                            
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(kycData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link cursor-pointer">&laquo;</span>
                                            
                                        </a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}}>
                                        <a className="page-link">{kycData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(kycData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
            </div>
            
        </div>}

        


        </Frame>
    )
}
