import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login, prelogin, sendOtp } from '../requests/request';
import { notifyError, notifySuccess } from '../parts/Toast';
import ForgetPassword from './ForgetPassword';
import { userInfo } from '../App';

function Login() {
	const { setUsername,setEmail } = useContext(userInfo);
	const navigate = useNavigate();
	const [blockInput, setBlockInput] = useState(false);
	const [data, setData] = useState({ username: '', password: '' });
	let errorsObj = { username: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [showPassword, setShowPassword] = useState(false);
	const [showForgetPassword, setShowForgetPassword] = useState(false);
	const [loader, setLoader] = useState(false);
	const [logType, setLogType] = useState('pre');
	const [typeData, setTypeData] = useState('');
	const [authType, setAuthType] = useState('');
	const [counter, setCounter] = useState(60);
	const [showTimmer, setShowTimmer] = useState(false);
	const [blockOtp, setBlockOtp] = useState(true);
	var globalCount = 60;

	function handleNum(e){
		let value = e.target.value
  		value = value.replace(/[^0-9]/ig, '');
		setTypeData(value);
	};

	async function onLogin(e) {
		e.preventDefault();
		setLoader(true);
		let error = false;
		const errorObj = { ...errorsObj };
		if (data.username == '') {
			errorObj.username = 'username is Required';
			error = true;
			setLoader(false);
		}
		if (data.password === '') {
			errorObj.password = 'Password is Required';
			error = true;
			setLoader(false);
		}
		setErrors(errorObj);
		if (error === false) {
			try {
				if (logType === 'pre') {
					setBlockInput(true);
					let result = await prelogin(data);
					if ((result.message || result.msg) && result.status == false) {
						setLoader(false);
						notifyError(result.message || result.msg);
						setBlockInput(false);
					}
					if(result.errors){
						let err = Object.values(result.errors);
						notifyError(err[0][0]);
						setBlockInput(false);
						setLoader(false);
					}
					if (result.status == false && result.otp_send == true) {
						sessionStorage.setItem('username', data.username);
						navigate('/verifyEmail');
						setLoader(false);
					}
					if (result.status == true) {
						sessionStorage.setItem('token', result.token);
						sessionStorage.setItem('auth_type', result.auth_type);
						setAuthType(result.auth_type);
						if (result.auth_type == 'otp') {
							sendOTP(null);
						}
						setLoader(false);
						setLogType('now');
					}
				}
				else {
					let temp = { ...data }
					temp[authType] = typeData;
					let result = await login(temp, sessionStorage.getItem('token'));
					if (result.errors) {
						let err = Object.values(result.errors);
						notifyError(err[0][0]);
						//err.map((data)=>notifyError(data[0]));
						setLoader(false);
					}
					if (result.message || result.msg && result.status == false) {
						setLoader(false);
						notifyError(result.message || result.msg);
						setTypeData("");
					}
					if (result.status === false && result.otp_send == true) {
						sessionStorage.setItem('username', data.username);
						navigate('/verifyEmail');
						setLoader(false);
					}
					if (result.status === true) {
						sessionStorage.setItem('username', result.username);
						sessionStorage.setItem('status', result.status);
						sessionStorage.setItem('email', result.email);
						sessionStorage.setItem('token', result.token);
						setUsername(data.username);
						setEmail(result.email);
						setLoader(false);
						navigate('/user/dashboard', { replace: true });
					}
				}
				// let result = await login(data);
				// if (result.errors) {
				// 	let err = Object.values(result.errors);
				// 	notifyError(err[0][0]);
				// 	//err.map((data)=>notifyError(data[0]));
				// 	setLoader(false);
				// }
				// if (result.message || result.msg && result.status == false) {
				// 	setLoader(false);
				// 	notifyError(result.message || result.msg);
				// 	setTypeData("");
				// }
				// if(result.errors){
				// 	let err = Object.values(result.errors);
				// 	notifyError(err[0][0]);
				// }
				// if (result.status === false && result.otp_send == true) {
				// 	sessionStorage.setItem('username', data.username);
				// 	navigate('/verifyEmail');
				// 	setLoader(false);
				// }
				// if (result.status === true) {
				// 	sessionStorage.setItem('username', data.username);
				// 	sessionStorage.setItem('status', result.status);
				// 	sessionStorage.setItem('token', result.token);
				// 	sessionStorage.setItem('auth_type', result.auth_type);
				// 	setUsername(data.username);
				// 	setLoader(false);
				// 	navigate('/user/dashboard', { replace: true });
				// }
			}
			catch (err) {
				if (err.response.status === 401 || err.response.status === 404) {
					sessionStorage.clear();
					window.history.replaceState(null, null, "/");
					navigate('/', { replace: true });
				}
				setLoader(false);
				notifyError("Request failed login again");
			}
		}
	}
	async function sendOTP(type) {
		setBlockOtp(false);
		let result = await sendOtp({ 'username': data.username, 'from': type });
		if ((result.message || result.msg) && result.status) {
			notifySuccess(result.message || result.msg);
			var OtpInterval = setInterval(function () {
				setCounter((prev) => prev - 1);
				globalCount -= 1;
				if (globalCount <= 0) {
					setCounter(60);
					globalCount = 60;
					setShowTimmer(false);
					setBlockOtp(true);
					clearInterval(OtpInterval);
				}
			}, 1000);
			setShowTimmer(true);
		}
		if ((result.message || result.msg) && result.status == false) {
			notifyError(result.message || result.msg);
			setBlockOtp(true);
		}
		if (result.errors) {
			let err = Object.values(result.errors);
			//err.map((data)=>notifyError(data[0]));
			notifyError(err[0][0]);
			setBlockOtp(true);
		}
	}
	function changeShowForgetPassword() {
		setShowForgetPassword(!showForgetPassword);
	}
	return (
		<>


			<div className="login">
				
				<div className="container">
					
				<div className='login_logo'>
						<a href="/" class="auth-logo mb-4">
							<img src="/assets/img/logo-dark.png" alt="" />
						</a>
					</div>

				<div className="login-content">
					
					<form onSubmit={onLogin}>
					
						<h4 class="mb-4">Login to account</h4>
						<div className="mb-3">
							<label className="form-label">Username <span className="text-danger">*</span></label>
							<input type="text" className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.username} onChange={(e) => setData({ ...data, 'username': e.target.value })} disabled={blockInput} />
							{(data.username == '' && errors.username) && <div className="text-danger fs-12">{errors.username}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">Password <span className="text-danger">*</span></label>
							<div class="input-group">
							<input
								type={`${showPassword ? "text" : "password"}`}
								className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.password} onChange={(e) => setData({ ...data, 'password': e.target.value })} disabled={blockInput} />
							
							<span
								className="btn btn-outline-secondary"
								onClick={() => setShowPassword(!showPassword)}>
								{showPassword === false ? (<i className="bi bi-eye" />) : (<i className="bi bi-eye-slash" />)}
							</span>
							</div>
							{(data.password == '' && errors.password) && <div className="text-danger fs-12">{errors.password}</div>}

						</div>
						
						{authType !== '' && <div className="mb-2">
							<label className="form-label" style={{ textTransform: 'capitalize' }}>{authType=='mpin'?'Security Pin':authType}</label>
							<input type="text" autoFocus={true} className="form-control form-control-lg bg-inverse bg-opacity-5" value={typeData} onChange={(e) => {handleNum(e)}} />
						</div>}
						{authType == 'otp' && !showTimmer && <div className="mb-4"><span role="button" className='text-primary' style={{ textTransform: 'capitalize' }} onClick={() => blockOtp ? sendOTP('resend') : null}>Resend OTP </span></div>}
						{authType == 'otp' && showTimmer && <div className="mb-4"><span style={{ color: 'var(--primary)', margin: '8px' }}>Resend request after {counter} sec</span></div>}
						<p className='mt-2 mb-3'>
							<Link onClick={changeShowForgetPassword} className="text-decoration-underline">Forgot password?</Link>
						</p>
						<div className="d-grid gap-3">
							<button type="submit" className="light_gradient_btn w_100" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
								{logType == 'pre' ? 'Verify ' : 'Sign In '}
								{loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
									<span className="visually-hidden">Loading...</span>
								</div>}
							</button>
							<Link className="text-center" to={"/register"}>Not registered? Signup</Link>
						</div>
						
					</form>
				</div>
				</div>
			</div>


			{showForgetPassword && <ForgetPassword props={{ changeShowForgetPassword }} />}
		</>
	)
}
export default Login;