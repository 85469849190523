export default function OpenImg(data,currency){
    var imgWindow = window.open("",'_blank');
    imgWindow.document.write(`
    <!DOCTYPE html>
        <html lang="en">
        <head>
            <title>Invoice</title>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <link rel="shortcut icon" type="image/png" href="/assets/img/fevicon.png"/>
            <link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet">
            <style type="text/css" media="screen,print">
                *{margin:0px; padding:0px;}
                body {
                        overflow-x: hidden;
                        font-family: 'Open Sans', sans-serif;
                    }
                
                .banner_ul{list-style: none; text-decoration: none; margin-bottom: 30px; margin-top: 30px; overflow: hidden; text-align: center;}
                .banner_ul li{display: inline-block;}
                .banner_ul li a{display: inline-block; text-decoration:none; margin: 5px 0px; position: relative; font-weight: bold; background: #111; color: #fff; padding: 10px 10px;  width: 200px; font-size: 20px;}
                .banner_ul li a::before {content: "";  border: 1px solid #fff; position: absolute; top: 5px; bottom: 5px; right: 5px; left: 5px;}
                .banner_ul li a:hover::before{border: 1px solid #111;}
                .banner_ul li a:hover{background: #fff; color:#111; }
                .top_part{background-color: #333;}
                .container{max-width:1140px; margin:auto; padding-right: 15px; padding-left: 15px;}
                .header_part{padding: 15px 0px; border-bottom: .1rem solid rgba(0,0,0,.1); text-align:center}
                .header_part .logo{width: 300px; }
            </style>
            <style media="print">
                .banner_ul{display:none;}
            </style>
        </head>
        <body>
            <div class="top_part"></div>
            <div class="container">
                <div class="header_part">
                    <img class="logo" src="/assets/img/logo-dark.png" alt="" style="filter:drop-shadow(4px 4px 4px black)">
                </div>
            </div>


            <div class="container">
                <h1 style="text-align: center; text-transform: uppercase; color: #111;">Invoice</h1>
                <div style="width:80px; border:1px solid #111; margin:auto; "></div>
            </div>
            <br>
            <br>
            <div style="overflow:hidden;" class="container">
                <div style="float:left;">
                    <p style="font-size: 14px;">Bill To</p>
                    <p><strong style="color: #000; ">${data.name }</strong></p>
                    <p>Username : ${data.username}</p>
                    <p>${data.country}</p>
                </div>
                <div style="float:right; width:400px; ">
                    <table style="width:100%; border-spacing: 0;">
                        <tr style="font-size: 14px;">
                            <th style="padding: 10px; border-bottom: 1px solid #fff; background:#111; color:#fff">Invoice#</th>
                            <td style="padding: 10px; border-bottom: 1px solid #fff; background:#f5f5f5;text-transform: uppercase;">${data.invoice_id}</td>
                        </tr>
                        <tr style="font-size: 14px;">
                            <th style="padding: 10px; border-bottom: 1px solid #fff; background:#111; color:#fff">Invoice Date</th>
                            <td style="padding: 10px; border-bottom: 1px solid #fff; background:#f5f5f5;">${new Date().toLocaleString('en-GB', {hour12: false,})}</td>
                        </tr>
                        <tr style="font-size: 14px;">
                            <th style="padding: 10px; border-bottom: 1px solid #fff; background:#111; color:#fff">Payment Date</th>
                            <td style="padding: 10px; border-bottom: 1px solid #fff; background:#f5f5f5;">${new Date(data.created_at).toLocaleString('en-GB', {hour12: false,})}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <br>
            <br>
            <div class="container">
                <table style="width:100%; border-spacing: 0; color: #666;">
                    <tr style="text-align: left; background: #111; color: #fff;">
                        <th style="padding: 10px;">#</th>
                        <th style="padding: 10px;">Package</th>
                        <th style="padding: 10px;">Descripition</th>
                        <th style="padding: 10px;">Amount</th>
                        <th style="padding: 10px;">Currency</th>
                    </tr>
                    <tr>
                        <td style="padding: 10px; border:1px solid #111; color: #000;">1</td>
                        <td style="padding: 10px; border:1px solid #111; color: #000;">${data.package}</td>
                        <td style="padding: 10px; border:1px solid #111; color: #000;">Package Purchase</td>
                        <td style="padding: 10px; border:1px solid #111; color: #000;">${parseFloat(data.amount_paid)}</td>
                        <td style="padding: 10px; border:1px solid #111; color: #000;">${currency.name}</td>
                    </tr>
                </table>
            </div>
            <br>
            <div style="overflow:hidden;" class="container">
                <div style="float:right; width:400px;">
                
                    <p style="padding:5px 0px; text-align:right;">Sub Total: <strong style="color: #21aa0d; font-style:italic;">${currency.symbol} ${parseFloat(data.amount_paid)}</strong></p>
                    
                    <p style="padding:5px 0px; text-align:right;">Total In Words: <strong style="color: #21aa0d; font-style:italic;" id='total_num'></strong></p>
                </div>
            </div>
            <br> 
            <br>
            <div class="container">
                <div >
                    <p style="font-size: 14px; text-align:center;">Thank You - we really appriciate your business!</p>
                    <p style="font-size: 14px; text-align:center;color:#ff5757">**This is a computer generated bill and doesn't require any signature.</p>
                </div>
            </div>
            <br>
            <ul class="banner_ul">
                <li><a onclick="window.print()">Print</a></li>
            </ul>
        </body>
        <script>
        function test(n) {
            if (n < 0)
              return false;
            single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
            double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
            below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
            if (n === 0) return 'Zero'
            function translate(n) {
                word = ""
                if (n < 10) {
                    word = single_digit[n] + ' '
                }
                else if (n < 20) {
                    word = double_digit[n - 10] + ' '
                }
                else if (n < 100) {
                    rem = translate(n % 10)
                    word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
                }
                else if (n < 1000) {
                    word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
                }
                else if (n < 1000000) {
                    word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
                }
                else if (n < 1000000000) {
                    word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
                }
                else {
                    word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
                }
                return word
            }
            result = translate(n) 
            return result.trim()
        }
        document.getElementById('total_num').innerHTML = test(${parseFloat(data.amount_paid)}) +" "+"only."
        </script>
    </html>
    `);
}