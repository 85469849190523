import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getReq } from '../../requests/request';
import { notifyError } from '../../parts/Toast';
import Tree from 'react-d3-tree';

import { MdPerson } from "react-icons/md";
import { SidebarClick } from '../../routes/route';
import { ThemeContext } from '../../context/ThemeContext';
import Frame from "../../components/frame";
export default function BinaryTeam() {
    const navigate = useNavigate();
    const { background } = useContext(ThemeContext);
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [username,setUsername] = useState(null);
    const [tree_data, setTree_data] = useState({
        name: '',
        attributes: {
        department: '',
        },
        more:'',
        children: []
    });
    useEffect(()=>{
        setCurrentSidebarClick('/user/binaryTeam');
        setCollapseId(7);
        (async () => {
            try{
                setSpinner(true);
                let result = await getReq(`binary-details/${sessionStorage.getItem('username')}`,sessionStorage.getItem('token'));
                setTree_data({
                    name: result.data.parent,
                    attributes: {},
                    more:result.data,
                    children: [await addFirstLevel(result.data.leftuser),await addFirstLevel(result.data.rightuser)]
                });
                if(result.status==false && result.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                setSpinner(false);
            }
            catch(err){
                notifyError(err.message);
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
            }
        })();
        setSpinner(false);
    },[]);
    async function addFirstLevel(side){
        if(side!==null){
            let result = await getReq(`binary-details/${side}`,sessionStorage.getItem('token'));
            return ({name: result.data.parent,
                attributes: {},
                more:result.data,
                children: [],
            });
        }
        else{
            return ({name:'vacant',
                attributes: {},
                more:'',
                children: [],
            });
        }
    }
    const getLastChildArray = (node, targetName) => {
        if (!node) {
          return null;
        }
        if (node.more&&node.more.parent === targetName) {
          return node.children;
        }
        const leftResult = getLastChildArray(node.children && node.children[0], targetName);
        if (leftResult) {
          return leftResult;
        }
        const rightResult = getLastChildArray(node.children && node.children[1], targetName);
        if (rightResult) {
          return rightResult;
        }
        return null;
    }
    const addDataToLastChild = async (node) => {
        if(node.data.name!=='vacant'){
          setSpinner(true);
          const lastChildArray = getLastChildArray(tree_data,node.data.more.parent);
          if(lastChildArray.length<=0){
            if(node.data.more.leftuser){
                let left = await getReq(`binary-details/${node.data.more.leftuser}`,sessionStorage.getItem('token'));
                if(left){
                    lastChildArray.push({name:left.data.parent,attributes: {},more:left.data,children: []});
                }
                else{
                    lastChildArray.push({name:'vacant'});
                }
            }
            else{
                lastChildArray.push({name:'vacant'});
            }
            if(node.data.more.rightuser){
                let right = await getReq(`binary-details/${node.data.more.rightuser}`,sessionStorage.getItem('token'));
                if(right){
                    lastChildArray.push({name:right.data.parent,attributes: {},more:right.data,children: []});
                }
                else{
                    lastChildArray.push({name:'vacant'});
                }
            }
            else{
                lastChildArray.push({name:'vacant'});
            }
            setTree_data({ ...tree_data });
            setSpinner(false);
        }
        else{
            setSpinner(false);
          }
        }
    };
    const renderRectSvgNode = ({nodeDatum,onNodeClick}) => (
        <g onClick={onNodeClick} onMouseOver={()=>setUsername(nodeDatum.name)} onMouseLeave={()=>setUsername(null)}>
            <circle r="25" fill="#6cfff475" stroke="#000" />
            <text fill={background.value=='light'?"#000":"white"} strokeWidth="0.1" transform="translate(30, 5)">
                {nodeDatum.name}
            </text>
            <g transform="translate(-25, -25)">
                <MdPerson style={{ fontSize: 50 }}/>
            </g>
            {nodeDatum.name!=='vacant'&&username===nodeDatum.name&&<foreignObject {...{ width: 330, height: 300, x: 30 ,y:-30}}>
                <div style={{ border: "1px solid #ccc", borderRadius:'8px',padding:'10px',color:"#000",backgroundColor: "#f69220" }}>
                    <div style={{display:'flex',gap:'1rem'}}>
                        <strong><span style={{ textAlign: "center" }}>Username :</span></strong>
                        <span style={{ textAlign: "center" }}>{nodeDatum.more?.parent}</span>
                    </div>
                    <div style={{display:'flex',gap:'1rem'}}>
                        <strong><span style={{ textAlign: "center" }}>Left User :</span></strong>
                        <span style={{ textAlign: "center" }}>{nodeDatum.more?.leftuser||'-'}</span>
                    </div>
                    <div style={{display:'flex',gap:'1rem'}}>
                        <strong><span style={{ textAlign: "center" }}>Total Left:</span></strong>
                        <span style={{ textAlign: "center" }}>{parseFloat(nodeDatum.more?.left_total)}</span>
                    </div>
                    <div style={{display:'flex',gap:'1rem'}}>
                        <strong><span style={{ textAlign: "center" }}>Right User :</span></strong>
                        <span style={{ textAlign: "center" }}>{nodeDatum.more?.rightuser||'-'}</span>
                    </div>
                    <div style={{display:'flex',gap:'1rem'}}>
                        <strong><span style={{ textAlign: "center" }}>Total Right:</span></strong>
                        <span style={{ textAlign: "center" }}>{parseFloat(nodeDatum.more?.total_right)}</span>
                    </div>
                    <div style={{display:'flex',gap:'1rem'}}>
                        <strong><span style={{ textAlign: "center" }}>Total Matching:</span></strong>
                        <span style={{ textAlign: "center" }}>{parseFloat(nodeDatum.more?.total_matching)}</span>
                    </div>
                </div>
            </foreignObject>}
        </g>
      );
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>

            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Binary Team</h1>
            </div>
            <div className="card">
                <div className="card-body">
                <div style={{height:'100vh'}}>
                    <Tree data={tree_data}
                    orientation='vertical'
                    collapsible={true}
                    separation={{nonSiblings:2,siblings:3}}
                    onNodeClick={(node)=>addDataToLastChild(node)}
                    translate={{x:500,y:100}}
                    renderCustomNodeElement={renderRectSvgNode}
                    //pathFunc={"step"}
                    />
                </div>
                </div>
               
            </div>

    </Frame>
    );
}
