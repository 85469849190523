import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getReq } from '../../requests/request';
import { notifyError } from '../../parts/Toast';
import Tree from 'react-d3-tree';

import { MdPerson } from "react-icons/md";
import { SidebarClick } from '../../routes/route';
import { ThemeContext } from '../../context/ThemeContext';
import Frame from "../../components/frame";
export default function SingleLeg() {
    const navigate = useNavigate();
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const { background } = useContext(ThemeContext);
    const [spinner, setSpinner] = useState(false);
    const [username, setUsername] = useState(null);
    const currecy = '$';
    const [tree_data, setTree_data] = useState(null);
    let tempTree = null;
    useEffect(() => {
        setSpinner(true);
        setCurrentSidebarClick('/user/SingleLeg');
        setCollapseId(7);
        (async () => {
            await renderTree(sessionStorage.getItem('username'));
        })();
        setSpinner(false);
    }, []);
    const renderTree = async(username)=>{
        try {
            let result = await getReq(`singleleg/${username}`, sessionStorage.getItem('token'));
            console.log(result)
            let temp = {
                name: result.data.mainuser.username,
                attributes: {},
                more: result.data.mainuser,
                extra: {
                    root:true,
                    upline: result.data.upline,
                    downline: result.data.downline
                },
                children:[],
            }
            let tempUsername = null;
            if(result.data.upline?.length>0){
                let utemp = {
                    name: result.data.upline[0].username,
                    attributes: {},
                    more: result.data.upline[0],
                    extra: {
                        upline: [],
                        downline: []
                    },
                    children:[],
                }
                tempTree = utemp;
                //setTree_data({...utemp});
                tempUsername = result.data.upline[0].username
                result.data.upline.forEach((data,idx)=>{
                    if(idx!=0 && idx<5){
                        let last_child = getLastChildArray(tempTree,tempUsername);
                        last_child.push(
                            {
                                name: data.username,
                                attributes: {},
                                more: data,
                                extra: {
                                    upline: [],
                                    downline: []
                                },
                                children:[],
                            }
                        )
                        tempUsername = data.username
                    }
                })
            }
            if(tempTree!=null){
                let last_child = getLastChildArray(tempTree,tempUsername);
                last_child.push(temp)
                tempUsername = temp.name
            }
            else{
                tempTree = temp
            }
            if(result.data.downline?.length>0){
                result.data.downline.forEach((data,idx)=>{
                    if(idx<5){
                        let last_child = getLastChildArray(tempTree,tempUsername);
                        last_child.push(
                            {
                                name: data.username,
                                attributes: {},
                                more: data,
                                extra: {
                                    upline: [],
                                    downline: []
                                },
                                children:[],
                            }
                        )
                        tempUsername = data.username
                    }
                })
            }
            setTree_data({...tempTree})
            if (result.status == false && result.msg == 401) {
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
                notifyError('Authentication Failed. Please Login Again....');
            }
        }
        catch (err) {
            notifyError(err.message);
            sessionStorage.clear();
            window.history.replaceState(null, null, "/");
            navigate('/', { replace: true });
        }
    }
    const getLastChildArray = (node, targetName) => {
        // if (!node) {
        //     return null;
        // }
        if (node.more && node.more.username === targetName) {
            return node.children;
        }
        for (let c of node.children) {
            const Result = getLastChildArray(c, targetName);
            if (Result) {
                return Result;
            }
        }
        return null;
    }
    const addDataToLastChild = async (node) => {
        if(node.data.more){
            if (node.data.name !== 'vacant' && node.data.children.length <= 0) {
                setSpinner(true);
                const lastChildArray = getLastChildArray(tree_data, node.data.name);
                let cdata = await getReq(`treeView/${node.data.more.downline}`, sessionStorage.getItem('token'));
                cdata && lastChildArray.push(
                    {
                        name: cdata.username,
                        attributes: {},
                        more: cdata.data[0],
                        children: []
                    }
                )
                setTree_data({ ...tree_data });
                setSpinner(false);
            }
            else {
                setSpinner(false);
            }
        }
        else {
            setSpinner(false);
        }  
    };
    const renderRectSvgNode = ({ nodeDatum, onNodeClick }) => (
        <g onClick={onNodeClick} onMouseOver={() => setUsername(nodeDatum.name)} onMouseLeave={() => setUsername(null)}>
            <circle r="25" fill={nodeDatum.extra?.root?"#f05a22":"#6cfff475"} stroke="#b6f4aa" />
            <text fill={background.value == 'light' ? "#000" : "white"}  transform="translate(30, 5)">
                {nodeDatum.name}
            </text>
            <g transform="translate(-25, -25)">
                <MdPerson style={{ fontSize: 50 }} />
            </g>
            {nodeDatum.name !== 'vacant' && username === nodeDatum.name && <foreignObject {...{ width: 250, height: 300, x: 30, y: -30 }}>
                <div style={{ border: "1px solid #ccc", borderRadius: '8px', padding: '10px', color:"#000", backgroundColor: "#b5f3aa" }}>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <strong><span style={{ textAlign: "center" }}>Username :</span></strong>
                        <span style={{ textAlign: "center" }}>{nodeDatum?.name}</span>
                    </div>
                    <div style={{ display: 'flex', gap: '0.2rem' }}>
                        <strong><span style={{ textAlign: "center" }}>Total Investment:</span></strong>
                        <span style={{ textAlign: "center" }}>{process.env.REACT_APP_Amount_Symbol||'$' + parseFloat(nodeDatum.more?.total_investment)}</span>
                    </div>
                    <div style={{ display: 'flex', gap: '0.2rem' }}>
                        <strong><span style={{ textAlign: "center" }}>Total Withdraw:</span></strong>
                        <span style={{ textAlign: "center" }}>{process.env.REACT_APP_Amount_Symbol||'$' + parseFloat(nodeDatum.more?.total_withdraw)}</span>
                    </div>
                    {/* {nodeDatum.name == nodeDatum.more?.username
                        ? <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Total Investment:</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.total_investment ? process.env.REACT_APP_Amount_Symbol||'$' + parseFloat(nodeDatum.more?.total_investment): process.env.REACT_APP_Amount_Symbol||'$' + '0'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Total Investment:</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.total_investment ? process.env.REACT_APP_Amount_Symbol||'$' + parseFloat(nodeDatum.more?.total_investment) : process.env.REACT_APP_Amount_Symbol||'$' + '0'}</span>
                        </div>
                    } */}
                    {/* {nodeDatum.name == nodeDatum.more[0]?.sponsor
                        ? <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Business Amount:</span></strong>
                            <span style={{ textAlign: "center" }}>{process.env.REACT_APP_Amount_Symbol + parseFloat(nodeDatum.more[0]?.sponsor_total_team_business) || process.env.REACT_APP_Amount_Symbol + '0'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Business Amount:</span></strong>
                            <span style={{ textAlign: "center" }}>{process.env.REACT_APP_Amount_Symbol + parseFloat(nodeDatum.more?.total_team_business) || process.env.REACT_APP_Amount_Symbol + '0'}</span>
                        </div>
                    } */}
                    {/* {nodeDatum.name == nodeDatum.more?.sponsor
                        ? <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Global Pool :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.sponsor_pool || 'NA'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Global Pool :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.pool || 'NA'}</span>
                        </div>
                    } */}
                    {/* {nodeDatum.name == nodeDatum.more?.sponsor
                        ? <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Rank :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.sponsor_rank || 'NA'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Rank :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.rank || 'NA'}</span>
                        </div>
                    } */}
                    {/* {nodeDatum.name == nodeDatum.more[0]?.sponsor && nodeDatum.extra
                        ? <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Total Team :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.extra.sponsor_total_team || '0'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Total Team :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.total_team || '0'}</span>
                        </div>
                    }
                    {nodeDatum.name == nodeDatum.more[0]?.sponsor && nodeDatum.extra
                        ? <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Paid Team :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.extra.sponsor_paid_team || '0'}</span>
                        </div>
                        : <div style={{ display: 'flex', gap: '0.2rem' }}>
                            <strong><span style={{ textAlign: "center" }}>Paid Team :</span></strong>
                            <span style={{ textAlign: "center" }}>{nodeDatum.more?.paid_team || '0'}</span>
                        </div>
                    } */}
                    {/* {nodeDatum.name != nodeDatum.more?.downline && <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <strong><span style={{ textAlign: "center" }}>Downline :</span></strong>
                        <span style={{ textAlign: "center" }}>{nodeDatum.more?.downline || '-'}</span>
                    </div>} */}
                </div>
            </foreignObject>}
        </g>
    );
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Single Leg</h1>
            </div>
            {spinner&&<div style={{backgroundColor: "rgba(0, 0, 0, 0.7)",position: "absolute",top: "0",bottom: "0",left: "0",right: "0",zIndex: "1000"}}>
                <div class="spinner-grow text-primary" role="status" style={{display: "block",position: "fixed",zIndex: 1030,top: "50%",left: "50%",}}>
                    <span class="sr-only"></span>
                </div>
            </div>}
            <div className="card">
                <div className="card-body">
                    <div style={{ height: '100vh' }}>
                        {tree_data&&<Tree 
                            data={tree_data}
                            orientation='vertical'
                            collapsible={true}
                            separation={{ nonSiblings: 1, siblings: 3 }}
                            onNodeClick={(node) => renderTree(node.data.name)}
                            translate={{ x: 500, y: 100 }}
                            renderCustomNodeElement={renderRectSvgNode}
                            pathFunc={"step"}
                        />}
                    </div>
                </div>
            </div>
        </Frame>
    );
}

